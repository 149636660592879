import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const FormContainer = styled.form`
    width: 44vw;
    border-radius: 12px;

    .col1 {
        width: 100%;
    }

    .headers {
        width: 100%;
        height: 48px;
        font-family: 'Roboto', sans-serif;
        font-size: 0.85rem;
        user-select: none;
        font-weight: 500;
        background-color: ${defaultTheme.colors['white-100']};
        color: ${defaultTheme.colors['gray-300']};
        display: flex;
        margin-top: 12px;
        align-items: center;

        span.small {
            width: 22%;
        }

        span.large {
            width: 42%;
        }    
    }

    .orderList {
        position: relative;
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
    }

    .footer {
        width: 100%;
        padding-right: 22%;
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        font-size: 1rem;
        color: ${defaultTheme.colors['gray-300']};
    }

    @media screen and (max-height:769px){
        .orderList {
            max-height: 50vh;
        }
    }

    @media screen and (max-width: 769px){

        .headers {
            width: 100%;
            position: sticky;
            top: 0;
            z-index: 20;
            padding-left: 12px;
            .small {
                min-width: 30%;
            }
        }

        .orderList {
            .clientName {
                margin-left: 2%;
            }
            .boxCount {
                margin-left: 3%;
            }
        }

        .footer {
            position: sticky;
            bottom: 0;
            height: 32px;
            background-color: ${defaultTheme.colors['white-100']};
        }
    }
`