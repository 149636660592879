import styled from 'styled-components'

export const FilterSelectContainer = styled.div`
    position: absolute;
    top: 40px;
    left: 20vw;
    width: 273px;
    display: flex;
    align-items: center;
    z-index: 200;

    padding: 12px;

    cursor: default;

    background: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));

    .list {
        width: 100%;

        display: flex;
        flex-direction: column;

        overflow-y: auto;
    }

    .checkboxes {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 12px 12px 12px 25px;
      height: 40px;

      :hover {
            background-color: #F0F0F0;
      }
    }

    .entry {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        height: 40px;
        padding: 12px 25px;

        :hover {
            background-color: #F0F0F0;
        }
    }

    .entryName {
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-align: left;
        background: none;
        border: none;
        width: 100%;
        color: black;
    }

    @media screen and (max-width:769px){
        width: 265px;
        top: 48px;
        left: -18vw;
        input {
          width: 100%;
        }

        .header {
            margin-bottom: 0;
            margin-top: 0;
        }

        ::before {
            visibility: hidden;
        }
    }

`

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
`
