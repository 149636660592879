import { FilterSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import arrowFilter from 'assets/arrowFilter.svg'
import { RouteSelect } from "./SelectRoutes";
import { api } from 'services/api';
import { baseURL } from 'settings';
import { PaginationProps } from 'interfaces/IPagination';
import { IWaybill } from "interfaces/IWaybill";
import { SelectDate } from "./SelectDate";

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    onChangeParams: (x: any) => void,
    onChangeParamsDate: (x: any) => void
}

export function FilterDeliveries({ open, setOpen, onChangeParams, onChangeParamsDate }: IProps) {
    const [params, setParams] = useState({ page: 1, pageSize: 300, search: ''})
    const [driverSelect, setDriverSelect] = useState(false)
    const [dateSelect, setDateSelect] = useState(false)
    const [entries, setEntries] = useState<PaginationProps<IWaybill>>({} as PaginationProps<IWaybill>)

    const fetchData = async () => {
        await api.get(`${baseURL}/v1/delivery/waybill`, {
            params
        })
            .then((response) => {
                setEntries(response);
            })
    };

    const display = open ? 'initial' : 'none'

    useEffect(() => {
        fetchData()
    }, [params]);

    return (
        <>
            <SelectBack className="popupBack" onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }}
            style={{ display }}
            />
            <FilterSelectContainer style={{ display }}>
                <div className="list">
                    <div className="entry">
                    <button type="button" className="entryName" onClick={() => setDriverSelect(true)}>Rota</button>
                    <img src={arrowFilter} alt='icon arrow filter' />
                </div>
                <div className="entry">
                    <button type="button" className="entryName" onClick={() => setDateSelect(true)}>Data</button>
                    <img src={arrowFilter} alt='icon arrow filter' />
                </div>
                
                </div>
                <RouteSelect entries={entries} onChangeParams={onChangeParams} open={driverSelect} setOpen={setDriverSelect} />
                <SelectDate onChangeParamsDate={onChangeParamsDate} onChangeParams={onChangeParams} open={dateSelect} setOpen={setDateSelect} />
            </FilterSelectContainer>
        </>
    )
}
