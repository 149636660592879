import { PriorityContainer } from "../styles"
import SearchIcon from 'assets/searchIcon.svg'
import { Input } from "components/Input"
import { useEffect, useState } from "react"
import { api } from "services/api"
import { baseURL } from "settings"
import { useDebaunce } from "utils/debaunce"
import { toast } from "react-toastify"
import { LinearProgress } from "@mui/material"
import { IDist } from "interfaces/IDist"
import { DepotLine } from "./DepotLine"
import { AddDepotPopup } from "./AddDepot"
import { priorityService } from "services/priorityService"

export function DepotPriority() {
    const [params, setParams] = useState({ page: 0 })
    const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<IDist[]>([])
    const [open, setOpen] = useState(false)
    const [canEdit, setCanEdit] = useState(false)
    const [changedDepots, setChangedDepots] = useState<IDist[]>([] as IDist[])

    const deepCopy = (list: IDist[]) => list.map(dist => ({ ...dist }))

    const fetchData = () => {
        setLoading(true)
        api.get(`${baseURL}/v1/delivery/distribution-center`, { params })
            .then((response) => {
                setData(response.results)
                setChangedDepots(response.results)
            }).catch((e) => {
                toast.error("Houve um erro ao contatar o banco de dados")
            }).finally(() => {
                setLoading(false)
            })
    }

    function onChangeParams(params: any) {
        if (loading) return
        setParams((state: any) => ({ ...state, ...params }))
    }

    const handleSave = () => {
        const newList = changedDepots.filter(depot => {
            const dataDepot = data.find(element => element.id === depot.id)

            if (depot.initials === dataDepot?.initials) return false

            return true
        })

        toast.info("Aguarde...")
        priorityService.updateDepots(newList)
        .then((e) => {
            toast.success("CDs atualizados com sucesso!")
            fetchData()
        }).catch((e) => {
            toast.error("Não foi possível atualizar os CDs")
        }).finally(() => {
            setCanEdit(false)
        })
    }

    const handleUpdate = (element: IDist) => {
        setChangedDepots((prevDepots) => {
            const updatedDepots = prevDepots.map((depot) => {
                if (depot.id === element.id) {
                    return { ...depot, initials: element.initials}
                }
                return depot
            })
    
            return updatedDepots
        })
    }

    const handleCancel = () => {
        setCanEdit(false)
        setChangedDepots(deepCopy(data))
        fetchData()
    }

    useEffect(() => {
        fetchData()
    }, [params])

    return (
        <PriorityContainer className="wide">
            <div className="header">
                <div className="left" style={{ width: "140%" }}>
                    <div className="input">
                        <img className="searchIcon" src={SearchIcon} alt="" />
                        <Input className="inputBox" placeholder="Nome ou ID"
                            onChange={e => debaunceFilter({ search: e.target.value, page: 0 })}/>
                    </div>
                    <div className="addBtnContainer">
                        <button className="addBtn" onClick={() => setOpen(true)}>+</button>
                    </div>
                </div>
                <div className="right">
                    <div className="editBtns">
                        {canEdit &&
                            <button className="cancelBtn" onClick={handleCancel}>Cancelar</button>
                        }
                        <button className="editBtn"
                            onClick={() => canEdit ? handleSave() : setCanEdit(true)}
                            disabled={canEdit && JSON.stringify(changedDepots) === JSON.stringify(data)}>
                            <span>{canEdit ? "Salvar" : "Editar"}</span>
                        </button>
                    </div>
                </div>
                {open && <AddDepotPopup open={open} setOpen={setOpen} fetchData={fetchData} />}
            </div>
            <div className="main">
                <div className="columns">
                    <span className="small">CD ID</span>
                    <span className="large">Nome</span>
                    <span className="small">Sigla</span>
                </div>
                {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
                <div className="list" style={{ paddingLeft: "40px" }}>
                    {data.sort((a, b) => a.id - b.id).map((depot, index) => (
                        <div className="priorityGroup" key={index}>
                            <DepotLine data={depot} canEdit={canEdit} handleUpdate={handleUpdate} />
                        </div>
                    ))}
                </div>
            </div>
        </PriorityContainer>
    )
}