import { PrivatePage } from "pages/privatePage";
import { Container, SidebarContainer } from "./styles";
import SidebarComponent from "components/Sidebar";
import { Header } from "components/Header";
import SigninIllust from 'assets/signinIllust.svg'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Card from "components/Card";
import { useAuth } from "contexts/AuthContext";
import { useMediaQuery } from "react-responsive";

export function Home() {
  const {username} = useAuth()
  let name = (username.split(".")[0] || "usuário")
  // let name : string = "Usuário"
  name = name.charAt(0).toUpperCase() + name.slice(1)
  const isTablet = useMediaQuery({maxWidth: 820})


  const home = <>
    <Container>
      <Header/>
      <SidebarContainer>
          <SidebarComponent />
      </SidebarContainer>
      <div className="mainCard">
        <div className="textArea">
          <h1>Olá, {name}!</h1>
          <p>Bem-vindo ao Maracujá, aqui você encontra todos os dados de logística em um lugar só!</p>
          
          <div className="signupContainer">
            <a href='/signup'>
              <PersonAddIcon className="addIcon"/>
              <span className="signupText">Cadastrar Usuário</span>
            </a>
          </div>
        </div>
        <div className="imgArea">
          <img src={SigninIllust} alt="" />
        </div>
      </div>
      <div className="menuContainer">
        <div className="sectionTitle">
          <h2>O que você procura?</h2>
        </div>
        <div className="menuCards">
          <Card title="Atribuição de rotas" goto="/routes" description="Dados das rotas"/>
          <Card title="Motoristas" goto="/drivers" description="Dados dos motoristas"/>
          <Card title="Status de Entregas" goto="/delivery-status" description="Estado das entregas"/>
          <Card title="Score de Clientes" goto="/score-client" description="Pontuação dos Clientes"/>
        </div>
      </div>
    </Container>
  </>

  return <>
    <PrivatePage>
      {home}
    </PrivatePage>
  </>
}