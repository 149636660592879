export const defaultTheme = {
  colors: {
    "base-light": "#E8F2D2",
    "base": "#89BD23",
    "base-dark": "#6E8247",
    "base-input": "#F0F0F0",
    "base-text": "#A2A2A2",
    "base-label": "#757575",
    "white-100": "#FFFFFF",
    "white-300": "#FAFAFA",
    "white-table": "#F5F5F5",
    "white-box": "#F0F0F0",
    "gray-100": "#C4C4C4",
    "gray-200": "#A2A2A2",
    "gray-300": "#757575",
    "logo-yellow": "#DBA120",
    "black": "#353535",
    "success": "#04D361",
    "danger": "#EB001B",
    "alert": "#FFC107",
    "transit": "#3887FF"
  },
  textSizes: {
    "text-regular-s": "0.875rem",
    "text-regular-p": "1rem",
  },
  fonts: {
    regular: "'Roboto'",
    title: "'Inter'"
  }
}