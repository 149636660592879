import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import { Container, MainContainer, SidebarContainer, TabButton } from './styles';
import { PageContainer } from 'components/PageContainer'
import { CityPriority } from 'components/Priority/CityPriority';
import { useState } from 'react';
import { DepotPriority } from 'components/Priority/Depot';
import { RegionPriority } from 'components/Priority/RegionPriority';

export function Priority() {
  const [currentBtn, setCurrentBtn] = useState("macro")

  return (
    <Container>
      <Header />
      <div className="pageContent">
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Macrorregiões, Cidades e CDs</h3>
              <div className="toolBar">
                <TabButton isActive={currentBtn==="macro"} onClick={() => setCurrentBtn("macro")}>Macrorregiões</TabButton>
                <TabButton isActive={currentBtn==="city"} onClick={() => setCurrentBtn("city")}>Cidades</TabButton>
                <TabButton isActive={currentBtn==="depot"} onClick={() => setCurrentBtn("depot")}>CDs</TabButton>
              </div>
            </div>
            <div className="priorityContainer">
              {currentBtn === "city" && <CityPriority />}
              {currentBtn === "depot" && <DepotPriority/>}
              {currentBtn === "macro" && <RegionPriority/>}
            </div>
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  )
}