import SearchIcon from 'assets/searchIcon.svg'
import { RouteSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { PaginationProps } from 'interfaces/IPagination';
import { Input } from 'components/Input';
import { IDriverFilter } from 'interfaces/IDriver';

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    onChangeParams: (x: any) => void,
    entries: PaginationProps<IDriverFilter>
}

export function DriverSelect({ open, setOpen, onChangeParams, entries }: IProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDrivers, setSelectedDrivers] = useState<number[]>([]) 

    const handleCheckbox = async (element: number) => {
        if (selectedDrivers?.includes(element)) {
            setSelectedDrivers(state => ( [ ...state?.filter((data: number) => data !== element) ] ));
        } else {
            setSelectedDrivers(state => ( [...state, element] ));
        }
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
    };

    useEffect(() => {
        onChangeParams({ "driverId": selectedDrivers.join(","), "page": 1 })
    }, [selectedDrivers]);

    if (!open) return null

    return (
        <>
            <SelectBack className="popupBack" onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }} />
            <RouteSelectContainer>
                <div className="header">
                    <div className="input">
                        <img className="searchIcon" src={SearchIcon} alt="" />
                        <Input
                            className="inputBox"
                            onChange={handleSearch}
                            placeholder="Motorista"
                        />
                    </div>
                </div>
                <div className="list">
                    {entries?.results?.filter(item => item?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(element => (
                            <div className="entry" key={element.id} onClick={() => handleCheckbox(element.id)}>
                                <button type="button" className="entryName" key={element.id}>{`${element?.name}`}</button>
                                <input
                                    type="checkbox"
                                    checked={selectedDrivers?.includes(element.id)}
                                />
                            </div>
                        ))}
                </div>
            </RouteSelectContainer>

        </>
    )
}
