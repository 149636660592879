import SearchIcon from 'assets/searchIcon.svg'
import { SelectContainer } from "../styles";
import { useEffect, useState } from "react";
import { baseURL } from "settings";
import { api } from "services/api";
import { PaginationProps } from 'interfaces/IPagination';
import { Input } from 'components/Input';
import { ITeams } from 'interfaces/ITeams';
import { IDriver } from 'interfaces/IDriver';
import { DriverListEntry } from './ListEntry';
import { LinearProgress } from '@mui/material';

interface IProps {
    data: ITeams,
    className?: string,
    handleSelect: (x: IDriver, y: boolean, z?: boolean) => void,
}

export function DriverList({ data, className, handleSelect }: IProps) {
    const [params, setParams] = useState({ page: 1, pageSize: 300, search: '' })
    const [search, setSearch] = useState('');
    const [entries, setEntries] = useState<PaginationProps<IDriver>>({} as PaginationProps<IDriver>)
    const [loading, setLoading] = useState(false)

    const fetchData = async () => {
        setLoading(true)
        await api.get(`${baseURL}/v1/delivery/driver`, {
            params
        })
            .then((response) => {
                setEntries(response);
                setLoading(false)
                const sortedList = sortData(response.results);
                setEntries((state) => ({
                    ...state,
                    results: sortedList
                }));
            })
    }

    const handleCheck = (element: IDriver, checked: boolean, leader?: boolean) => {
        element.teamId = data.id
        handleSelect(element, checked, leader)
    }

    const sortData = (list: IDriver[]): IDriver[] => {
        let teamList: { [index: string]: IDriver[] } = {}

        teamList = list.reduce((acc, driver) => {
            if (driver.team && !acc[driver.team.name]) {
                acc[driver.team.name] = []
            }
            driver.team && acc[driver.team.name].push(driver)
            return acc
        }, teamList)


        const available = list.filter((driver) => !driver.team)
        .sort((a: IDriver, b: IDriver) => a.name.localeCompare(b.name))

        const currentTeam = teamList[data.name]
        let sortedList = Object.values(teamList).filter((team) => team !== currentTeam)

        sortedList.forEach((element: IDriver[]) => {
            element.sort((a: IDriver, b: IDriver) => a.name.localeCompare(b.name))
            .sort((a: IDriver) => a.isLeader === true ? -1 : 1)
        })

        currentTeam && currentTeam.sort((a: IDriver, b: IDriver) => a.name.localeCompare(b.name))
        .sort((a: IDriver) => a.isLeader === true ? -1 : 1)

        const unavailable = sortedList.sort((a: IDriver[], b: IDriver[]) => a[0].team?.name.localeCompare(b[0].team?.name || '') || 0).flat()

        if (!currentTeam) return [...available, ...unavailable]
        return [...currentTeam, ...available, ...unavailable]
    }

    useEffect(() => {
        fetchData()
    }, [params]);

    return (
        <>
            <SelectContainer className={className}>
                <div className="header">
                    <div className="input">
                        <img className="searchIcon" src={SearchIcon} alt="" />
                        <Input
                            className="inputBox"
                            onChange={e => {
                                setSearch(e.target.value);
                            }}
                            placeholder="Nome do Motorista"
                        />
                    </div>
                </div>
                <div className="list">
                    {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
                    {entries.results?.filter(item => item?.name?.toLowerCase().includes(search.toLowerCase()))
                        .map((element) => (
                            <DriverListEntry memberList={data.members} element={element} data={data} handleCheck={handleCheck} key={element.id} />
                        ))}
                </div>
            </SelectContainer>
        </>
    )
}
