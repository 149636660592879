import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

interface ISearchButtonProps {
    isSensitive: boolean
}

export const ProductSearchButtonContainer = styled.button<ISearchButtonProps>`
    width: 83px;
    height: 27px;
    border: none;
    border-radius: 16px;
    font-family: 'Roboto' sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: ${({ isSensitive }) => isSensitive ? defaultTheme.colors['danger'] : defaultTheme.colors['base'] };
`

export const ProductSearchEntryContainer = styled.div`
    width: 98%;
    height: 48px;

    font-family: 'Roboto' sans-serif;
    font-size: 0.9rem;
    font-weight: 400;

    display: flex;
    align-items: center;

    .left {
        width: 75%;
        display: flex;

        .sku {
            width: 10%;
        }

        .name {
            margin-left: 8px;
        }
    }

    .right {
        width: 20%;
    }

    .toggleText {
        color: ${defaultTheme.colors['white-100']};
        font-family: 'Roboto' sans-serif;
        font-size: 0.9rem;
        font-weight: 400;
    }
`