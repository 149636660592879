import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const RouteSelectContainer = styled.div`
    position: absolute;
    visibility: hidden;
    left: 0;
    width: 340px;
    min-height: 40px;
    max-height: 200px;
    overflow-y: auto;

    &.below {
        visibility: visible;
        top: 115%;
    }

    &.above {
        visibility: visible;
        bottom: 115%;
    }
    
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #fff;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));
    z-index: 100;

    .header {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 10;
        user-select: none;
        width: 100%;
        min-height: 32px;
        display: flex;
        padding: 4px 25px;
        color: ${defaultTheme.colors['gray-200']};
        font-size: 0.85rem;
        font-weight: 500;
        align-items: center;
        .route {
            width: 33%;
        }

        .driver {
            width: 47%;
        }
    }

    .routeEntry {
        position: relative;
        padding: 8px 0;
        width: 100%;
        min-height: 32px;
        text-align: left;
        padding: 0 25px;
        border: none;
        font-size: 0.9rem;
        background-color: inherit;
        display: flex;
        align-items: center;
        gap: 12px;

        .teamTag {
            position: absolute;
            left: 0;
            width: 6px;
            height: 100%;
        }
        
        .route {
            min-width: 20%;
        }

        .divider {
            color: ${defaultTheme.colors['gray-100']}
        }

        .team {
            width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .boxes {
            display: flex;
            justify-content: center;
            /* text-align: center; */
            width: 100px;
        }

        :hover {
            background-color: ${defaultTheme.colors['base-light']}
        }
    }
`