import { useEffect, useState } from "react";
import { Container } from "./styles";

interface Props {
  onChangeParams: (x: any) => void;
}

export function DeliveryStatusFilter({ onChangeParams }: Props) {
  const [selectedFilter, setSelectedFilter] = useState<any>("");

  const handleStatusClick = (status: string) => {
    if (status === selectedFilter) {
      setSelectedFilter("");
    } else {
      setSelectedFilter(status);
    }
  };

  useEffect(() => {
    onChangeParams({ deliveryStatus: selectedFilter });
  }, [selectedFilter]);

  return (
    <Container>
      <button
        className={`statusDelivery ${selectedFilter === "falha" ? "active" : ""}`}
        onClick={() => handleStatusClick("falha")}
      >
        <div className={`statusBall ${selectedFilter === "falha" ? "active" : ""}`}></div>
        Falha
      </button>
      <button
        className={`statusDelivery ${selectedFilter === "pendente" ? "active" : ""}`}
        onClick={() => handleStatusClick("pendente")}
      >
        <div className={`statusBallBlue ${selectedFilter === "pendente" ? "active" : ""}`}></div>
        Em trânsito
      </button>
      <button
        className={`statusDelivery ${selectedFilter === "sucesso" ? "active" : ""}`}
        onClick={() => handleStatusClick("sucesso")}
      >
        <div className={`statusBallGreen ${selectedFilter === "sucesso" ? "active" : ""}`}></div>
        Entregue
      </button>
    </Container>
  );
}
