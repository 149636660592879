export function dateToday(dayLapse?: number) {
  const date = new Date();
  if (dayLapse) date.setDate(date.getDate() + dayLapse)
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  const currentDate = `${year}-${month}-${day}`;

  return currentDate
}
