import { ButtonHTMLAttributes } from "react"
import { ButtonContainer } from "./styles"


export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string | number;
  variant?: 'primary' | 'secondary';
}

export function ButtonFilter({ text, ...props }: ButtonProps) {
  return (
    <ButtonContainer {...props}>
      {text}
    </ButtonContainer>
  )
}