import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const DropContainer = styled.div`
    position: relative;

    .backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: 150;
    }

    .dropName.white {
        color: #fff
    }

    /* .dropdownBtn.medium {
        width: 200%;
    } */

    .contentContainer {
        position: absolute;
        width: 100%;
        top: 120%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 300;
        width: 100%;

        display: flex;
        flex-direction: column;

        input {
            margin-top: 10px;
            border: 1px solid ${defaultTheme.colors['gray-100']};
            box-shadow: inset 1px black;
        }
    }

    .react-colorful {
        width: 100%;
        
        height: 120px;

        .react-colorful__hue {
            height: 12px;
        }
        .react-colorful__saturation-pointer {
            width: 16px;
            height: 16px;
        }
        .react-colorful__hue-pointer {
            width: 16px;
            height: 16px;
        }
    }
`