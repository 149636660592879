import { TransshipPointSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { PaginationProps } from 'interfaces/IPagination';
import { IDriverFilter } from 'interfaces/IDriver';
import CustomCheckBox from 'components/checkbox';

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    onChangeParams: (x: any) => void,
    entries: PaginationProps<IDriverFilter>
    loading?: boolean
}

export function SelectTransshipPoint({ open, setOpen, onChangeParams, entries, loading }: IProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTransshipPoints, setSelectedTransshipPoints] = useState<number[]>([]);

    const handleInterior = () => {
        if (loading) return;

        const listIds = entries.results
            .filter(({ id }) => id !== 1)
            .map(({ id }) => id);

        setSelectedTransshipPoints(prevState => {
            const updatedList = prevState.length > 1
                ? prevState.filter(id => id === 1)
                : [...prevState, ...listIds];
            return updatedList;
        });
    }

    const handleCapital = () => {
        if (loading) return;
        setSelectedTransshipPoints(prevState => {
            if (prevState.includes(1)) {
                return prevState.filter(id => id !== 1);
            } else {
                return [...prevState, 1];
            }
        });
    }

    useEffect(() => {
        onChangeParams({ "transshipPoints": selectedTransshipPoints.join(","), "page": 1 });
    }, [selectedTransshipPoints]);

    if (!open) return null;

    return (
        <>
            <SelectBack className="popupBack" onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
            }} />
            <TransshipPointSelectContainer>
                <div className="list">
                    <div className="entry" onClick={handleInterior}>
                        <button type="button" className="entryName">{'Interior'}</button>
                        <CustomCheckBox
                            checked={selectedTransshipPoints.length > 1}
                            size='small'
                            disabled={loading}
                        />
                    </div>
                    <div className="entry" onClick={handleCapital}>
                        <button type="button" className="entryName">{'Capital'}</button>
                        <CustomCheckBox
                            checked={selectedTransshipPoints.includes(1)}
                            size='small'
                            disabled={loading}
                        />
                    </div>
                </div>
            </TransshipPointSelectContainer>
        </>
    );
}
