import { IBoxes } from "interfaces/IBoxes";
import { FormContainer } from "./styles";
import { dateFormat } from "utils/dateFormat";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { toast } from "react-toastify";

interface IProps {
  data: IBoxes,
  onSubmit: (x: IBoxes) => void,
  setOpen: (x: boolean) => void,
  updateData: (x: IBoxes, y?: boolean) => void
}

export function LastRouteForm({ data, onSubmit, setOpen, updateData }: IProps) {

  const handleBoxConfirmed = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = parseInt(event.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    } else if (newValue < 0) {
      toast.warning("O valor não pode ser menor que 0!");
      newValue = 0;
    }
    event.target.value = newValue.toString();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    toast.info("Enviando dados...");

    const boxSentInput = document.querySelector<HTMLInputElement>('input[name="boxesSent"]');
    const boxReturnedInput = document.querySelector<HTMLInputElement>('input[name="boxesReturned"]');

    const boxesSent = boxSentInput ? parseInt(boxSentInput.value) : 0;
    const boxesReturned = boxReturnedInput ? parseInt(boxReturnedInput.value) : 0;

    const newData = {
      ...data,
      lastRoute: {
        ...data.lastRoute,
        boxesSent: boxesSent,
        boxesReturned: boxesReturned
      }
    };

    setOpen(false);
    updateData(newData as IBoxes);
    updateData(newData as IBoxes, true);
    onSubmit(newData.lastRoute as IBoxes);
  };

  return (
    <FormContainer id="popupForm" onSubmit={e => handleSubmit(e)}>
      <Table size="small">
        <TableBody>
          <TableRow className="tableRows">
            <TableCell>Motorista</TableCell>
            <TableCell align="center">{data.lastRoute?.vehicle?.driver?.name || '-'}</TableCell>
          </TableRow>
          <TableRow className="tableRows">
            <TableCell>Rota</TableCell>
            <TableCell align="center">{data.lastRoute?.route || '-'}</TableCell>
          </TableRow>
          <TableRow className="tableRows">
            <TableCell>Data</TableCell>
            <TableCell align="center">{(data.lastRoute?.deliveryDate && dateFormat(data.lastRoute?.deliveryDate)) || '-'}</TableCell>
          </TableRow>
          <TableRow className="tableRows">
            <TableCell>Comentário</TableCell>
            <TableCell align="center">{data.lastRoute?.returnComment || '-'}</TableCell>
          </TableRow>
          <TableRow className="tableRows">
            <TableCell>First Mile</TableCell>
            <TableCell align="center">{data.lastRoute?.firstMile || '-'}</TableCell>
          </TableRow>
          <TableRow className="tableRows">
            <TableCell>Caixas Enviadas</TableCell>
            <TableCell align="center">
              <input
                type="number"
                name="boxesSent"
                className={"boxInput"}
                defaultValue={data.lastRoute?.boxesSent || 0}
                onChange={handleBoxConfirmed}
              />
            </TableCell>
          </TableRow>
          <TableRow className="tableRows">
            <TableCell>Caixas Retornadas</TableCell>
            <TableCell align="center">
              <input
                type="number"
                name="boxesReturned"
                className={"boxInput"}
                defaultValue={data.lastRoute?.boxesReturned || 0}
                onChange={handleBoxConfirmed}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </FormContainer>
  )
}

