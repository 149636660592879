import { ProductEntry } from "../ProductEntry"
import { ProductListContainer } from "./styles"
import { useEffect, useState } from "react"
import SearchIcon from "assets/searchIcon.svg"
import { IProductConfig, SensitiveMap } from "interfaces/ISensitiveItems"
import { useDebaunce } from "utils/debaunce"
import { productService } from "services/productService"
import { toast } from "react-toastify"
import { ProductSearchPopup } from "../SearchPopup"

interface IProps {
    data: IProductConfig[],
    setData: React.Dispatch<React.SetStateAction<IProductConfig[]>>
    isSensitiveMap: SensitiveMap,
    setIsSensitiveMap: React.Dispatch<React.SetStateAction<SensitiveMap>>
}

export function ProductList({data, setData, isSensitiveMap, setIsSensitiveMap}: IProps) {
    const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })
    const [results, setResults] = useState<IProductConfig[]>([] as IProductConfig[])
    const [searchPopupVisible, setSearchPopupVisible] = useState(false)
    const [params, setParams] = useState({search: '', page: 0})

    const fetchSearchData = () => {
        productService.getProducts(params)
        .then(response => {
            setResults(response.results)
        }).catch(() => {
            toast.error("Não foi possível buscar no banco de dados")
        })
    }

    function onChangeParams(params: any) {
        setParams((state: any) => ({ ...state, ...params }))
    }

    const handleSearch = (value: string) => {
        setSearchPopupVisible(true)
        debaunceFilter({search: value, page: 0})
    }

    const handleUpdateSensitivity = (product: IProductConfig, isSensitive: boolean) => {
        if (data.findIndex(target => target.id === product.id) === -1) {
            setData(prevData => [...prevData, {
                ...product,
                isSensitive: isSensitive,
                isNew: true
            }])
        }
        setIsSensitiveMap((prevMap) => ({...prevMap, [product.id]: isSensitive}))
    }

    const handleUpdateComment = (product: IProductConfig, comment: string) => {
        setData((prevData) => {
            const updatedData = [...prevData]
            const indexToUpdate = updatedData.findIndex((item) => item.id === product.id)
        
            if (indexToUpdate !== -1) {
              updatedData[indexToUpdate] = {
                ...updatedData[indexToUpdate],
                comment: comment,
                isNew: true,
              }
            }
        
            return updatedData
          })
    }

    useEffect(() => {
        if (!params.search) {
            setResults([])
            setSearchPopupVisible(false)
        }
        fetchSearchData()
    }, [params])

    useEffect(() => {
        setIsSensitiveMap(
            data.reduce((map, product) => {
                map[product.id] = product.isSensitive
                return map
            }, {} as SensitiveMap)
        )
    }, [data])

    return (
        <ProductListContainer>
            <div className="header">
                <div className="searchBar">
                    <img src={SearchIcon} className="searchIcon" />
                    <input type="text" className="searchInput" placeholder="Produto ou SKU" onChange={(e) => handleSearch(e.target.value.trim())}/>
                    {searchPopupVisible && <ProductSearchPopup handleUpdate={handleUpdateSensitivity} data={results} setOpen={setSearchPopupVisible} sensitiveMap={isSensitiveMap}/>}
                </div>
            </div>
            <span className="listDesc">Itens sensíveis</span>
            <div className="list">
                {data.sort((a, b) => parseInt(a.sku) - parseInt(b.sku)).map(product => (
                    <ProductEntry
                    data={product}
                    isSensitive={isSensitiveMap[product.id]}
                    handleUpdateSensitivity={handleUpdateSensitivity}
                    handleUpdateComment={handleUpdateComment}
                    key={product.sku}/>
                ))}
            </div>
        </ProductListContainer>
    )
}