import { Checkbox } from "@mui/material";
import { IDriver } from "interfaces/IDriver";
import { ITeams } from "interfaces/ITeams";
import { defaultTheme } from "styles/theme/default";

import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useEffect, useState } from "react";

interface IProps {
    element: IDriver,
    data: ITeams,
    handleCheck: (x: IDriver, y: boolean, z?: boolean) => void,
    memberList?: IDriver[]
}

export function DriverListEntry({ element, data, handleCheck, memberList }: IProps) {
    const [driverCheck, setDriverCheck] = useState(
        data.id ? data.id === element.team?.id :
        (memberList?.find(driver => driver.id === element.id) ? true : false)
    )
    const [leaderCheck, setLeaderCheck] = useState(
        element.id === data.leader?.leader?.id
    )
    
    return (
        <div className="entry">
            <div className="entryName">
                <Checkbox className="driverListCheck" sx={{
                    cursor: "default",
                    width: 16,
                    height: 16,
                    color: defaultTheme.colors['gray-300'],
                    '&.Mui-checked': {
                        color: defaultTheme.colors['success'],
                    },
                    '&.Mui-disabled': {
                        color: defaultTheme.colors['white-box'],
                        backgroundColor: defaultTheme.colors['white-box'],
                        borderRadius: "2px"
                    },
                }}
                    defaultChecked={driverCheck}
                    disabled={leaderCheck === true || element.team !== null && element.team?.id !== data.id}
                    onChange={(e) => {
                        handleCheck(element, e.target.checked, element.isLeader)
                        setDriverCheck(e.target.checked)
                    }}
                    disableRipple />
                <span className="driverName">{element.name + (element.surname ? " " + element.surname : "")}</span>
            </div>
            <div className="entryInfo">
                {element?.team?.id === data?.id || element.team === null ?
                    <Checkbox
                        className='leaderCheck'
                        icon={<StarBorderRoundedIcon />}
                        checkedIcon={<StarRoundedIcon />}
                        sx={{
                            cursor: "default",
                            width: 20,
                            height: 20,
                            '& .MuiSvgIcon-root': { fontSize: '1.75rem' },
                            color: defaultTheme.colors['gray-300'],
                            '&.Mui-disabled': {
                                color: defaultTheme.colors['gray-100'],
                            },
                            '&.Mui-checked': {
                                color: defaultTheme.colors['logo-yellow'],
                            },
                        }}
                        defaultChecked={leaderCheck}
                        disabled={driverCheck === false}
                        onChange={(e) => {
                            handleCheck(element, driverCheck, e.target.checked)
                            setLeaderCheck(e.target.checked)
                        }} />
                    : <span className="otherName">Time {element?.team?.name}</span>}
            </div>
        </div>
    )
}