import { Link } from "react-router-dom"
import { CardContainer } from "./styles"

export default function Card(props: {title: string, goto: string, description?: string}){
    return (
        <CardContainer>
            <div className="cardItem">
                <Link to={props.goto} className="cardLink"/>
                <b>{props.title}</b>
                <p>{props.description}</p>
            </div>
        </CardContainer>
    )
}