import { ICity } from "interfaces/ICity"
import { CityLineContainer } from "./styles";
import { useEffect, useState } from "react";
import { mask, unMask } from "remask";

interface IProps {
    data: ICity,
    handleUpdate: (x: ICity, y: boolean) => void,
    canEdit: boolean
}

export function CityLine ({data, handleUpdate, canEdit}: IProps){
    const [isDifferent, setIsDifferent] = useState(false)
    const [value, setValue] = useState(data.priority)
    const priorityPattern = ['A']

    const handleChange = (newPriority: string) => {
        const newElement = {...data, priority: newPriority || data.priority}
        handleUpdate(newElement, newPriority === data.priority)
    }

    useEffect(() => {
        if (!canEdit) {
            setValue(data.priority)
            setIsDifferent(false)
            handleChange(data.priority)
        }
    }, [canEdit])

    return (
        <CityLineContainer className="city">
            <span className="small id">{data.id}</span>
            <span className="large city">{data.name}</span>
            <span className="small uf">{data.state}</span>
            <span className="small cdId">{data.depot?.id}</span>
            <input type="text" className={`priorityInput ${isDifferent && "updated"}`} maxLength={1}
            pattern="[a-zA-Z]" value={value} placeholder={data.priority} disabled={!canEdit}
            onChange={(e) => {
                const originalValue = unMask(e.target.value).toLocaleUpperCase()
                e.target.value = mask(originalValue, priorityPattern)
                setValue(e.target.value)
                setIsDifferent(e.target.value !== data.priority)
            }}
            onBlur={(e) => {
                if (e.target.value) {
                    handleChange(e.target.value)
                }
            }}/>
        </CityLineContainer>
    )
}