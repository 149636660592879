import { IAuthentication } from 'interfaces/IAuthentication'
import { ILogin } from 'interfaces/ILogin'
import cookies from 'js-cookie'

import { api as apiService, ApiService } from './api'
import { msUrl } from 'settings'


class AuthenticationService {
  constructor(
    private readonly api: ApiService, 
    private readonly url: string
  ) {}

  public postLogin = async (login: ILogin): Promise<IAuthentication> => {
    const data = await this.api.post(`${this.url}/api/auth-internal/v2/token`, login)
    cookies.set("authToken", data.access)
    return data
  }
}

export const authenticationService = new AuthenticationService(apiService, msUrl)
