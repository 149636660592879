import dayjs from "dayjs";
import { FilterSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { dateToday } from "utils/dateToday";
import { TeamSelect } from "./SelectTeam";
import { api } from "services/api";
import { baseURL } from "settings";
import { PaginationProps } from "interfaces/IPagination";
import { ITeams } from "interfaces/ITeams";
import arrowFilter from 'assets/arrowFilter.svg'
import { RegionSelect } from "./SelectRegion";
import { IPunctuality } from "interfaces/IPunctuality";

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParams: (x: any) => void
}

const today = new Date();
const last7Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
const last15Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);
const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

const formatDate = (date: any) => {
  return dayjs(date).format('YYYY-MM-DD');
}

const initialEntries = [
  {
    id: 1,
    name: "último mês",
    query: formatDate(lastMonth),
    isChecked: false,
  },
  {
    id: 2,
    name: "últimos 15 dias",
    query: formatDate(last15Days),
    isChecked: false,
  },
  {
    id: 3,
    name: "últimos 7 dias",
    query: formatDate(last7Days),
    isChecked: false,
  },
];

export function FilterPunctuality({ open, setOpen, onChangeParams }: IProps) {
  const [selectedDate, setSelectedDate] = useState<string[]>([]) 
  const [entriesState, setEntriesState] = useState(initialEntries);
  const [selectedCheckbox, setSelectedCheckbox] = useState<number>(-1);
  const [entries, setEntries] = useState<PaginationProps<ITeams>>({} as PaginationProps<ITeams>)
  const [regions, setRegions] = useState<IPunctuality[]>([])
  const [params, setParams] = useState({ page: 1, pageSize: 300, search: ''})
  const [teamSelect, setTeamSelect] = useState(false)
  const [regionSelect, setRegionSelect] = useState(false)

  const handleCheckbox = async (index: number, element: string) => {
    const newSelectedDates = [...selectedDate];
    if (selectedCheckbox === index) {
      setSelectedCheckbox(-1);
      newSelectedDates.splice(newSelectedDates.indexOf(element), 1);
    } else {
      setSelectedCheckbox(index);
      newSelectedDates.splice(0, newSelectedDates.length, element);
    }
    setSelectedDate(newSelectedDates);
  };

  const fetchDataTeams = async () => {
    await api.get(`${baseURL}/v1/delivery/team`, {
      params
    })
      .then((response) => {
        setEntries(response);
      })
  };

  const fetchDataRegions = async () => {
    await api.get(`${baseURL}/v1/delivery/driver/punctuality`, {
      params
    })
      .then((response) => {
        setRegions(response);
      })
  };

  useEffect(() => { 
    if (selectedDate.length > 0) {
      onChangeParams({ "startDate": selectedDate.join(",") })
    }
    else {
      onChangeParams({ "startDate": dateToday() })
    }
  }, [selectedDate]);

  useEffect(() => {
    fetchDataTeams()
    fetchDataRegions()
  }, [params])

  const display = open ? 'initial' : 'none'

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }}
      style={{ display }}
      />
      <FilterSelectContainer style={{ display }}>
        <div className="list">
        {entriesState.map((item, index) => (
          <div className='entry' key={item.id} onClick={() => handleCheckbox(index, item.query)}>
            <button type="button" className="entryName">{item.name}</button>
            <span className={`bullet ${selectedCheckbox === index ? 'checked' : ''}`}></span>
          </div>
        ))}
          <div className="entry">
            <button type="button" className="entryName" onClick={() => setTeamSelect(true)}>Time</button>
            <img src={arrowFilter} alt='icon arrow filter' />
          </div>
          <div className="entry">
            <button type="button" className="entryName" onClick={() => setRegionSelect(true)}>Região</button>
            <img src={arrowFilter} alt='icon arrow filter' />
          </div>
        </div>
        <TeamSelect entries={entries} onChangeParams={onChangeParams} open={teamSelect} setOpen={setTeamSelect} />
        <RegionSelect entries={regions} onChangeParams={onChangeParams} open={regionSelect} setOpen={setRegionSelect} />
      </FilterSelectContainer>
    </>
  )
}
