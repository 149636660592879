import styled from "styled-components";
import { defaultTheme } from 'styles/theme/default'

export const Container = styled.div`
  max-width: 60vw;
  width: 600px;
  min-width: 300px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  overflow-y: auto;

  cursor: default;

  background: #FFFFFF;
  border-radius: 24px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.21);

  .content  {
    margin: 2px 13px;
    cursor: pointer;
  }

  .icon {
    font-size: 14px;
    color: ${defaultTheme.colors['base']};
  }

  p {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 0.8rem;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #757575;
    font-weight: 400;
  }

  .percentage {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #353535;
  }

  .line {
    width: 60px;
    margin: auto;
    height: 10px;

    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }

  @media screen and (max-width: 769px) {
    min-width: 85vw;
  }
`
