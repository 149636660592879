import { IProductConfig } from "interfaces/ISensitiveItems"
import { ProductEntryContainer } from "./styles"
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from "react";

interface IProps {
    data: IProductConfig,
    isSensitive: boolean,
    handleUpdateSensitivity: (x: IProductConfig, y: boolean) => void
    handleUpdateComment: (x: IProductConfig, y: string) => void
}

export function ProductEntry({data, handleUpdateSensitivity, handleUpdateComment, isSensitive}: IProps) {
    const [comment, setComment] = useState(data.comment || "")

    const handleSubmit = () => {
        handleUpdateSensitivity(data, !isSensitive)
    }

    const handleChange = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setComment(e.target.value)
    }

    const handleBlur = () => {
        handleUpdateComment(data, comment)
    }

    return (
        <ProductEntryContainer isSensitive={isSensitive} className="productEntry">
            <div className="left">
                <span className="sku">{data.sku}</span>
                -
                <span className="name">{data.name}</span>
            </div>
            <div className="right">
                <input type="text" className="observation" placeholder="Comentário" value={comment} onChange={handleChange} onBlur={handleBlur} />
                <button type="button" className="removeBtn" onClick={handleSubmit}>
                    {isSensitive ? <DoNotDisturbOnIcon/> : <AddCircleIcon/>}
                </button>
            </div>
        </ProductEntryContainer>
    )
}