import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const ObsBtnContainer = styled.button`
    border: none;
    background-color: transparent;
`

export const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`;

export const balanceColor = (sent: number, returned: number) => {
  const diff = sent - returned;
  if (!diff) return {}
  if (diff > 0) return { color: defaultTheme.colors['danger'] }
  return { color: defaultTheme.colors['base'] }
}
