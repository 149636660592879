import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

export const StyledUnavailableIcon = styled(NoAccountsIcon) <{ isUnavailableActive: boolean }>`
    color: ${props => props.isUnavailableActive ? props.theme.colors['logo-yellow'] : props.theme.colors['gray-100']};
    opacity: ${props => props.isUnavailableActive ? 1 : 0.8};
    cursor: pointer;

    &:hover {
        color: ${defaultTheme.colors['logo-yellow']};
        opacity: 1;
        transition: all 0.2s;
    }
`;

export const Line = styled.tr<{stripColor: string}>`
    width: 100%;
    height: 100%;

    td{
      padding-right: 18px;
    }

    td:first-child, td:nth-child(2),:nth-child(3){
      padding-right: 0;
    }

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }

    .iconContainer {
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .spacer{
      width: 10px;
    }

    .driverContainer {
      height: 40px;
      display: flex;
      align-items: center;
      gap: 7%;
    }

    .cardStrip {
      height: 100%;
      background-color: ${props => props.stripColor};
      width: 10px;
    }

    .routeContainer {
        position: relative;
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60px;
        button {
            pointer-events: all;
            border: none;
            background-color: transparent;
        }
        .editButton svg {
            position: relative;
            top: 2px;
        }
    }

    .removeButton {
      position: absolute;
      right: 0;
      top: 54%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      width: 10px;
      border-radius: 8px;
      :hover {
        svg, img {
              fill: ${defaultTheme.colors['danger']};
            }
          }
      :disabled {
        display: none;
      }
    }

    .routeInput {
        width: 112px;
        padding: 0 12px;
        box-shadow: inset 0px 0px 1px 1px rgba(154, 154, 154, 0.15);
        background-color: ${defaultTheme.colors['base-input']};
        color: black;
        border-radius: 0;
        height: 32px;
    }

    input.routeInput::-webkit-outer-spin-button,
    input.routeInput::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .routeBoxesContainer {
      margin-left: 5%;
    }
    .capacityContainer, .routeBoxesContainer {
      text-align: center
    }

    .routeDoneContainer {
        margin-left: 3.8%;
        width: 10.7%;
        text-align: center
    }

      .changeDepot {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
    }

    .depotBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 45%;
        background: ${defaultTheme.colors['white-box']};
        border: 0.5px solid #C4C4C4;
        border-radius: 36px;
        transition: all 100ms;

        svg, img {
            position: relative;
            top: 1px;
            left: 4px;
            fill: ${defaultTheme.colors['gray-200']}
        }

        :disabled {
            display: none;
        }


    }

    @media screen and (max-width:769px){
        .driverContainer {
          gap: 8%;
        }
    }
`
