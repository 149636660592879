import { PriorityContainer } from "../styles"
import SearchIcon from 'assets/searchIcon.svg'
import { Input } from "components/Input"
import { useEffect, useRef, useState } from "react"
import { useDebaunce } from "utils/debaunce"
import { toast } from "react-toastify"
import { LinearProgress } from "@mui/material"
import { DraggableRegionLine } from "./Draggable"
import { IRegion } from "interfaces/IRegion"
import { priorityService } from "services/priorityService"

export function RegionPriority() {
    const [params, setParams] = useState({ search: '' })
    const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IRegion[]>([])
    const [sortedData, setSortedData] = useState<IRegion[]>([])
    const [canEdit, setCanEdit] = useState(false)
    const [hasChanges, setHasChanges] = useState(false)
    const dragItem = useRef<any>(null)
    const dragOverItem = useRef<any>(null)

    const deepCopy = (list: IRegion[]) => list.map(region => ({ ...region }))

    const fetchData = () => {
        setLoading(true)
        priorityService.getRegions()
            .then((response) => {
                setData(response)
                setSortedData(deepCopy(response))
            }).catch((e) => {
                toast.error("Houve um erro ao contatar o banco de dados")
            }).finally(() => {
                setLoading(false)
            })
    }

    function onChangeParams(params: any) {
        if (loading) return
        setParams((state: any) => ({ ...state, ...params }))
    }

    const sortList = () => {
        if (dragOverItem.current === dragItem.current) return

        let list = [...sortedData]
        const first = list[dragItem.current]
        const second = list[dragOverItem.current]

        const draggedItem = list.splice(dragItem.current, 1)[0]

        list.splice(dragOverItem.current, 0, draggedItem)

        first.priority = dragOverItem.current + 1
        second.priority = dragItem.current + 1

        dragItem.current = null
        dragOverItem.current = null

        setSortedData(list)
        setHasChanges(true)
    }

    const handleSave = () => {
        const newList = sortedData.filter(region => {
            const dataRegion = data.find(element => element.id === region.id)

            if (region.priority === dataRegion?.priority) return false

            return true
        })
        toast.info("Aguarde...")
        priorityService.updateRegions(newList)
            .then(() => {
                toast.success("Prioridades alteradas com sucesso!")
                handleCancel()
            }).catch(err => {
                toast.error("Não foi possível alterar as prioridades")
            })
    }

    const handleCancel = () => {
        setSortedData(deepCopy(data))
        setCanEdit(false)
        setHasChanges(false)
        fetchData()
    }

    const sortRegions = (a: IRegion, b: IRegion): number => {
        return a.priority === null ? 1 :
            b.priority === null ? -1 :
            a.priority - b.priority;
    }

    useEffect(() => {
        fetchData()
    }, [params])

    return (
        <PriorityContainer className="wide">
            <div className="header">
                <div className="input" style={{ width: "100%" }}>
                    <img className="searchIcon" src={SearchIcon} alt="" />
                    <Input className="inputBox" placeholder="Nome ou ID"
                        onChange={e => debaunceFilter({ search: e.target.value, page: 1 })} />
                </div>
                <div className="right">
                    <div className="editBtns">
                        {canEdit &&
                            <button className="cancelBtn" onClick={handleCancel}>Cancelar</button>
                        }
                        <button className="editBtn"
                            onClick={() => canEdit ? handleSave() : setCanEdit(true)}
                            disabled={canEdit && !hasChanges}>
                            <span>{canEdit ? "Salvar" : "Editar"}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="main">
                <div className="columns">
                    <span className="line">ID</span>
                    <span className="line name">Nome</span>
                    <span className="line">Sigla</span>
                    <span className="line">CD ID</span>
                </div>
                {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
                <div className="list">
                    {(canEdit ? sortedData : data)
                        ?.sort(sortRegions)
                        .map((region, index) => (
                            <DraggableRegionLine
                                data={region}
                                index={index}
                                dragItem={dragItem}
                                dragOverItem={dragOverItem}
                                sortList={sortList}
                                draggable={canEdit}
                                key={region.id} />
                        ))}
                </div>
            </div>
        </PriorityContainer>
    )
}