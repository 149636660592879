import { Input } from "components/Input";
import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import SearchIcon from 'assets/searchIcon.svg'
import { Container, Line, MainContainer, SidebarContainer } from './styles';
import { ColumnProps, DataTable } from "components/Table";
import { baseURL } from "settings";
import { api } from "services/api";
import { useEffect, useState } from "react";
import { EditButton } from "components/EditButton";
import { Popup } from "components/Popup";
import { PaginationProps } from "interfaces/IPagination"
import { Pagination } from "@mui/material";
import { useDebaunce } from "utils/debaunce";
import { PageContainer } from "components/PageContainer";
import cookies from 'js-cookie'
import { toast } from "react-toastify";
import { ClientScoreForm } from "components/PopupForms/ClientScoreForm";
import { IClientScore } from "interfaces/IClientScore";
import { Toolbar } from "components/Toolbar";
import { clientService } from "services/clientService";

const columns: ColumnProps[] = [
    {
        name: "Nome",
        align: "center"
    },
    {
        name: "Client ID",
        align: "center"
    },
    {
        name: "Score",
        align: "center"
    },
    {
        name: "Obs.",
        align: "center"
    },
    {
        name: "",
    },
]

export function ClientPage() {
    const [formData, setFormData] = useState<IClientScore>({} as IClientScore)
    const [loading, setLoading] = useState(true);
    const [popupVisible, setPopupVisible] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [params, setParams] = useState({ page: 1, pageSize: 30, search: '' })
    const [dataTable, setDataTable] = useState<PaginationProps<IClientScore>>({} as PaginationProps<IClientScore>)
    const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })

    const fetchClientData = () => {
        api.get(`${baseURL}/v1/delivery/client-score`, {
            params,
            headers: {
                Authorization: `Bearer ${cookies.get('authToken')}`,
            },
        })
            .then((response) => {
                setDataTable(response)
            })
            .catch(e => {
                toast.error("Houve um erro ao buscar os dados no sistema")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        debaunceFilter({ page })
    }

    const handleSubmit = (data: IClientScore, comment: string) => {
        const newData = {
            ...data,
            comment: comment
        }
        toast.info("Aguarde...")
        clientService.updateClient(newData)
        .then(() => {
            toast.success("Dados salvos com sucesso!")
            setPopupVisible(false)
            fetchClientData()
        }).catch(() => {
            toast.error("Houve um erro ao tentar salvar os dados")
        })
    }

    function onChangeParams(params: any) {
        setParams(state => ({ ...state, ...params }))
    }

    useEffect(() => {
        fetchClientData()
    }, [params])

    return (
        <Container>
            <Header />
            <Popup
                popupTitle="Informações do Cliente"
                open={popupVisible} setOpen={setPopupVisible}
                content={<ClientScoreForm data={formData} handleSubmit={handleSubmit} />}
                isEditPopup
            />
            <div className="pageContent">
                <SidebarContainer>
                    <SidebarComponent />
                </SidebarContainer>

                <PageContainer>
                    <MainContainer>
                        <div className='infos'>
                            <h3>Clientes</h3>
                            <Toolbar
                                params={params}
                                paramState={setParams}
                                loadingState={loading}
                                page="ScoreClient"
                                placeholder="Cliente, região ou endereço"
                                hideFilter
                            />
                        </div>
                            <DataTable
                                loading={loading}
                                columns={columns}
                            >
                                {dataTable?.results?.filter(item => item?.id?.toString().includes(searchTerm))
                                    .map(element => (
                                        <Line className="bodyLine" key={element?.id}>
                                            <td className='contentTable contentName'>{element?.clientName}</td>
                                            <td className='contentTable'>{element?.clientId}</td>
                                            <td align="center" className='contentTable contentScore'>
                                                {element?.score.toFixed(0)} {element?.score > 10 ? <div className="scoreGreen"></div> : <div className="scoreRed"></div>}
                                            </td>
                                            <td className="contentTable contentComment"><span>{element.comment}</span></td>
                                            <td align="center" className='contentTable contentEdit'>
                                                <EditButton onClick={() => {
                                                    setPopupVisible(true)
                                                    setFormData(element)
                                                }} />
                                            </td>
                                        </Line>
                                    ))}
                            </DataTable>
                        <Pagination
                            shape="rounded"
                            count={dataTable?.totalPages}
                            page={params.page}
                            onChange={handlePageChange}
                            className='pagination'
                        />
                    </MainContainer>
                </PageContainer>
            </div>
        </Container>
    )
}
