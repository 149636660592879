import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const HorizontalScroll = styled.div`
    width: fit-content;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
`

export const DeliveryListContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    gap: 12px;
    align-items: center;
    overflow: visible;

    .scrollBtn {
        position: absolute;
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        border-radius: 8px;
        width: 32px;
        height: 32px;
        transition: all 100ms;

        img, svg {
            fill: ${defaultTheme.colors['gray-300']}
        }

        &.left {
            left: -15%;
        }

        &.right {
            right: -15%;
        }

        :hover {
            img, svg {
                fill: ${defaultTheme.colors['base']}
            }
        }

        :disabled {
            pointer-events: none;
            img, svg {
                fill: ${defaultTheme.colors['gray-100']};
            }
        }
    }

    .scrollContainer {
        width: 100%;
        overflow: scroll;
        /* transition: all 200ms; */
        scroll-behavior: smooth;

        ::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .deliveryOrder {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;

        img, svg {
            transition: all 250ms;
        }
        :hover {
            img, svg {
                filter: brightness(0.7) saturate(1.8);
            }
        }
    }

    .deliveryBox {
        position: absolute;

        &.green > path {
            fill: #B0DFBD;
        }
        &.red > path {
            fill: #FF909D;
        }
        &.blue > path {
            fill: #a9d9f9;
        }
    }

    .orderNumberContainer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 7px;
    }

    .orderNumber {
        user-select: none;
        position: relative;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        font-size: 0.7rem;
    }
`
