import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const DeliveryCardContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;

    .driverDelivery {
        position: relative;
        top: -30%;
        width: 30.5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .driverName {
        width: 100%;
    }

    .deliveryAmount {
        font-size: 0.8rem;
        color: ${defaultTheme.colors['gray-300']}
    }

    .routeData {
        width: 16%;
        /* text-align: center; */
    }

    .vehicleModel {
        width: 13.5%;
    }

    .deliveryList {
        width: 37%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 769px) {
      .deliveryList{
        width: 42%;
      }
      .routeData{
        font-size: 0.85rem;
        margin-left: 25px;
        margin-top: 5px;
        width: 24%;
      }
      .vehicleModel{
        display: none;
      }
      .driverName{
        font-size: 0.8rem;
      }
    }
`
