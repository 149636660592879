import { baseURL } from 'settings';
import { dateToday } from 'utils/dateToday';

import { api as apiService, ApiService } from './api'


class PdfService {
  constructor(private readonly api: ApiService, private readonly baseURL: string) {}

  public downloadPdf = async (teamId?: string) => {
    const date = dateToday()
    return await this.api.get(`${this.baseURL}/v1/delivery/waybill/report/filename?deliveryDate=${date}`, { params: {
      'teamId': teamId
    }, responseType: 'arraybuffer' });
  }

  public downloadZip = async (byTeam?: boolean) => {
    const date = dateToday()
      return await this.api.get(`${this.baseURL}/v1/delivery/waybill/report/filename.zip?deliveryDate=${date}`, { params: {
        'byTeam': byTeam
      }, responseType: 'blob' });
  }
}

export const pdfService = new PdfService(apiService, baseURL)
