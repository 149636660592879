import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import { Container, MainContainer, SidebarContainer } from './styles';
import { baseURL } from "settings";
import { useEffect, useState } from "react";
import { api } from "services/api";
import { PaginationProps } from "interfaces/IPagination";
import { useDebaunce } from "utils/debaunce";
import { PageContainer } from 'components/PageContainer'
import { Toolbar } from "components/Toolbar";
import { DataCard } from "components/DataCard";
import { useMediaQuery } from "react-responsive";
import { DeliveryCard } from "components/DataCard/DeliveryCard";
import { IDelivery } from "interfaces/IDeliveryAttempt";
import { LinearProgress } from "@mui/material";
import { dateToday } from 'utils/dateToday';
import { toast } from 'react-toastify';


export function Deliveries() {
    const isTablet = useMediaQuery({ maxWidth: 820 })
    const [dataPagination, setDataPagination] = useState<PaginationProps<IDelivery>>({} as PaginationProps<IDelivery>)
    const [joinedData, setJoinedData] = useState<{ [index: string]: IDelivery[] }>({})
    const [params, setParams] = useState({ page: 0, pageSize: 1000, search: '', minDate: dateToday(), maxDate: dateToday() })
    const [loading, setLoading] = useState(true)

    const [paramsDate, setParamsDate] = useState({ startDate: dateToday(), endDate: dateToday() })

    const fetchData = () => {
        setLoading(true)
        api.get(`${baseURL}/v1/delivery/delivery-status`, { params })
            .then((response) => {
                setDataPagination(response)
                joinData(response.results)
            }).catch(e => {
                toast.error("Não foi possível buscar os dados no sistema")
            }).finally(() => {
                setLoading(false)
            })
    }

    const joinData = (data: any[]) => {
        let driverList: { [index: string]: IDelivery[] } = {}

        driverList = data.reduce((acc, delivery) => {
            if (delivery?.driver?.name && !acc[delivery?.driver?.name]) {
                acc[delivery.driver.name] = []
            }
            delivery?.driver?.name && acc[delivery?.driver?.name].push(delivery)
            return acc
        }, driverList)
        setJoinedData(driverList)
    }

    useEffect(() => {
        fetchData()
    }, [params])

    return (
        <Container>
            <Header />
            <div className="pageContent">
                <SidebarContainer>
                    <SidebarComponent />
                </SidebarContainer>
                <PageContainer>
                    <MainContainer>
                        <div className='infos'>
                            <h3>Entregas</h3>
                            <Toolbar
                                params={params}
                                paramState={setParams}
                                paramsDate={paramsDate}
                                paramsDateState={setParamsDate}
                                loadingState={loading}
                                minSearchSize={4}
                                placeholder="Pedido, Cliente, Rota ou Data"
                                page='Deliveries'
                            />
                        </div>
                        <div className='spacer' style={{ height: '20px' }}></div>
                        <div className="deliveryCardHeader">
                            <span className='driverName'>{isTablet ? "Nome" : "Nome do Motorista"}</span>
                            <span className='route'>Rota</span>
                            <span className='model'>Modelo</span>
                            <span className='deliveries'>Entregas</span>
                        </div>
                        {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
                        <div className="deliveryContainer">
                            {Object.entries(joinedData)?.filter(entry => entry[1][0].driver?.name)
                                .map((element, index) => (
                                    <DataCard key={index} stripColor={element[1][0].driver?.team?.colorTeam} stripWidth="16px">
                                        <DeliveryCard data={element[1]} />
                                    </DataCard>
                                ))}
                        </div>
                    </MainContainer>
                </PageContainer>
            </div>
        </Container>
    )
}
