import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const OrderContainer = styled.div`
    width: 100%;
    height: 56px;
    font-family: Roboto;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;

    .orderId {
        width: 20%;
    }

    .clientName {
        width: 42%;
    }

    .boxCount {
        justify-content: center;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .boxInput {
        width: 50px;
        padding: 0;
        height: 40;
        border: none;
        border-radius: 8px;
        background-color: ${defaultTheme.colors['base-input']};
        text-align: center;
        ::placeholder {
            color: ${defaultTheme.colors['gray-100']};
        }
        :focus {
            background-color: ${defaultTheme.colors['base-light']};
        }

        &.changed {
            color: ${defaultTheme.colors['transit']};
            font-weight: bolder;
        }
    }
`

export const Divider = styled.div`
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    border-bottom: 1px solid ${defaultTheme.colors['gray-100']};
    width: 80%;

`

