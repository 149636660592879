import { Button } from "components/Button";
import { ConfirmContainer } from "./styles";

interface IProps {
    states: any,
    handleDelete?: (x: any) => void
}

export function ConfirmPopup({ states, handleDelete }: IProps) {
    const [popupState, setPopupState] = states;

    if (!popupState) return null

    return (
        <ConfirmContainer onClick={() => setPopupState(false)}>
            <div className="backdrop" ></div>
            <div className="popup" onClick={(e) => e.stopPropagation()}>
                <div className="header">
                    <h1 className="confirmText">Tem certeza que deseja excluir esse item?</h1>
                </div>
                <div className="confirmBtnContainer">
                    <Button className="cancelBtn" text="Cancelar" onClick={() => setPopupState(false)} />
                    <Button className="deleteBtn" text="Excluir" onClick={handleDelete} />
                </div>
            </div>
        </ConfirmContainer>
    )
}
