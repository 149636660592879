import SearchIcon from 'assets/searchIcon.svg'
import { SelectContainer, SelectBack } from "./styles";
import { useDebaunce } from "utils/debaunce";
import { useEffect, useRef, useState } from "react";
import { baseURL } from "settings";
import { api } from "services/api";
import { PaginationProps } from 'interfaces/IPagination';
import { Input } from 'components/Input';
import { IDist } from 'interfaces/IDist';

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    handleSelect: (x: any) => void,
    style?: {},
    adaptPosition?: boolean
}
export function DistSelect({ open, setOpen, handleSelect, style, adaptPosition }: IProps) {
    const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 });
    const [params, setParams] = useState({ page: 1, pageSize: 300, search: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [entries, setEntries] = useState<PaginationProps<IDist>>({} as PaginationProps<IDist>);
    const [className, setClass] = useState('');
    const selectRef = useRef<HTMLDivElement>(null);

    const fetchData = async () => {
        await api.get(`${baseURL}/v1/delivery/transship-point`, {
            params,
        }).then((response) => {
            setEntries(response);
        });
    };

    const handlePick = (element: any) => {
        handleSelect(element);
        setOpen(false);
    };

    function onChangeParams(params: any) {
        setParams((state: any) => ({ ...state, ...params }));
    }

    useEffect(() => {
        if (selectRef.current && open) {
            const rect = selectRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const isOverflowing = rect.bottom > viewportHeight - 150;

            if (isOverflowing) {
                setClass('above');
            } else {
                setClass('below');
            }
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            fetchData();
        } else {
            setClass("")
        }
    }, [params, open]);

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpen(false);
    };

    if (!open) return null;

    return (
        <>
            <SelectBack className="popupBack" onClick={handleClose} />
            <SelectContainer className={adaptPosition ? className : "default"} style={style} ref={selectRef}>
                <div className="header">
                    <div className="input">
                        <img className="searchIcon" src={SearchIcon} alt="" />
                        <Input
                            type="text"
                            className="inputBox"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                debaunceFilter({ search: e.target.value });
                            }}
                            placeholder="Nome"
                        />
                    </div>
                </div>
                <div className="list">
                    {entries.results
                        ?.filter((item) => item?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map((element) => (
                            <div className="entry" key={element.id}>
                                <button
                                    type="button"
                                    className="entryName"
                                    key={element.id}
                                    onClick={() => handlePick(element)}
                                >
                                    {`${element.name}`}
                                </button>
                            </div>
                        ))}
                </div>
            </SelectContainer>
        </>
    );
}
