import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const DriverSelectBtnContainer = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    float: left;
    margin-right: 12px;
    margin-left: -4px;
    margin-top: -2px;

    svg {
        transform: all 100ms;
    }

    :hover {
        svg {
            fill: ${defaultTheme.colors['base']};
        }
    }

    @media screen and (max-width:769px) {
        svg {
            transform: scale(75%);
        }
    }
`

export const SensitiveBtnContainer = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    gap: 6px;

    span {
        transition: all 100ms;
    }

    :hover {
        span {
            color: ${defaultTheme.colors["base"]};
            font-weight: bolder;
            font-size: 0.90rem;
        }
    }

    .sensitiveText {
        width: 48px;
    }
    img, svg {
        fill: none;
        background-color: transparent;
    }
`

export const ObsBtnContainer = styled.button`
    border: none;
    background-color: transparent;
`