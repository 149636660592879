import styled from 'styled-components'

export const FilterSelectContainer = styled.div`
    position: absolute;
    top: 60%;
    left: 102.4%;
    width: 273px;
    display: flex;
    align-items: center;
    height: 120px;
    z-index: 200;
    
    cursor: default;
    
    /* Cinza/Branco-100 */
    background: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));


    .bullet {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        border: 1px solid #757575;
        margin-right: 10px;
    }

    .checked {
        display: inline-block;
        background-color:#89BD23;
    }
    
    .list {
        width: 100%;
        height: 80%;
        margin-top: 20px;

        display: flex;
        flex-direction: column;

        overflow-y: auto;
    }

    .entry {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        height: 40px;
        padding: 12px 25px;

        :hover {
            background-color: #F0F0F0;
        }
    }

    .entryName {
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-align: left;
        background: none;
        border: none;
        width: 100%;
        color: black;
    }

    @media screen and (max-width:769px){
        width: 285px;
        top: 110%;
        left: 0;
        .header {
            margin-bottom: 0;
            margin-top: 0;
        }
    
        ::before {
            visibility: hidden;
        }
    }

`

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
`