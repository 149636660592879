import { CircularProgress } from "@mui/material";
import { Container } from "./styles";
import ArrowRoutes from '../../assets/arrowRoutes.svg'
import { useEffect, useState } from "react";
import RoutesOkIcon from '../../assets/routesOk.svg'
import { IRoute } from "interfaces/IWaybill";
import { SelectContainerRoutes } from "./Select";

interface Props {
  data: IRoute[]
}

export function AssignedRoutes({ data }: Props) {
  const [progressClass, setProgressClass] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const getTotalRoutes = (data: IRoute[]): number => {
    const uniqueRoutes = new Set();
    
    data.forEach(({ route }) => {
      if (route) {
        uniqueRoutes.add(route);
      }
    });
  
    return uniqueRoutes.size;
  };
  
  const getTotalRoutesWithDriver = (data: IRoute[]): number => {
    const uniqueRoutes = new Set();
    
    data.forEach(({ vehicle, route }) => {
      if (vehicle?.driver !== null && route && !uniqueRoutes.has(route)) {
        uniqueRoutes.add(route);
      }
    });
    
    return uniqueRoutes.size;
  };

  const getTotalDriverWithRoute = getTotalRoutesWithDriver(data)
  const totalRoutes = getTotalRoutes(data) 
  const percentageAssigned = (getTotalDriverWithRoute / totalRoutes) * 100

  const handleArrowClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setProgressClass(percentageAssigned === 100 ? "success" : "danger");
  }, [percentageAssigned])

  return (
    <Container className={isOpen ? "open" : ""}>
      <CircularProgress
        variant="determinate"
        value={percentageAssigned}
        className={`progressBar ${progressClass}`}
        size={50}
        thickness={7}
      />
      <div>
        <p className="title">Rotas atribuídas</p>
        <p className="countRoutes">{getTotalDriverWithRoute} de {totalRoutes}</p>
      </div>
      <img
        src={ArrowRoutes}
        alt="arrow"
        onClick={handleArrowClick}
        className={isOpen ? "arrowDown" : ""}  
      />
      {percentageAssigned === 100 && <img src={RoutesOkIcon} alt="Ok" className="okIcon"/>}
      <SelectContainerRoutes entries={data} open={isOpen} setOpen={setIsOpen}/>
    </Container>
  )
}