import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  position: relative;
  > p {
    color: ${({ theme }) => theme.colors["danger"]};
  }
`

export const InputStyleContainer = styled.input`
  width: 372px;
  height: 48px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors["base-input"]};
  overflow: hidden;
  border: none;

  color: ${({ theme }) => theme.colors["base-text"]};
  font-size: 1rem;
  padding: 0 0.75rem 0 44px;
  &::placeholder {
    color: ${({ theme }) => theme.colors["base-label"]};
  }

  @media screen and (max-width: 769px){
    height: 45px;
    font-size: 0.9rem;
  }
`
