import { CardContainer } from "./styles";

interface IProps {
    children?: React.ReactNode,
    stripColor?: string,
    stripWidth?: string,
    type?: "driverCard"|"teamCard"|"table",

}

export function DataCard({ children, type, stripColor, stripWidth }: IProps) {
    const stripStyles = {
        backgroundColor: stripColor || "transparent",
        width: stripWidth || "8px"
    }

    return (
        <CardContainer className={type || "driverCard"}>
            <div className="cardStrip" style={stripStyles}></div>
            <div className="mainCard">
                {children}
            </div>
        </CardContainer>
    );
}