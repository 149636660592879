import { dateToday } from 'utils/dateToday';

export function verifyDate() {
  const now = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
  const hour = new Date(now).getHours();

  if (hour >= 17 && hour <= 24) {
    return dateToday(1);
  } else {
    return dateToday();
  }
}
