import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const DepotLineContainer = styled.div`
    width: 100%;
    height: 40px;
    background-color: ${defaultTheme.colors['white-100']};
    display: flex;
    align-items: center;
    gap: 2px;

    input {
        height: 36px;
        padding: 12px;
        border: 0.5px solid #C4C4C4;

        &.updated {
            color: ${defaultTheme.colors['transit']};
            font-weight: bolder;
        }

        :disabled {
            border: none;
            background-color: transparent;
        }

        ::placeholder {
            color: ${defaultTheme.colors['gray-100']}
        }
    }

    .small {
        width: 15%;
        text-align: center;
    }

    .medium {
        width: 30%;
    }

    .large {
        width: 40%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

`