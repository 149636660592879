import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const IconContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100px;
    margin-bottom: -50px;

    .MuiSvgIcon-root {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 50%;
        width: 112px;
        height: 112px;
        background: white;
        border-radius: 50%;

        &.success {
            fill: ${defaultTheme.colors['success']};
        }
        &.info {
            fill: ${defaultTheme.colors['logo-yellow']};
        }
        &.error {
            fill: ${defaultTheme.colors['danger']};
        }
    }
`

export const InfoContainer = styled.div`
    position: relative;
`