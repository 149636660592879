import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

interface CheckboxLabelProps {
  justifyContent?: string;
}

export const CheckboxLabel = styled.div<CheckboxLabelProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  font-size: 14px;

  .MuiCheckbox-root {
    &.Mui-checked {
      color: ${defaultTheme.colors["base"]};
    }
    &.Mui-disabled {
      color: ${defaultTheme.colors["gray-100"]};
    }
  }

  .MuiCheckbox-root.Mui-disabled {
    &:hover {
      background-color: transparent;
    }
    .MuiSvgIcon-root {
      color: ${defaultTheme.colors["gray-100"]};
    }
  }
  .MuiCheckbox-root.Mui-disabled.MuiCheckbox-indeterminate {
    color: ${defaultTheme.colors["gray-200"]};
  }
`;
