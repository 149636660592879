import styled from 'styled-components'
import { defaultTheme } from 'styles/theme/default'

export const RouteSelectContainer = styled.form`
    width: 350px;
    height: 300px;
    z-index: 200;

    cursor: default;

    .header {
        padding: 0 20px 20px 20px;
        width: 100%;
        margin-left: 10px;
    }

    .input {
        position: relative;
        height: 40px;
        width: 100%;
    }

    .inputBox {
        width: 100%;
        border-radius: 37px;
        height: 40px;
    }

    .input input{
        padding-left: 12%;
        height: 40px;
        color: black;
        border: none;
        font-size: 0.9rem;
        background-color: ${defaultTheme.colors['base-input']};
        ::placeholder {
            color: ${defaultTheme.colors['gray-200']};
        }
    }

    .input img {
        position: absolute;
        z-index: 1;
        left: 12px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        cursor: default;
        pointer-events: none;
    }

    input[type="checkbox"]:checked {
        background-color: #04D361;
    }

    .list {
        width: 100%;
        height: 75%;
        align-items: center;

        display: flex;
        flex-direction: column;

        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 4px;
            border: 2px solid transparent;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    .entry {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 90%;
        height: 40px;
        padding: 12px 0 12px 12px;
        margin-left: 25px;

        :hover {
            background-color: ${defaultTheme.colors['base-light']};
        }
    }

    .entryName {
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-align: left;
        background: none;
        border: none;
        width: 100%;
        color: black;
    }

    @media screen and (max-width:769px){
        top: 110%;
        left: 50%;
        width: 100%;

        .input input {
          padding-left: 16%;
        }

        .list {

        }

        .entry {
          margin-left: 0;
        }

        .header {
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 0;
        }

        ::before {
            visibility: hidden;
        }
    }

`

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
`

