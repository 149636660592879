import { IDelivery } from "interfaces/IDeliveryAttempt";
import { useState } from "react"
import {ReactComponent as DeliveryBoxIcon} from "assets/deliveryBoxIcon.svg"

interface IProps {
    order: IDelivery;
    setHover: React.Dispatch<React.SetStateAction<boolean>>;
    setData: React.Dispatch<React.SetStateAction<IDelivery>>;
}

export function DeliveryItem({order, setHover, setData}: IProps) {
    const [isDone, setDone] = useState(order.deliveryAttempt[order.deliveryAttempt?.length - 1]?.successfulDelivery)

    const color = isDone !== undefined ? (isDone === true ? "green" : "red") : "blue"
    return (
        <div className="deliveryOrder"
            onMouseEnter={() => {
                setData(order)
                setHover(true)
            }}
            onMouseLeave={() => {
                setHover(false)
            }}>
            
            <DeliveryBoxIcon className={`deliveryBox ${color}`} />
            <div className="orderNumberContainer">
                <span className="orderNumber">{order.attendanceOrder}</span>
            </div>
        </div>
    )
}