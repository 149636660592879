import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-bottom: 24px;

  .statusDelivery {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 103px;
    height: 32px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
    font-size: 14px;
    border: none;
  }

  .active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .statusBall {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #EB001B;
  }
  
  .statusBallBlue {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #3887FF;
  }

  .statusBallGreen {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: #04D361;
  }
`