import { format } from 'date-fns';

export const dateFormat = (dataString: string | null) => {
  if (dataString === null) {
    return 0;
  }

  const dateParts = dataString.split("-");

  if (dateParts.length !== 3) {
    return 0;
  }

  const [year, month, day] = dateParts;
  const formattedDate = format(new Date(`${month}/${day}/${year}`), 'dd/MM/yyyy');

  return formattedDate;
}
