import { ILogin } from "interfaces/ILogin";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { api } from "services/api";
import { authenticationService } from "services/authenticationService";
import { baseURL } from "settings";

interface IAuthContext {
    username: string,
    setUsername: (name: string) => void
    handleSignIn: (x: ILogin) => Promise<void>
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export function AuthProvider({ children }: any) {
  const navigate = useNavigate()
  const [username, setUsername] = useState(() => localStorage.getItem("username") || "")

  const getUserData = async (token: string) => {
    await api.get(`${baseURL}/me/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      setUsername(response.userLogin)
      localStorage.setItem("username", response.userLogin)
    })
    .catch(() => {
      toast.error("Houve um erro ao buscar o usuário no sistema")
    })
  }

  const handleSignIn = async (data: ILogin) => {
    try {
      const { access } = await authenticationService.postLogin(data)
      localStorage.setItem("bearerToken", access)
      getUserData(localStorage.getItem('bearerToken') || "")
      api.instance.defaults.headers["Authorization"] = `Bearer ${access}`
      navigate('/home')
      toast.success("Login realizado com sucesso!")
    } catch (e: any) {
      toast.error(e?.message ?? "Este usuário não existe no sistema")
    }
  }

  return (
    <AuthContext.Provider value={{ username, setUsername, handleSignIn }}>
      {children}
    </AuthContext.Provider>
  )
}



export const useAuth = () => useContext(AuthContext)
