import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";


export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .mainCard {
    width: 80.8vw;
    height: 47vh;
    background-color: ${defaultTheme.colors['base-light']};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    margin-top: 15vh;
    margin-left: 138px;
  }

  .textArea {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    width: 59vw;
    height: 100%;
    padding-left: 7vw;
    padding-right: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .textArea h1 {
    font-size: 3rem;
    margin-bottom: 32px;
  }

  .textArea p {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 50px;
  }

  .signupContainer a {
    width: fit-content;
    display: flex;
    align-items: center;
  }

  .signupContainer .signupText {
    width: fit-content;
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: 800;
    color: ${defaultTheme.colors['base-dark']};
  }

  .signupContainer .addIcon {
    height: 100%;
    margin-right: 12px;
    color: ${defaultTheme.colors['base-dark']};
  }

  .imgArea {
    width: 50%;
    height: 100%;
  }

  .imgArea img {
    width: 610px;
    margin-top: -8vh;
    margin-right: 28px;
  }

  .menuContainer {
    position: relative;
    width: 70.5vw;
    height: 18vh;
    margin-top: 56px;
    margin-bottom: 0.6vh;
    margin-left: 14.7vw;
  }

  .menuContainer .sectionTitle {
    left: 0;
    top: 0;
    position: sticky;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
  }

  .menuCards {
    display: flex;
    width: fit-content;
    padding: 1vw 0.5vw 1vw 0.5vw;
    margin-top: 10px;
    /* overflow-x: scroll; */
    /* overflow-y: auto; */
  }

  .menuCards:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 1280px){
    .mainCard img {
      margin-left: -8vw;
      /* margin-top: 8; */
      width: 51vw;

    }
  }

  @media screen and (max-width: 769px){
    overflow-y: scroll;

    .mainCard {
      width: 100vw;
      height: 56vh;
      margin: 64px 0 0 0;
      border-radius: 0;
    }
    .textArea {
      h1{
        font-size: 2.5rem;
      }
      p {
        font-size: 1.4rem;
      }
      width: 82vw;
      padding-left: 3vw;
      text-align: justify;
    }
    .imgArea {
      display: none;
    }

    .signupContainer .signupText{
      font-size: 1.4rem;
    }

    .menuContainer {
      position: relative;
      margin-left: 0;
      align-self: center;
      width: 87vw;
    }

    .menuContainer .sectionTitle {
      position: sticky;
      z-index: 10;
      height: 48px;
      top: 0;
      background-color: ${defaultTheme.colors['white-300']};
    }

    .menuCards {
      width: 100%;
      margin-top: 20px;
      padding-left: 5px;
      padding-right: 5px;
      flex-direction: column;
    }

    .menuCards .cardItem {
      width: 100%;
      height: 96px;
      margin-bottom: 1rem;
      line-height: 150%;
    }

  }

  @media screen and (max-height:1024px){
    overflow-y: auto;
    .mainCard {
      height: 57vh;
    }

    .imgArea img {
      width: 430px;
    }

    .menuCards {
      width: 100%;
      margin-top: 20px;
      padding-left: 5px;
      padding-right: 5px;
      flex-direction: column;
    }

    .menuCards .cardItem {
      width: 100%;
      height: 96px;
      margin-bottom: 1rem;
      line-height: 150%;
    }
  }
`

export const SidebarContainer = styled.div`
  width: 72px;
`
