import { ReactNode } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { AuthProvider } from "./AuthContext";


export const AppProvider = ({children}: {children: ReactNode}) => (
    <AuthProvider>
        <ProSidebarProvider>
        {children}
        </ProSidebarProvider>
    </AuthProvider>
)