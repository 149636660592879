import SearchIcon from 'assets/searchIcon.svg'
import { DriverSelectContainer, DriverTeamColorTag, SelectBack } from "./styles";
import { CSSProperties, useEffect, useState } from "react";
import { IDriver } from "interfaces/IDriver";
import { PaginationProps } from 'interfaces/IPagination';
import { Input } from 'components/Input';
import { toast } from 'react-toastify';
import { driverVehicleService } from 'services/driverVehicleService';
import { LinearProgress } from '@mui/material';

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  handleSelect: (x: any) => void,
  unassigned?: boolean,
  tailPosition: 'top' | 'left',
  style?: CSSProperties,
}

export function DriverSelect({ open, setOpen, handleSelect, unassigned, tailPosition, style }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState<PaginationProps<IDriver>>({} as PaginationProps<IDriver>)
  const [loading, setLoading] = useState(false)
  const params = { page: 0, unassigned: unassigned ? 1 : 0 }

  const fetchData = () => {
    setLoading(true)
    driverVehicleService.getDrivers(params)
      .then((response) => {
        setEntries(response);
      }).catch(() => {
        toast.error("Não foi possível buscar os motoristas no banco de dados")
      }).finally(() => {
        setLoading(false)
      })
  }

  const handlePick = (element: any) => {
    handleSelect(element)
    setOpen(false)
  }

  const filteredNames = entries.results?.filter((element) => element.name.startsWith(searchTerm))

  useEffect(() => {
    fetchData()
  }, [open])

  if (!open) return null

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }} />
      <DriverSelectContainer style={style} tailPosition={tailPosition}>
        <div className="header">
          <div className="input">
            <img className="searchIcon" src={SearchIcon} alt="" />
            <Input
              type="text"
              className="inputBox"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Nome"
            />
          </div>
        </div>
        <div className="list">
        {loading && <LinearProgress variant="indeterminate" color='success'/>}
          {entries.results && filteredNames
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((element, index) => (
              <div className="entry" key={index}>
                <DriverTeamColorTag barColor={element.team?.colorTeam || "transparent"} />
                <button type="button" className="entryName" onClick={() => handlePick(element)}>{`${element.name}`}</button>
              </div>
            ))
          }
        </div>
      </DriverSelectContainer>
    </>
  )
}
