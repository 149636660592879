import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const LogoWrapper = styled.div`
    display: flex;
    align-content: center;
    .logoName {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        text-decoration: none;
        color: ${defaultTheme.colors['logo-yellow']};
        display: flex;
        align-items: center;
    }

    .logoImg {
        margin: 8px;
        width: 35px;
        height: 35px;
        fill: ${defaultTheme.colors['logo-yellow']};
    }

    @media screen and (max-width: 760px){
        .logoImg{
            margin: 0 0 0 -4px;
        }
        .logoName{
            margin: 0 0 0 4px;
        }
    }

`