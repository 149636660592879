import styled from 'styled-components'
import { defaultTheme } from 'styles/theme/default'

interface ITeamStyle {
    barColor: string
}

export const DriverTeamColorTag = styled.div<ITeamStyle>`
    height: 100%;
    width: 8px;
    background-color: ${({ barColor }) => barColor};
`

interface IStyle {
    tailPosition: 'top'|'left',
}

export const DriverSelectContainer = styled.div<IStyle>`
    position: absolute;
    top: 22%;
    left: 36%;
    width: 273px;
    height: 312px;
    z-index: 200;

    cursor: default;

    /* Cinza/Branco-100 */
    background: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));

    ::before {
        visibility: visible;
        content: "";
        position: absolute;
        bottom: ${({ tailPosition }) => tailPosition === 'left' ? "85%" : "100%"};
        right: ${({ tailPosition }) => tailPosition === 'left' ? "100%" : "75%"};
        border-width: ${({ tailPosition }) => tailPosition === 'left' ? "10px 10px 10px 0" : "0 10px 10px 10px"};
        border-style: solid;
        border-color: ${({ tailPosition }) => tailPosition === 'left' ? "transparent #FFFFFF transparent transparent" : "transparent transparent #FFFFFF transparent"};
        transform: ${({ tailPosition }) => tailPosition === 'left' ? "translateY(-50%)" : "translateX(-50%)"};
    }

    .header {
        padding: 16px;
        width: 100%;
    }

    .input {
        position: relative;
        height: 40px;
        width: 100%;
    }

    .inputBox {
        width: 100%;
        border-radius: 37px;
        height: 40px;
    }

    .input input{
        padding-left: 15%;
        color: black;
        border: none;
        font-size: 0.9rem;
        background-color: ${defaultTheme.colors['base-input']};
        ::placeholder {
            color: ${defaultTheme.colors['gray-200']};
        }
    }

    .input img {
        position: absolute;
        z-index: 1;
        left: 12px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        cursor: default;
        pointer-events: none;
    }

    .list {
        width: 100%;
        height: 70%;

        display: flex;
        flex-direction: column;

        overflow-y: auto;
    }

    .entry {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 40px;

        :hover {
            background-color: ${defaultTheme.colors['base-light']};
        }
    }


    .entryName {
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-align: left;
        background: none;
        border: none;
        width: 100%;
        color: black;
        padding: 12px 16px;
    }

    @media screen and (max-width:769px){
      position: fixed;
      width: 80vw;
      height: 40vh;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
      z-index: 9999;
        .header {
            margin-bottom: 0;
            margin-top: 0;
        }

        ::before {
            visibility: hidden;
        }
    }

`

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;

    @media screen and (max-width:769px){
      background-color: rgba(0, 0, 0, 0.46); /* Cor escura com 50% de opacidade */
    }
`
