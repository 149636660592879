import { ICity } from 'interfaces/ICity'
import { api as apiService, ApiService } from './api'
import { agileProcessApi as processApiService, AgileProcessApi } from './agileProcessApi'
import { baseURL, baseRegionApi } from 'settings'
import { IDist } from 'interfaces/IDist'
import { IRegion } from 'interfaces/IRegion'

export type Depot = {
  externalId: string,
  name: string,
  initials: string
}

class PriorityService {
  constructor(
    private readonly api: ApiService,
    private readonly processApiService: AgileProcessApi,
    private readonly baseURL: string,
    private readonly baseRegionApi: string) { }

  public getDepots = async (): Promise<any> => {
    return await this.api.get(`${this.baseURL}/v1/delivery/distribution-center`)
  }

  public getCities = async (): Promise<any> => {
    const params = { pageSize: 0 }
    return await this.api.get(`${this.baseURL}/v1/delivery/delivery-city`, {params})
  }

  public getRegions = async (): Promise<any> => {
    return await this.processApiService.get(`${this.baseRegionApi}`)
  }

  public saveDepot = async (element: Depot): Promise<any> => {
    const params = {
      name: element.name,
      externalId: element.externalId,
      initials: element.initials
    }

    return await this.api.post(`${this.baseURL}/v1/delivery/distribution-center`, params)

  }

  public saveCity = async (cityName: string, state: string, depotId: number) => {
    const params = {
      name: cityName,
      state: state,
      depotId: depotId,
      priority: "Z"
    }

    return await this.api.post(`${this.baseURL}/v1/delivery/delivery-city`, params)
  }

  public updateRegions = async (params: IRegion[]): Promise<any> => {
    return await this.processApiService.put(`${this.baseRegionApi}/bulk`, params)
  }

  public updateDepots = async (depots: IDist[]): Promise<any> => {    
    return await this.api.put(`${this.baseURL}/v1/delivery/distribution-center/bulk`, depots)
  }

  public updatePriorities = async (cities: ICity[], notify?: boolean): Promise<any[]> => {    
    return await this.api.put(`${this.baseURL}/v1/delivery/delivery-city/bulk`, cities)
  }
}

export const priorityService = new PriorityService(apiService, processApiService, baseURL, baseRegionApi)
