import { IGroup } from 'interfaces/IGroup'

import { api as apiService, ApiService } from './api'

class PermissionsService {
  constructor(private readonly api: ApiService) {}

  async getPermissions(): Promise<IGroup[]> {
    const url = 'v1/delivery/user-manager/groups'
    return await this.api.get(url)
  }
}

const permissionsService = new PermissionsService(apiService)
export default permissionsService
