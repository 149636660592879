import { defaultTheme } from "styles/theme/default";
import { SelectContainer, SelectBack } from "../styles";
import { useEffect, useState } from "react";
import { ColorSelectContainer } from "./styles";

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    handleSelect: (x: any) => void,
}

const colors = {
    red: "#EB001B",
    orange: "#ff7b07",
    yellow: "#FFC107",
    olive: "#78bd23",
    green: "#04D361",
    teal: "#27bca3",
    blue: "#3887FF",
    purple: "#9838ff",
    pink: "#ff62d0"
}

export function ColorSelect({ open, setOpen, handleSelect }: IProps) {

    const handlePick = (color: string) => {
        handleSelect(color)
        setOpen(false)
    }

    useEffect(() => {
        
      }, [open]);

    if (!open) return null

    return (
        <>
            <SelectBack className="popupBack" onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }} />
            <ColorSelectContainer className='colorSelect'>
                <button type="button" id="red" className="colorBtn" style={{ backgroundColor: colors.red }} onClick={() => handlePick(colors.red)}/>
                <button type="button" id="orange" className="colorBtn" style={{ backgroundColor: colors.orange }} onClick={() => handlePick(colors.orange)}/>
                <button type="button" id="yellow" className="colorBtn" style={{ backgroundColor: colors.yellow }} onClick={() => handlePick(colors.yellow)}/>
                <button type="button" id="olive" className="colorBtn" style={{ backgroundColor: colors.olive }} onClick={() => handlePick(colors.olive)}/>
                <button type="button" id="green" className="colorBtn" style={{ backgroundColor: colors.green }} onClick={() => handlePick(colors.green)}/>
                <button type="button" id="teal" className="colorBtn" style={{ backgroundColor: colors.teal }} onClick={() => handlePick(colors.teal)}/>
                <button type="button" id="blue" className="colorBtn" style={{ backgroundColor: colors.blue }} onClick={() => handlePick(colors.blue)}/>
                <button type="button" id="purple" className="colorBtn" style={{ backgroundColor: colors.purple }} onClick={() => handlePick(colors.purple)}/>
                <button type="button" id="pink" className="colorBtn" style={{ backgroundColor: colors.pink }} onClick={() => handlePick(colors.pink)}/>
            </ColorSelectContainer>
        </>
    )
}
