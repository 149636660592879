import { Box, styled } from "@mui/material";
import { ButtonFilter } from "components/ButtonFilter";
import { defaultTheme } from "styles/theme/default";

export const ContainerButtonFilter = styled(Box)(({ theme }) => ({
  border: "none",
  height: "32px",
  position: "relative",
  width: "84px",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  transition: "all 100ms",
  "&:hover": {
    backgroundColor: defaultTheme.colors["white-box"],
  },
  [theme.breakpoints.down(769)]: {
    width: "64px",
    height: "48px",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const Button = styled(ButtonFilter)(({ theme }) => ({
  width: "134px",
  height: "40px",
  background: "transparent",
  color: defaultTheme.colors["base"],
  [theme.breakpoints.down(769)]: {
    fontSize: "1rem",
    paddingLeft: "0",
    width: "auto",
  },
}));

export const Image = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "10px",
  left: "12px",
  zIndex: 1,
  cursor: "pointer",
  [theme.breakpoints.down(769)]: {
    position: "relative",
    left: 0,
    right: 0,
    margin: "auto",
    marginBottom: "4px",
    width: 'auto',
  },
}));
