import { Checkbox } from '@mui/material';
import styled from 'styled-components'
import { defaultTheme } from 'styles/theme/default'

export const SelectContainer = styled.div`
    position: absolute;
    width: 273px;
    height: 312px;
    left: -20px;
    top: 175%;
    z-index: 200;

    cursor: default;
    
    /* Cinza/Branco-100 */
    background: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));
    
    ::before {
        visibility: visible;
        content: "";
        position: absolute;
        left: 50%;
        bottom: 100%;
        border-width: 0px 10px 10px 10px;
        border-style: solid;
        border-color: transparent transparent #FFFFFF transparent;
        transform: translateX(-50%);
    }
    
    .header {
        padding: 16px;
        width: 100%;
    }

    .input {
        position: relative;
        height: 40px;
        width: 100%;
    }

    .inputBox {
        width: 100%;
        border-radius: 37px;
        height: 40px;
    }
    
    .input input{
        padding-left: 15%;
        color: black;
        border: none;
        font-size: 0.9rem;
        background-color: ${defaultTheme.colors['base-input']};
        ::placeholder {
            color: ${defaultTheme.colors['gray-200']};
        }
    }

    .input img {
        position: absolute;
        z-index: 1;
        left: 12px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        cursor: default;
        pointer-events: none;
    }

    .list {
        width: 100%;
        height: 70%;

        display: flex;
        flex-direction: column;

        overflow-y: auto;
    }

    .entry {
        height: 40px;

        :hover {
            background-color: ${defaultTheme.colors['base-light']};
        }
    }


    .entryName {
        display: flex;
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-align: left;
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        padding: 12px 25px;
        color: black;

        justify-content: space-between;
    }

    .entryInfo {
        color: ${defaultTheme.colors['gray-200']}
    }

    /* TEAM POPUP */

    &.driverList {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 352px;
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05));
        z-index: 100;

        ::before {
            display: none;
        }

        .input input {
            padding-left: 8%;
        }

        .entry {
            display: flex;
            align-items: center;
        }

        .entryName {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            /* or 22px */

            display: flex;
            align-items: center;
            width: 60%;

            /* Cinza/Cinza-300 */

            color: ${defaultTheme.colors['gray-300']};
            justify-content: left;


            .driverName {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: normal;
                white-space: nowrap;
            }
       }

       .entryInfo {
            display: flex;
            width: 40%;
            justify-content: flex-end;
       }

        .driverListCheck {
            margin-right: 14px;
        }

        .leaderCheck {
            margin-right: 14px;

            :disabled {
                background-color: black;
            }
        }

        .otherName {
            left: 0;
            margin-right: 12px;
            text-align: right;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    
    @media screen and (max-width:769px){
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 10%;
        margin: auto;
        .header {
            margin-bottom: 0;
            margin-top: 0;
        }
    
        ::before {
            visibility: hidden;
        }
    }

`

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
`