import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const ProductListContainer = styled.div`
    width: 700px;
    height: 487px;
    background-color: ${defaultTheme.colors['white-100']};
    box-shadow: 0px 0px 8px 0px #0000001F;
    border-radius: 16px;
    padding: 16px;
    

    .header {
        height: fit-content;
        margin-bottom: 28px;
    }

    .searchBar {
        position: relative;
        width: 100%;
        height: 40px;

        .searchIcon {
            position: absolute;
            pointer-events: none;
            left: 16px;
            top: 0;
            bottom: 1px;
            margin: auto;
        }
    
        .searchInput {
            border: none;
            width: 100%;
            height: 40px;
            border-radius: 37px;
            font-size: 0.9rem;
            padding-left: 40px;
            background-color: ${defaultTheme.colors['base-input']};

            ::placeholder {
                color: ${defaultTheme.colors['gray-300']}
            }
        }

    }

    .listDesc {
        font-family: 'Inter' sans-serif;
        font-size: 1rem;
        font-weight: 500;
        margin-left: 12px;
        color: ${defaultTheme.colors['gray-300']};
        user-select: none;
    }

    > .list {
        margin-top: 16px;
        height: 358px;
        overflow-y: auto;
        border-radius: 12px;

        .productEntry:nth-of-type(2n) {
            background-color: ${defaultTheme.colors['white-table']};
        }
    }

    @media screen and (max-width:769px){
        width: 100%;
        height: 100%;
        margin: 12px;
    }
`