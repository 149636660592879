import { api } from "services/api";
import { baseURL } from "settings";
import { RouteSelectContainer } from "./styles";
import { PaginationProps } from "interfaces/IPagination";
import { IWaybill } from "interfaces/IWaybill";
import { useEffect, useRef, useState } from "react";
import { defaultTheme } from "styles/theme/default";

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    value: string | number,
    filter: boolean,
    date: string,
    handleSelect: (x: any) => void
}

export function RouteSelect({ open, setOpen, date, value, filter, handleSelect }: IProps) {
    const [routes, setRoutes] = useState<PaginationProps<IWaybill>>({} as PaginationProps<IWaybill>)
    const [params, setParams] = useState({ page: 1, pageLimit: 300, search: '' })
    const entries = filter ? routes.results?.filter((a: IWaybill) => (a.route || "").toString().includes(value.toString()))
        : routes.results
    const [selectClass, setClass] = useState("")
    const routeSelectRef = useRef<HTMLDivElement>(null)

    const fetchData = async () => {
        await api.get(`${baseURL}/v1/delivery/waybill/line-up`, {
            params
        })
            .then((response) => {
                setRoutes(response)
            })
    };

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        document.body.classList.toggle('modal-open', open)

        return () => {
          document.body.classList.remove('modal-open')
        };
      }, [open]);

    useEffect(() => {
        if (routeSelectRef.current && open) {
            const rect = routeSelectRef.current.getBoundingClientRect()
            const viewportHeight = window.innerHeight;
            const isOverflowing = rect.bottom > viewportHeight - 200
            if (isOverflowing) {
                setClass("above")
            } else {
                setClass("below")
            }
        }
    }, [open])

    return (
        <RouteSelectContainer ref={routeSelectRef} className={`routeInputDropdown ${selectClass}`}
            onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}>
                <div className="header">
                    <span className="route">Rota</span>
                    <span className="driver">Motorista</span>
                    <span className="boxes">Caixas</span>
                </div>
            {entries?.sort((a, b) => a.route - b.route).filter((w) => w.route !== null)
                .map((element) => (
                    <button key={element.id} className="routeEntry" onClick={() => handleSelect(element)}>
                        <div className="teamTag" style={{ backgroundColor: element.driver?.team?.colorTeam }}/>
                        <span className="route">{element.route}</span>
                        <span className="divider">—</span>
                        <span className="team" style={{ color: element.driver ? "black" : defaultTheme.colors['danger'] }}>{element.driver?.name || "Não atribuído"}</span>
                        <span className="boxes">{element.boxesPlanned || 0} </span>
                    </button>
                ))}
        </RouteSelectContainer>
    )
}
