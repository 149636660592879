import { IBoxes } from "interfaces/IBoxes"
import { useState } from "react"

interface IProps {
    data: IBoxes
    isCount?: boolean,
    isBalance?: boolean,
    handleSave: (x: any, y?: boolean, z?: boolean) => void
}

export function ObservationForm({data, handleSave, isCount, isBalance}: IProps) {
    const [comment, setComment] = useState(isCount ? data.countComment : isBalance ? data.balanceComment : data.returnComment)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSave(comment)
    }

    return (
        <>
            <form id="popupForm" autoComplete="false" onSubmit={(e) => handleSubmit(e)}>
                <div className="col1">
                    <div className="section">
                        <div className="form">
                            <textarea
                            className="obsInput"
                            name="observation"
                            id="obsArea"
                            placeholder="Digite uma observação."
                            value={comment}
                            onChange={e => {
                                setComment(e.target.value)
                            }}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
