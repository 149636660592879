import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

export const StyledPageLoader = styled.div <{isPopup: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: ${props => props.isPopup ? '32vh' : '55vh'};
  .rotate-image {
    width: 3.5rem;
    animation: ${spin} 2s linear infinite;
  }

`;

