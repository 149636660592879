import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const ItemTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 516px;
    height: 100%;
    max-height: 536px;
    border-radius: 12px;
    background-color: ${defaultTheme.colors['white-table']};
    font-family: 'Inter', Arial, Helvetica, sans-serif;
        font-size: 1rem;

    .itemTable {
        width: 100%;
        height: 100%;
    }
    .itemTableHead {
        text-align: left;
        width: 100%;
        height: 20%;
        display: flex;
        user-select: none;
        padding: 16px 27px;

        color: ${defaultTheme.colors['gray-300']};
        
        height: 48px;
        /* background-color: ${defaultTheme.colors['white-table']}; */

        .productHeader {
            width: 60%;
        }

        .quantHeader {
            width: 25%;
        }
    }

    .itemTableBody {
        padding: 16px 27px;

        height: 60%;
        overflow-y: auto;
    }

    .itemRow {
        display: flex;
        min-height: 36px;
        font-size: 1rem;

        .itemName {
            width: 61%;
        }

        .itemQuant {
            width: 25%;
        }
    }

    .itemTableFooter {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        gap: 8px;
        color: ${defaultTheme.colors['gray-300']};
        /* background-color: ${defaultTheme.colors['white-table']}; */
        box-shadow: 0px -7px 11px -1px rgba(0, 0, 0, 0.05);
        padding-top: 16px;

        div {
            display: flex;
            justify-content: space-between;
            line-height: 140%;
        }

        .priceValue {
            width: 15%;
        }
    }

    .horLine {
        width: 100%;
        height: 1px;
        margin-bottom: 16px;
        background-color: ${defaultTheme.colors['gray-100']};
    }

    @media screen and (max-width: 769px){
        max-height: 100%;
        padding-bottom: 24px;

        .itemTableBody {
            height: 200px;
        }
    }
`