import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Backdrop, CostFilterContainer } from './styles';

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParams: (x: any) => void
}

const minDistance = 10

export function CostSelect({ open, setOpen, onChangeParams }: IProps) {
  const [value1, setValue1] = React.useState<number[]>([300, 700])
  const [minValue, setMinValue] = React.useState<number>(value1[0])
  const [maxValue, setMaxValue] = React.useState<number>(value1[1])

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]])
      setMinValue(Math.min(newValue[0], value1[1] - minDistance))
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)])
      setMaxValue(Math.max(newValue[1], value1[0] + minDistance))
    }
  }

  const fetchFilterCost = async () => {
    onChangeParams({ "minCost": minValue, "maxCost": maxValue })
  }

  if (!open) return null

  return (
    <>
      <Backdrop onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }} />
      <CostFilterContainer>
          <div className="header">
            <span>R$ {minValue}</span>
            <span>R$ {maxValue}</span>
          </div>

          <Slider
            getAriaLabel={() => 'Minimum distance'}
            value={value1}
            onChange={handleChange1}
            disableSwap
            min={150}
            max={1200}
            style={{
              color: '#89BD23',
              marginTop: '10px'
            }}
          />
          <button onClick={fetchFilterCost}>Aplicar Filtro</button>
      </CostFilterContainer>
    </>
  );
}