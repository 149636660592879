import { baseURL } from 'settings';

import { api as apiService, ApiService } from './api'
import { IClientScore } from 'interfaces/IClientScore';

class ClientService {
  constructor(private readonly api: ApiService, private readonly baseURL: string) {}

  public updateClient = (data: IClientScore) => {
    return this.api.patch(`${this.baseURL}/v1/delivery/client-score/${data.clientId}`, {comment: data.comment})
  }
}

export const clientService = new ClientService(apiService, baseURL)
