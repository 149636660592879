import { IDelivery } from "interfaces/IDeliveryAttempt";
import { DeliveryListContainer, HorizontalScroll } from "./styles";
import { DeliveryItem } from "./DeliveryItem";
import { useEffect, useRef, useState } from "react";
import { DeliveryHover } from "./DeliveryHover";

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

interface IProps {
    orders: IDelivery[]
}

export function DeliveryList({ orders }: IProps) {
    const [hover, setHover] = useState(false)
    const [data, setData] = useState<IDelivery>({} as IDelivery)
    const childRef = useRef<HTMLDivElement>(null)
    const [scrollLeft, setScrollLeft] = useState(0)
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(false)

    useEffect(() => {
        const ref = childRef.current
        if (ref) {
            const handleScroll = () => {
                setScrollLeft(ref.scrollLeft)
                setCanScrollLeft(ref.scrollLeft > 0)
                setCanScrollRight(ref.scrollLeft < ref.scrollWidth - ref.clientWidth - 2)
            }

            setCanScrollLeft(ref.scrollLeft > 0)
            setCanScrollRight(ref.scrollLeft < ref.scrollWidth - ref.clientWidth - 2)

            ref.addEventListener('scroll', handleScroll)

            return () => {
                ref.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    const handleScrollLeft = () => {
        const ref = childRef.current
        if (ref) {
            ref.scrollBy(-96, 0)
        }
    }

    const handleScrollRight = () => {
        const ref = childRef.current
        if (ref) {
            ref.scrollBy(96, 0)
        }
    }

    return (
        <DeliveryListContainer>
            <button className="scrollBtn left" onMouseDown={handleScrollLeft} disabled={!canScrollLeft}>
                <ArrowBackIosNewRoundedIcon />
            </button>
            <div className="scrollContainer" ref={childRef}>
                <HorizontalScroll>
                    {orders.sort((a, b) => a.attendanceOrder - b.attendanceOrder)
                        .map((order) => (
                            <DeliveryItem key={order.orderId} order={order} setHover={setHover} setData={setData} />
                        ))}
                </HorizontalScroll>
            </div>
            <button className="scrollBtn right"
                onMouseDown={handleScrollRight} disabled={!canScrollRight}>
                <ArrowForwardIosRoundedIcon />
            </button>
            {hover && <DeliveryHover data={data} />}
        </DeliveryListContainer>
    )
}