import { IProductConfig, SensitiveMap } from "interfaces/ISensitiveItems"
import { PopupBack, ProductSearchPopupContainer } from "./styles"
import { ProductSearchEntry } from "../SearchEntry"

interface IProps {
    data: IProductConfig[],
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    sensitiveMap: SensitiveMap
    handleUpdate: (x: IProductConfig, y: boolean) => void
}

export function ProductSearchPopup({data, setOpen, sensitiveMap, handleUpdate}: IProps) {

    return (
        <>
        <PopupBack onClick={(e) => {
            e.stopPropagation()
            setOpen(false)
        }}/>
        <ProductSearchPopupContainer>
            <div className="list">
                {data.map(entry => (
                    <ProductSearchEntry data={entry} isSensitive={sensitiveMap[entry.id]} key={entry.sku} handleUpdate={handleUpdate}/>
                ))}
            </div>
        </ProductSearchPopupContainer>
        </>
    )
}