import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import { Link } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import IconBoxReturn from '../../assets/IconBoxReturn.svg'
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import IconPunctuality from '../../assets/iconPunctuality.svg'
import RouteIcon from '../../assets/routeIcon.svg'
import ScoreIcon from '../../assets/scoreIcon.svg'
import { PiExportFill } from "react-icons/pi";
import { SidebarWrapper } from "./styles";
import { useMediaQuery } from "react-responsive";
import { useEffect, useRef } from "react";
import { useLogout } from "hooks/useLogout";
import { toast } from "react-toastify";
import SupportIcon from '@mui/icons-material/Support';

// Envolver a sidebar em uma tag ProSidebarProvider.
function SidebarComponent() {
  const { collapseSidebar, collapsed } = useProSidebar();
  const sidebarRef = useRef<HTMLDivElement>(null)
  const isTablet = useMediaQuery({ maxWidth: 820 })
  const isDesktop = useMediaQuery({ minWidth: 820 })

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !collapsed && isTablet) {
        collapseSidebar(true)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef, collapsed, isTablet]);

  const handleClick = () => {
    toast.warning("Esta página está em desenvolvimento!");
  };

  return (
    <SidebarWrapper
      ref={sidebarRef}
      onMouseEnter={() => { if (isDesktop) collapseSidebar(false) }}
      onMouseLeave={() => { if (isDesktop) collapseSidebar(true) }}>
      <Sidebar
        className="sidebarContainer"
        defaultCollapsed={true}
        collapsedWidth={isTablet ? '0px' : '80px'}
      >
        <Menu className="menu">
          <MenuItem id="homeButton" className="menuItem" component={<Link to='/home' />} icon={<HomeIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Home</b>
          </MenuItem>
          <MenuItem id="deliveryButton" className="menuItem" component={<Link to='/deliveries' />} icon={<LocalPostOfficeIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Entregas</b>
          </MenuItem>
          <MenuItem id="driverButton" className="menuItem" component={<Link to='/drivers' />} icon={<LocalShippingIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Motoristas e Times</b>
          </MenuItem>
          <MenuItem id="driverButton" className="menuItem" component={<Link to='/support-drivers' />} icon={<SupportIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Motoristas de Suporte</b>
          </MenuItem>
          <MenuItem id="statusButton" className="menuItem" component={<Link to='/delivery-status' />} icon={<StickyNote2Icon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Status de Entregas</b>
          </MenuItem>
          <MenuItem id="boxCountButton" className="menuItem" component={<Link to='/box-count' />} icon={<ArchiveRoundedIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Entrada de Caixas</b>
          </MenuItem>
          <MenuItem id="boxReturnButton" className="menuItem" component={<Link to='/box-return' />} icon={<img src={IconBoxReturn} className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Devolução de Caixas</b>
          </MenuItem>
          <MenuItem id="scoreButton" className="menuItem" component={<Link to='/clients' />} icon={<img src={ScoreIcon} className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Clientes</b>
          </MenuItem>
          <MenuItem id="punctualityButton" className="menuItem" component={<Link to='/driver-punctuality' />} icon={<img src={IconPunctuality} alt="icon" className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Pontualidade</b>
          </MenuItem>
          <MenuItem id="punctualityButton" className="menuItem" component={<Link to='/priority' />} icon={<MapIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Prioridades</b>
          </MenuItem>
          <MenuItem id="routeButton" className="menuItem" component={<Link to='/routes' />} icon={<img src={RouteIcon} alt="icon" className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Atribuição de Rotas</b>
          </MenuItem>
          <MenuItem id="ExportRoutesButton" className="menuItem" component={<Link to='/export-routes' />} icon={<PiExportFill className="menuIcon" style={{ color: '#A2A2A2', fontSize: '25.5px' }} />}>
            <b className="menuText">Exportação de Rotas</b>
          </MenuItem>
          <div className="line" />
          <MenuItem id="userButton" className="menuItem" onClick={handleClick} icon={<PersonIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Usuários</b>
          </MenuItem>
          <MenuItem id="signupButton" className="menuItem" component={<Link to='/signup' />} icon={<PersonAddIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Cadastrar Usuário</b>
          </MenuItem>
          <MenuItem id="configButton" className="menuItem" onClick={handleClick} icon={<SettingsIcon className="menuIcon" style={{ color: '#A2A2A2' }} />}>
            <b className="menuText">Configurações</b>
          </MenuItem>
        </Menu>
        {!collapsed &&
          <div className="btnBackground">
            <button className="btn" onClick={useLogout}>Sair</button>
          </div>
        }
      </Sidebar>
    </SidebarWrapper>
  )
}

export default SidebarComponent;
