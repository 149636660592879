import { ISensitiveItem, Orders } from "interfaces/ISensitiveItems";
import { useEffect, useState } from "react";
import { SensitiveItemOrder } from "./SensitiveItemOrder";
import { boxReturnService } from "services/boxReturnService";
import { toast } from "react-toastify";
import { OrderListContainer } from "./styles";
import { LinearProgress } from "@mui/material";
import { BtnLock } from "interfaces/IBoxes";

interface IProps {
    route: number,
    driverName: string,
    setDisableSave: React.Dispatch<React.SetStateAction<BtnLock>>,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function SensitiveItemsForm({ route, driverName, setDisableSave, setOpen }: IProps) {
    const [orderList, setOrderList] = useState<Orders>({} as Orders)
    const [updatedList, setUpdatedList] = useState<ISensitiveItem[]>([] as ISensitiveItem[])
    const [loading, setLoading] = useState(false)

    const fetchData = () => {
        setLoading(true)
        boxReturnService.getOrdersByRoute(route, true)
            .then(response => {
                if (response.results.length < 1) {
                    toast.info("Não foram encontrados pedidos nessa rota")
                }
                const joinedData = joinData(response.results)
                setOrderList(joinedData)
            }).catch(e => {
                toast.error("Não foi possível buscar os dados no servidor")
            }).finally(() => {
                setLoading(false)
            })
    }

    const joinData = (data: ISensitiveItem[]) => {
        let orderList: Orders = {}

        orderList = data.reduce((acc, order) => {
            const index = order.orderId.toString()
            if (index && !acc[index]) {
                acc[index] = []
            }
            order.orderId && acc[index].push(order)
            return acc
        }, orderList)
        return orderList
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (updatedList.length === 0) {
            setOpen(false)
            return
        }
        setLoading(true)
        setDisableSave(prev => ({...prev, sensitiveBtn: true}))
        boxReturnService.updateBrokenItems(updatedList)
            .then(() => {
                toast.success("Conferência de quebra atualizada com sucesso!")
                setOpen(false)
                setUpdatedList([])
            }).catch(e => {
                toast.error("Houve um erro ao tentar salvar os dados no sistema")
            }).finally(() => {
                setLoading(false)
                setDisableSave(prev => ({...prev, sensitiveBtn: false}))
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <form id="popupForm" onSubmit={handleSubmit}>
            <div className="col1 full">
                <div className="section">
                    <div className="sectionHeader">
                        <h2 className="sectionTitle">Motorista: {driverName}</h2>
                        <p className="sectionDesc">Marcar itens quebrados para a rota {route}</p>
                        {loading && <LinearProgress color="success" style={{ position: 'absolute', width: '100%' }} />}
                    </div>
                    <OrderListContainer>
                        <div className="list">
                            {Object.entries(orderList).map(order => (
                                <SensitiveItemOrder data={order[1]} updatedList={updatedList} setUpdatedList={setUpdatedList} key={order[0]} />
                            ))}
                        </div>
                    </OrderListContainer>
                </div>
            </div>
        </form>
    )
}