import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const PopupBack = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`

export const ProductSearchPopupContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 140%;
    margin: auto;
    width: 427px;
    border-radius: 8px;
    background-color: ${defaultTheme.colors['white-100']};
    z-index: 100;
    filter: drop-shadow(0px 0px 20px #00000040);

    ::after {
        content: "";
        position: absolute;
        bottom: 100%; /* Adjust positioning to place the triangle at the top */
        left: 50%;
        transform: translateX(-50%);
        border-width: 0 10px 10px 10px; /* Adjust border-width to create the triangle */
        border-style: solid;
        border-color: transparent transparent ${defaultTheme.colors['white-100']} transparent;
    }

    .list {
        position: relative;
        min-height: 48px;
        max-height: 452px;
        transition: height 200ms;
        overflow-y: auto;
        margin: 16px 0px 16px 24px;

        .entry:nth-of-type(n) {
            border-bottom: 1px solid ${defaultTheme.colors['white-table']};

        }

        .entry:last-of-type {
            border: none;
        }
    }
`