import { baseURL } from 'settings';

import { api as apiService, ApiService } from './api'
import { IProductConfig } from 'interfaces/ISensitiveItems';

interface ISearch {
  page: number;
  search: string;
  isSensitive?: number
}

interface IProductResponse {
  results: IProductConfig[]
}

class ProductService {
  constructor(private readonly api: ApiService, private readonly baseURL: string) {}

  public getProducts = (params: ISearch): Promise<IProductResponse> => {
    return this.api.get(`${this.baseURL}/v1/delivery/product-config`, {params})
  }

  public updateProduct = (params: IProductConfig[]): Promise<IProductResponse> => {
    return this.api.put(`${this.baseURL}/v1/delivery/product-config/bulk`, params)
  }
}

export const productService = new ProductService(apiService, baseURL)
