import { HeaderWrapper } from "./styles";
import { Logo } from "components/Logo";
import { useAuth } from "contexts/AuthContext";
import { Link } from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";
import MenuIcon from '@mui/icons-material/Menu';

export function Header() {
    const {username} = useAuth()
    let name = (username.split(".")[0] || "usuário")
    name = name.charAt(0).toUpperCase() + name.slice(1)
    const { collapseSidebar, collapsed } = useProSidebar()

    return (
        <HeaderWrapper className="header">
            <div className="sidebarToggle">
                <button onClick={(e) => {
                    collapseSidebar(!collapsed)
                    
                }}>
                    <MenuIcon sx={{color: '#757575'}}/>
                </button>
            </div>
            <Link to="/home">
                <Logo/>
            </Link>
            <div className="userWrapper">
                <span className="avatar">{name[0].toUpperCase()}</span>
                <span className="name">{name}</span>
            </div>
        </HeaderWrapper>
    )
}