import { FilterSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import arrowFilter from 'assets/arrowFilter.svg'
import { TeamSelect } from './SelectTeam';
import { CostSelect } from './SelectCost';
import { CapacitySelect } from './SelectCapacity';
import { PaginationProps } from "interfaces/IPagination";
import { ITeams } from "interfaces/ITeams";
import { api } from "services/api";
import { baseURL } from "settings";
import { IVehicleFilter } from "interfaces/IVehicle";

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParams: (x: any) => void
}

export function FilterDrivers({ open, setOpen, onChangeParams }: IProps) {
  const [teamSelect, setTeamSelect] = useState(false)
  const [costSelect, setCostSelect] = useState(false)
  const [capacitySelect, setCapacitySelect] = useState(false)
  const [params, setParams] = useState({ page: 1, pageSize: 300, search: ''})
  const [entries, setEntries] = useState<PaginationProps<ITeams>>({} as PaginationProps<ITeams>)
  const [capacityData, setCapacityData] = useState<PaginationProps<IVehicleFilter>>({} as PaginationProps<IVehicleFilter>)
  const [selectedLeader, setSelectedLeader] = useState<string[]>([]) 
  const [selectedVehicle, setSelectedVehicle] = useState<number[]>([]) 

  const handleCheckbox = async (element: string) => {
    if (selectedLeader?.includes(element)) {
      setSelectedLeader(state => ( [ ...state?.filter((data: string) => data !== element) ] ));
    } else {
      setSelectedLeader(state => ( [...state, element] ));
    }
  }

  const handleCheckboxVehicle = async (element: number) => {
    if (selectedVehicle?.includes(element)) {
      setSelectedVehicle(state => ( [ ...state?.filter((data: number) => data !== element) ] ));
    } else {
      setSelectedVehicle(state => ( [...state, element] ));
    }
  }

  const fetchDataDrivers = async () => {
    await api.get(`${baseURL}/v1/delivery/team`, {
        params
    })
      .then((response) => {
        setEntries(response);
      })
  };

  const fetchDataCapacity = async () => {
    await api.get(`${baseURL}/v1/delivery/vehicle`, {
      params
    })
      .then((response) => {
        setCapacityData(response);
      })
  };

  useEffect(() => {
    fetchDataDrivers()
    fetchDataCapacity()
  }, [params])

  useEffect(() => {    
    onChangeParams({ "leader": selectedLeader.join(",") })
  }, [selectedLeader]);

  useEffect(() => {    
    if(selectedVehicle.length > 0) {
      onChangeParams({ "inUse": selectedVehicle.join("") })
    } else {
      onChangeParams({ "inUse": 1 })
    }
  }, [selectedVehicle]);

  const display = open ? 'initial' : 'none'

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }}
      style={{ display }}
      />
      <FilterSelectContainer style={{ display }}>
        <div className="list">
            <div className="entry">
              <button type="button" className="entryName" onClick={() => setTeamSelect(true)}>Time</button>
              <img src={arrowFilter} alt='icon arrow filter' />
            </div>
          <div className="entry" onClick={() => handleCheckbox("True")}>
            <button type="button" className="entryName" onClick={() => handleCheckbox("True")}>Líder</button>
            <input
              type="checkbox"
              checked={selectedLeader?.includes("True")}
              onClick={() => handleCheckbox("True")}
            />
          </div>
          <div className="entry" onClick={() => handleCheckboxVehicle(0)}>
            <button type="button" className="entryName" >Veículos inativos</button>
            <input
              type="checkbox"
              checked={selectedVehicle?.includes(0)}
              onClick={() => handleCheckboxVehicle(0)}
            />
          </div>
          <div className="entry">
            <button type="button" className="entryName" onClick={() => setCostSelect(true)}>Custo</button>
            <img src={arrowFilter} alt='icon arrow filter' />
          </div>
          <div className="entry">
            <button type="button" className="entryName" onClick={() => setCapacitySelect(true)}>Tipo</button>
            <img src={arrowFilter} alt='icon arrow filter' />
          </div>
        </div>
        <TeamSelect entries={entries} onChangeParams={onChangeParams} open={teamSelect} setOpen={setTeamSelect} />
        <CostSelect onChangeParams={onChangeParams} open={costSelect} setOpen={setCostSelect} />
        <CapacitySelect capacityData={capacityData} onChangeParams={onChangeParams} open={capacitySelect} setOpen={setCapacitySelect} />
      </FilterSelectContainer>
    </>
  )
}
