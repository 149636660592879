import { IBoxCount, IBoxes, IBoxReturnHistory } from "interfaces/IBoxes";
import { api as apiService, ApiService } from "./api";
import { baseURL } from "settings";
import { ISensitiveItem } from "interfaces/ISensitiveItems";

export class BoxReturnService {
  constructor(
    private readonly api: ApiService,
    private readonly baseURL: string
  ) {}

  public getBoxReturnHistory = (boxesReturnId: number) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-return/history`, {
      params: {
        boxesReturnId,
      },
    });
  }

  public getBoxesReturn = (params: any) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-return`, { params });
  };

  public getBoxesBalance = (params: any) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-balance`, { params });
  };

  public getFirstMileTeam = () => {
    return this.api.get(`${this.baseURL}/v1/delivery/driver`, { params: { teamId: 7 } });
  };

  public updateBoxReturnHistory = (updates: IBoxReturnHistory[]) => {
    return this.api.put(`${this.baseURL}/v1/delivery/boxes-return/history`, {
      updates,
    });
  }

  public updateComment = (data: IBoxes): Promise<any> => {
    return this.api.put(`${this.baseURL}/v1/delivery/boxes-return`, data);
  };

  public updateBoxCount = async (elements: IBoxCount[]) => {
    return this.api.put(
      `${this.baseURL}/v1/delivery/boxes-count/bulk`,
      elements
    );
  };

  public updateBrokenItems = (elements: ISensitiveItem[]) => {
    return this.api.put(
      `${this.baseURL}/v1/delivery/conference-item/bulk`,
      elements
    );
  };

  public submitForm = (element: IBoxes): Promise<any> => {
    const params = {
      route: element.route,
      vehicleId: element.vehicle.id,
      firstMile: element.firstMile,
      boxesReturned: element.boxesReturned,
      boxesConfirmed: element.boxesConfirmed,
      boxesSent: element.boxesSent,
      returnComment: element.returnComment,
      countComment: element.countComment,
      deliveryDate: element.deliveryDate,
      isSupport: element.isSupport,
    };

    return this.api.put(`${this.baseURL}/v1/delivery/boxes-return`, params);
  };

  public saveFileBoxesReturn = (start: string, end: string) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-return/file`, {
      responseType: "arraybuffer",
      params: {
        startDeliveryDate: start,
        endDeliveryDate: end,
      },
    });
  };

  public getOrdersByRoute = (route: number, hasConference?: boolean) => {
    const params = {
      route: route,
      page: 0,
      hasConferenceItems: hasConference,
    };

    return this.api.get(`${this.baseURL}/v1/delivery/route-tag`, { params });
  };

  public getOrdersBoxCount = (params: {
    route?: number;
    startCountDate?: string;
    endCountDate?: string;
  }) => {
    return this.api.get(`${this.baseURL}/v1/delivery/boxes-count`, { params });
  };

  public getSensitiveItems = (orderId: string | number) => {
    const params = {
      pageSize: 0,
      orderId: orderId,
    };

    return this.api.get(`${this.baseURL}/v1/delivery/conference-item`, {
      params,
    });
  };
}

export const boxReturnService = new BoxReturnService(apiService, baseURL);
