import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

interface IExtraButtonStyles {
  buttonStyle: "filled" | "outlined";
  children: JSX.Element;
  customWidth?: string;
  customMargin?: string;
}

export const ExtraButtonContainer = styled.button<IExtraButtonStyles>`
  width: ${({ customWidth }) => customWidth || "134px"};
  height: 40px;
  background: ${({ buttonStyle }) =>
    buttonStyle === "outlined" ? "transparent" : defaultTheme.colors["base"]};
  color: ${({ buttonStyle }) =>
    buttonStyle === "filled"
      ? defaultTheme.colors["white-100"]
      : defaultTheme.colors["base"]};
  padding: 0 12px;
  border-radius: 12px;
  transition: 0.3s;
  border: ${({ buttonStyle }) =>
    buttonStyle === "filled"
      ? "none"
      : `1px solid ${defaultTheme.colors["base"]}`};
  margin: ${({ customMargin }) =>
    customMargin ? customMargin : customMargin};

  :disabled {
    background-color: ${defaultTheme.colors["gray-100"]};
    opacity: 0.9;
    transition: 0.3s;
  }

  .buttonContent {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;

    span {
      width: 100%;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 769px) {
    height: 35px;
    .buttonContent {
      svg{
        width: 20px;
        height: 20px;
      }
      span {
        font-size: 0.875rem;
      }
    }
}`;

export const ToolbarWrapper = styled.div<{ singleButton: boolean, hideFilter?: boolean }>`
  margin-top: 25px;
  display: flex;
  justify-content: ${({ singleButton }) => singleButton ? 'space-between' : 'initial'};
  width: 100%;
  align-items: center;
  margin-right: 664px;
  flex-wrap: wrap;
  gap: 1rem;

  .searchAndFilter {
    display: flex;
    align-items: center;
    gap: 1vw;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  .button {
    width: 134px;
    height: 40px;
    background: #89bd23;
    color: #fff;
    padding: 0 12px;

    :disabled {
      background-color: ${defaultTheme.colors["gray-300"]};
    }
  }

  .button.outlined {
    background-color: transparent;
    border: 1px solid ${defaultTheme.colors["base"]};
    color: ${defaultTheme.colors["base"]};

    :disabled {
      cursor: not-allowed;
      border-color: ${defaultTheme.colors["gray-300"]};
      color: ${defaultTheme.colors["gray-300"]};
    }
  }

  .button.long {
    width: 154px;
    /* padding-left: 24px; */
  }

  .buttonFilter {
    width: 134px;
    height: 40px;
    background: transparent;
    color: #89bd23;

    /* &:not(:disabled):hover {
            background: ${({ theme }) => theme.colors["base-light"]};;
        } */
  }

  .filterIcon,
  .plusIcon,
  .planilhaIcon {
    cursor: pointer;
  }

  .filterContainer {
    position: absolute;
  }

  .searchIcon {
    position: absolute;
    left: 16px;
    top: 16px;
    bottom: 16px;
    z-index: 1;
    pointer-events: none;
  }

  .plusIcon {
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
    pointer-events: none;
  }

  .buttonPlanilhaContainer {
    display: flex;

    .export {
      margin-left: 10px;
    }
  }

  .custom-file-input {
    padding: 0 2.8rem;
    color: "#6E8247";
    background: "#FFF";
    border-radius: 12px;
    transition: 0.4s;
    font-size: 16px;
    width: 372px;
    border: none;
    cursor: pointer;
  }

  .planilhaText {
    cursor: pointer;
  }

  .buttonPlanilha {
    display: flex;
    position: relative;
    width: 190px;
    height: 40px;
    background: transparent;
    color: ${defaultTheme.colors["base"]};
    border: 1px solid ${defaultTheme.colors["base"]};
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0 20px;
    cursor: pointer;
  }

  .buttonPdf {
    display: flex;
    position: relative;
    width: 190px;
    height: 40px;
    background: transparent;
    color: ${defaultTheme.colors["base"]};
    border: 1px solid ${defaultTheme.colors["base"]};
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 0 20px;
  }

  .planilhaIcon {
    z-index: 1;
  }

  .planilhaText {
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
  }

  .filterIcon {
    position: absolute;
    top: 10px;
    left: 12px;
    z-index: 1;
  }

  .buttonFilterContainer {
    border: none;
    height: 32px;
    position: relative;
    width: 84px;
    margin: 0 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 12px;

    transition: all 100ms;

    :hover {
      background-color: #f0f0f0;
    }
  }

  .input,
  .buttonPlus,
  .buttonPlanilhaContainer {
    height: 100%;
    position: relative;
  }

  input[type="file"] {
    border: none;
    font-size: 0;
    opacity: 0;
    outline: none;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    z-index: -1;
  }

  @media screen and (max-width: 769px) {
    gap: 20px;
    .buttonPlanilha,
    .planilhaIcon {
      display: none;
    }

    .buttonPlus {
      width: 100%;
      margin-bottom: 20px;
    }

    .buttons{
      width: 100%;
    }

    .buttonPlus > button {
      width: 100%;
    }

    .buttonPdf {
      width: 120px;
      height: 35px;
    }

    .searchAndFilter {
      gap: 2vw;
    }

    input {
      width: ${({ hideFilter }) => (hideFilter ? '90vw': '73vw')};
    }

    .searchIcon {
      top: 14px;
    }

    .buttonFilterContainer {
      width: 64px;
      height: 48px;
      margin-left: 0;
      flex-direction: column;
      justify-content: center;
    }

    .buttonFilter {
      font-size: 0.9rem;
      padding-left: 0;
      width: auto;
    }

    .filterIcon {
      position: relative;
      left: 0;
      right: 0;
      margin: auto;
      margin-bottom: 4px;
      width: 21px;
    }
  }
`;
