import styled from "styled-components";
import { ButtonProps } from "./index";

export const ButtonContainer = styled.button<ButtonProps>`
  color: ${props => props.variant === 'primary' ? '#FFFFFF' : '#6E8247'};
  background: #6E8247;
  border-radius: 12px;
  transition: 0.4s;
  font-size: 14px;
  width: 372px;
  border: none;
  text-align: start;
  padding-left: 36px;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

`