import { BrowserRouter } from "react-router-dom";
import Routes from "routes/Routes";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./styles/global";
import { defaultTheme } from "./styles/theme/default"
import { AppProvider } from "contexts";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme} >
        <GlobalStyle />
        <ToastContainer />
        <AppProvider>
          <Routes />
        </AppProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

