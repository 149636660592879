import { useEffect, useState } from "react";
import { ProductList } from "./ProductList";
import { productService } from "services/productService";
import { IProductConfig, SensitiveMap } from "interfaces/ISensitiveItems";
import { toast } from "react-toastify";

interface IProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function ProductConfigForm({setOpen}: IProps) {
    const [productList, setProductList] = useState<IProductConfig[]>([] as IProductConfig[])
    const [isSensitiveMap, setIsSensitiveMap] = useState<SensitiveMap>({} as SensitiveMap)

    const params = {search: "", page: 0, isSensitive: 1}

    const fetchData = () => {
        productService.getProducts(params)
        .then(response => {
            setProductList(response.results)
        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const newList: IProductConfig[] = productList.reduce((acc: IProductConfig[], product) => {
            if ((isSensitiveMap[product.id] !== product.isSensitive) || product.isNew) {
                acc.push({...product, isSensitive:isSensitiveMap[product.id]})
            }
            return acc
        }, [])
        
        if (newList.length === 0) {
            toast.info("Todos os itens já foram salvos")
            setOpen(false)
            return
        }
        
        toast.info("Enviando dados...")
        productService.updateProduct(newList)
        .then(response => {
            setOpen(false)
            toast.success("Itens sensíveis alterados com sucesso!")
        }).catch(e => {
            toast.error("Não foi possível atualizar os itens sensíveis")
        })
    }

    useEffect(() => {
        fetchData()
    }, [])
    
    return (
        <form id="popupForm" onSubmit={handleSubmit}>
            <div className="col1">
                <div className="section">
                    <span className="sectionDesc">Pesquise itens para adicionar ou excluir</span>
                    <div className="form">
                        <ProductList data={productList} setData={setProductList} isSensitiveMap={isSensitiveMap} setIsSensitiveMap={setIsSensitiveMap}/>
                    </div>
                </div>
            </div>
        </form>
    )
}