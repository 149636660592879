import { ButtonHTMLAttributes, ReactNode } from "react"
import { ButtonContainer } from "./styles"

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string | number;
  variant?: 'primary' | 'secondary';
}

export function Button({ text, children, ...props }: ButtonProps) {
  return (
    <ButtonContainer {...props}>
      {text || children}
    </ButtonContainer>
  )
}

interface ButtonPlanilhaProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  variant?: 'primary' | 'secondary';
}

export function ButtonPlanilha({ children, ...props }: ButtonPlanilhaProps) {
  return (
    <ButtonContainer {...props}>
      {children}
    </ButtonContainer>
  )
}