import styled from "styled-components";

export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`

  .tableContainer {
    width: 100%;
    overflow-y: auto;
    border-radius: 12px;
  }

  .pagination{
    display: flex;
    justify-content: center;
  }

  .MuiPagination-ul {
    display: flex;
    margin-top: 43px;
    margin-bottom: 50px;
    width: 400px;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
  }

  .MuiButtonBase-root {
    position: relative;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 16.5%;
    font-weight: 400;
    color: #6E8247;
  }

  .infos {
    margin-bottom: 28px;

    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .button {
    width: 134px;
    height: 40px;
    background: #89BD23;
    color: #FFF;
    padding-left: 44px;

  }

  .buttonFilter {
    width: 134px;
    height: 40px;
    background: transparent;
    color: #89BD23;
  }

  .input, .buttonPlus {
    height: 100%;
    position: relative;
  }

  @media screen and (max-width: 600px){

  .infos{
    margin-bottom: 0;
    h3{
      font-size: 17px;
    }
  }

  .toolbar {
    input.toolbarInput {
      width: 75vw;
    }
  }

  .filterContainer {
    left: 32vw;
  }

}`

export const Line = styled.tr`
    width: 100%;
    height: 48px;

    .contentTable {
      text-align: center;
    }

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }

    .contentScore {
      height: 48px;
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: flex-end;
      margin-right: 1%;
      overflow: hidden;
    }

    .contentComment {
      text-align: left;
      padding-left: 1%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .contentEdit {
      width: 20%;
    }

    .scoreGreen {
      width: 7px;
      height: 9px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 14px solid #04D361;
    }

    .scoreRed{
      width: 7px;
      height: 9px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 14px solid #EB001B;
      transform: rotate(180deg);
    }
`
