import styled from "styled-components";

export const EditBtnContainer = styled.div`
    button {
        border: none;
        text-decoration: none;
        background-color: transparent;
    }

    img {
        filter: brightness(1.25);
    }
`