import { Checkbox } from '@mui/material'
import { CheckboxLabel } from './styles'

interface ICheckbox {
  text?: string,
  handleToggle?: () => void;
  checked?: boolean;
  align?: string;
  size?: 'medium' | 'small';
  disabled?: boolean;
}

export interface ICheckboxOption {
  id: string;
  text: string;
  handleToggle: () => void;
  state: boolean;
}

const CustomCheckBox = ({ text, handleToggle, checked, align, size, disabled }: ICheckbox) => {
  return (
    <CheckboxLabel justifyContent={align}>
      <Checkbox
        onChange={handleToggle && handleToggle}
        checked={checked && checked}
        size={size || 'medium'}
        disabled={disabled}
      />
      {text && <label>{text}</label>}
    </CheckboxLabel>
  );
};


export default CustomCheckBox
