import { RegionLine } from "../RegionLine"
import { useState } from "react"
import { IRegion } from "interfaces/IRegion"

interface IProps {
    data: IRegion,
    index: number,
    dragItem: React.MutableRefObject<any>,
    dragOverItem: React.MutableRefObject<any>,
    draggable: boolean,
    sortList: () => void
}

export function DraggableRegionLine({data, index, dragItem, dragOverItem, draggable, sortList}: IProps) {
    const [isOver, setIsOver] = useState(false)
    const [dragState, setDragState] = useState<"active"|"inactive">("inactive")
    
    return (
        <div className="priorityGroup">
            <div className="side">
                <span className="priorityLetter">{index + 1}</span>
            </div>
            <div className={`entries ${isOver && "over"}`} draggable={draggable}
                onDragStart={e => {
                    dragItem.current = index
                    setDragState("active")
                }}
                onDragEnter={e => dragOverItem.current = index}
                onDragEnd={() => {
                    sortList()
                    setIsOver(false)
                    setDragState("inactive")
                }}
                onDragOver={e => {
                    e.preventDefault()
                    !isOver && dragOverItem.current !== dragItem.current && setIsOver(true)
                }}
                onDragLeave={() => setIsOver(false)}
                onDrop={() => setIsOver(false)}
                key={index}>
                <RegionLine data={data} className={dragState} />
            </div>
        </div>
    )
}