import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import { useEffect, useState } from "react";
import { PaginationProps } from "interfaces/IPagination";
import { PageContainer } from "components/PageContainer";
import { Popup } from "components/Popup";
import { Toolbar } from "components/Toolbar";
import { DataCard } from 'components/DataCard';
import { toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';
import { Container, MainContainer, SidebarContainer } from './styles';
import { dateToday } from 'utils/dateToday';
import { ISupportDriver } from 'interfaces/IDriver';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmPopup } from 'components/ConfirmPopup';
import { IVehicle } from 'interfaces/IVehicle';
import { SupportDriversForm } from 'components/PopupForms/SupportDriverForm';
import { useMediaQuery } from 'react-responsive';
import { NoDataMessage } from 'components/NoDataMessage/styles';
import { driverVehicleService } from 'services/driverVehicleService';

export function SupportDrivers() {
  const [supportDriverTable, setSupportDriverTable] = useState<PaginationProps<ISupportDriver>>({} as PaginationProps<ISupportDriver>)
  const [driverList, setDriverList] = useState<PaginationProps<IVehicle>>({} as PaginationProps<IVehicle>)
  const [formData, setFormData] = useState<ISupportDriver>({} as ISupportDriver)
  const [searchTerm, setSearchTerm] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [params, setParams] = useState({
    search: '',
    notPaginated: true,
    pageSize: 300,
    deliveryDate: dateToday()
  });
  const [loading, setLoading] = useState(false);
  const smallScreen = useMediaQuery({ maxWidth: 820 });

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await driverVehicleService.getSupportDrivers(params);
      setSupportDriverTable(response);
    } catch (e) {
      toast.error("Houve um erro ao buscar os dados no sistema: " + e)
    } finally {
      setLoading(false)
    }
  }

  const fetchDataDrivers = async () => {
    try {
      const response = await driverVehicleService.getVehicleDrivers(params);
      setDriverList(response);
    } catch (e) {
      toast.error("Houve um erro ao buscar os dados no sistema: " + e)
    }
  }

  const handleAdd = () => {
    setFormData({} as ISupportDriver)
    setPopupVisible(true)
  }

  const onDelete = async (id: number) => {
    try {
      await driverVehicleService.deleteDriverSupport(id);
      setDeletePopupVisible(false);
      fetchData();
      toast.success("Motorista deletado com sucesso!");
    } catch (error) {
      toast.error("Erro ao deletar motorista: " + error);
    }
  };

  const handleDelete = (element: ISupportDriver) => {
    setFormData(element)
    setDeletePopupVisible(true)
  }

  useEffect(() => {
    fetchData()
  }, [params]);

  useEffect(() => {
    fetchDataDrivers()
  }, []);

  return (
    <Container>
      <Header />
      <div className="pageContent">
        <Popup
          popupTitle='Selecionar Motoristas'
          content={
            <SupportDriversForm
              setPopupVisible={setPopupVisible}
              data={driverList?.results}
              params={params}
              fetchData={fetchData}
            />
          }
          open={popupVisible}
          setOpen={setPopupVisible}
          isEditPopup
          titleStyles={{
            pl: '50px'
          }}
        />
        <ConfirmPopup
          states={[deletePopupVisible, setDeletePopupVisible]}
          handleDelete={() => onDelete(formData.id)}
        />

        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Motoristas de Suporte</h3>
              <Toolbar
                params={params}
                paramState={setParams}
                loadingState={loading}
                placeholder="Nome, Agile Code ou Placa"
                add={true}
                onAdd={handleAdd}
                page='SupportDrivers'
              />
            </div>
            {loading && <LinearProgress color="success" style={{ width: '100%' }} />}
            <div className='cardContainer'>
              <div className="dataCardContainer">
                {supportDriverTable.results?.length > 0  && (
                  supportDriverTable.results
                    .filter(item => item?.vehicle?.driver?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                    .sort((a: ISupportDriver, b: ISupportDriver) => a.vehicle.driver.name.localeCompare(b.vehicle.driver.name))
                    .map(element => (
                      <DataCard key={element.id} stripColor={element.vehicle?.driver?.team?.colorTeam}>
                        <div className="mainInfo">
                          <p className="name">{element?.vehicle?.driver?.name || element?.vehicle?.name}</p>
                          {smallScreen ? null : <span> - </span>}
                          <p className="plate">{element?.vehicle?.licensePlate}</p>
                        </div>
                        <div className="sideMenu">
                          <DeleteIcon className='deleteIcon' onClick={() => handleDelete(element)} />
                        </div>
                      </DataCard>
                    ))
                )}
              </div>
            </div>
            {supportDriverTable.results?.length === 0 && !loading && (
              <NoDataMessage isPopup={false} ht='45vh'>
                <h3>Nenhum motorista encontrado</h3>
                <p>Clique em '+adicionar' para incluir um novo motorista de suporte.</p>
              </NoDataMessage>
            )}
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  )
}
