import styled from "styled-components";

export const ClockContainer = styled.div`
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    .clockSvg {
        scale: 65%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
`