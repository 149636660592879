import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ClockContainer } from './styles';
import { defaultTheme } from 'styles/theme/default';

interface IProps {
    timing : Timing,
}

enum Timing {
    ON_TIME = 1,
    LATE = 2,
    V_LATE = 3
}

export function DeliveryTimeIcon ({timing}: IProps) {
    let color = {
        base: "",
        accent: ""
    }
    switch (timing) {
        case 1:
            color.base = defaultTheme.colors['base-light']
            color.accent = defaultTheme.colors['base-dark']
            break
        case 2:
            color.base = "#FFC09C"
            color.accent = "#EF7300"
            break
        case 3:
            color.base = "#FFB6B6"
            color.accent = "#EB001B"
    }
    return (
        <ClockContainer style={{ backgroundColor: color.base || defaultTheme.colors['gray-200'] }}>
            <AccessTimeIcon className="clockSvg" sx={{ fill: color.accent || defaultTheme.colors['gray-100']}}/>
        </ClockContainer>
    )
}