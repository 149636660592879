import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const ClientScoreFormContainer = styled.form`
    .scoreContainer {
        width: 60px;
        height: 32px;
        font-family: ${defaultTheme.fonts['regular']};
        font-weight: 400;
        font-size: 0.8rem;
        background: ${defaultTheme.colors['base-light']};
        border-radius: 22px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    input, textarea {
        border: none;
        background-color: ${defaultTheme.colors['base-input']};
    }

    .clientComment {
        resize: none;
        border-radius: 8px;
        padding: 12px;
    }
`