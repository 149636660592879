import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DropContainer } from './styles';
import { useDebaunce } from 'utils/debaunce';

interface IProps {
    text: string,
    bgColor: string,
    select: boolean,
    setSelect: Dispatch<SetStateAction<boolean>>,
    content: JSX.Element,
    className?: string
}

export function DropdownButton({ text, bgColor, select, setSelect, content, className }: IProps) {

    const getContrast = (hexcolor: string) => {

        // If a leading # is provided, remove it
        if (hexcolor && hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // If a three-character hexcode, make six-character
        if (hexcolor.length === 3) {
            hexcolor = hexcolor.split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }
    
        // Convert to RGB value
        var r = parseInt(hexcolor.substr(0,2),16);
        var g = parseInt(hexcolor.substr(2,2),16);
        var b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 128) ? 'black' : 'white';
    
    };

    return (
        <DropContainer>
            {select &&
            <div className="backdrop" onClick={(e) => {
                e.stopPropagation()
                setSelect(false)
            }}/>
            }
            <button type="button" name="dropdown" className={`dropdownBtn ${className}`}
                style={{ backgroundColor: bgColor }}
                onClick={(e) => {
                    setSelect(!select)
                }}>
                <span className={`dropName ${getContrast(bgColor || "#fff")}`}>{text}</span>
                {select ? <KeyboardArrowUpIcon className="arrow" /> : <KeyboardArrowDownIcon className="arrow" />}
            </button>
            <div className="contentContainer">
            {select && content}
            </div>
        </DropContainer>
    )
}