import { DepotLineContainer } from "./styles";
import { IDist } from "interfaces/IDist";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

interface IProps {
    data: IDist,
    canEdit: boolean,
    handleUpdate: (x: IDist) => void
}

export function DepotLine ({data, canEdit, handleUpdate}: IProps){
    const [abbr, setAbbr] = useState(data.initials || "")
    const [abbrUpdate, setAbbrUpdate] = useState(false)
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const upperValue = e.target.value.toUpperCase()
        setAbbr(upperValue)
        setAbbrUpdate(upperValue !== data.initials)
    }

    useEffect(() => {
        if (abbr === data.initials) {
            setAbbrUpdate(false)
        }
        const newElement = { ...data, initials: abbr }
        handleUpdate(newElement)
    }, [abbr])
    
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        !e.target.value && setAbbr(data.initials || "")
    }

    useEffect(() => {
        if (!canEdit) {
            setAbbr(data.initials || "")
        }
    }, [canEdit])

    return (
        <DepotLineContainer className="city">
            <span className="small id">{data.id}</span>
            <span className="large city">{data.name}</span>
            <input type="text" className={`small abbr ${abbrUpdate && "updated"}`} value={abbr} placeholder={data.initials || ""} disabled={!canEdit} 
            onChange={handleChange}
            onBlur={handleBlur}/>
        </DepotLineContainer>
    )
}