import { CircularProgress } from "@mui/material";
import { IDelivery } from "interfaces/IDeliveryAttempt";
import { useEffect, useState } from "react";
import { api } from "services/api";
import { baseURLFilesApi } from "settings";

interface IProps {
  apiData: IDelivery
}

export function DeliveryStatusFormObs({apiData}: IProps) {
  const {deliveryAttempt} = apiData
  const [urlImage, setUrlImage] = useState<string>()
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleImageClick = () => {
    setIsImageOpen(true);
  };

  const handleCloseImage = () => {
    setIsImageOpen(false);
  };
  
  async function getURLImage() {
    await api.get(`${baseURLFilesApi}/v1/file`, {
      params: {
        "origin": "delivery.delivery-attempt",
        "externalCode": deliveryAttempt[0]?.id.toString()
      }    
    })
      .then((response) => {
        setUrlImage(response?.results[0]?.url);
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getURLImage()
  }, [])

  return (
    <>
      <div className="tablePopup">
        <div className="col1">
          <div className="section">
            <p className="titleObs">Quem recebeu esse pedido?</p>
            <span className="descObs">{deliveryAttempt[0]?.nameReceiver || "Sem nome"}</span>
            <div>
              <p className="titleObs">Observação</p>
              <span className="descObs">{deliveryAttempt[0]?.driverDescription || deliveryAttempt[0]?.description}</span>
            </div>
            <div>
              <p className="titleObs">Canhoto da entrega</p>
              <div
                style={{
                  width: '396px',
                  height: '160px',
                  backgroundColor: '#D9D9D9',
                  borderRadius: '16px',
                  marginTop: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleImageClick}
              >
                {isLoading ? (
                  <CircularProgress color="success" />
                ) : urlImage ? (
                  <img
                    src={urlImage}
                    alt="Canhoto da entrega"
                    style={{ width: "100%", height: "100%", borderRadius: "16px" }}
                  />
                ) : (
                  <p>Nenhuma imagem disponível</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isImageOpen && (
        <div className="imagePopup" onClick={handleCloseImage}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <img
              src={urlImage}
              alt="Canhoto da entrega"
              style={{
                width: '70%',
                height: '70%',
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}