import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const SwitchContainer = styled.div`
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    width: 25vw;
    height: 55px;

    display: flex;
    gap: 1.5rem;

    .buttonContainer {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 140%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        a {
            color: black;
            text-decoration: none;
        }

        &.selected {
            padding: 20px;
            background: ${defaultTheme.colors['base-light']};
            border-radius: 10px 10px 0px 0px;
            border-bottom: 4px solid ${defaultTheme.colors['base']};
        }
    }

    @media screen and (max-width: 769px){
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
`
