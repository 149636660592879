import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const DeliveryHoverContainer = styled.div`
    position: absolute;
    bottom: 140%;
    left: 0;
    right: 0;
    margin: auto;
    width: 284px;
    height: 184px;
    padding: 16px;
    background-color: ${defaultTheme.colors['white-100']};
    border-radius: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;

    font-family: 'Roboto';
    font-style: normal;
    font-size: 0.9rem;

    .header {
        cursor: default;
        width: 100%;
        font-weight: 500;
        margin-bottom: 12px;
    }

    .body {
        display: flex;
        gap: 0.8rem;
        width: 100%;
    }

    .fields {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        user-select: none;
    }

    .values {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 769px) {
      z-index: 1000;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
    }
`
