import SearchIcon from 'assets/searchIcon.svg'
import { TeamSelectContainer, SelectBack } from "./styles";
import { useDebaunce } from "utils/debaunce";
import { useEffect, useRef, useState } from "react";
import { baseURL } from "settings";
import { api } from "services/api";
import { PaginationProps } from 'interfaces/IPagination';
import { Input } from 'components/Input';
import { ITeams } from 'interfaces/ITeams';

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    handleSelect: (x: any) => void,
}

export function TeamSelect({ open, setOpen, handleSelect }: IProps) {
    const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })
    const [params, setParams] = useState({ page: 1, pageSize: 300, search: '' })
    const [searchTerm, setSearchTerm] = useState('');
    const [entries, setEntries] = useState<PaginationProps<ITeams>>({} as PaginationProps<ITeams>)
    const [className, setClass] = useState("")
    const teamSelectRef = useRef<HTMLDivElement>(null);

    const fetchData = async () => {
        await api.get(`${baseURL}/v1/delivery/team`, {
            params
        })
            .then((response) => {
                setEntries(response);
            })
    };

    const handlePick = (team: ITeams) => {
        handleSelect(team)
        setOpen(false)
    }

    function onChangeParams(params: any) {
        setParams((state: any) => ({ ...state, ...params }))
    }

    useEffect(() => {
        open && fetchData()
    }, [params]);

    useEffect(() => {
        if (teamSelectRef.current && open) {
          const rect = teamSelectRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight;
          const isOverflowing = rect.bottom > viewportHeight - 100;
          if (isOverflowing) {
            setClass("above")
          } else {
            setClass("below")
          }
        }
      }, [open]);

    if (!open) return null

    return (
        <>
            <SelectBack className="popupBack" onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }} />
            <TeamSelectContainer ref={teamSelectRef} className={className}>
                <div className="header">
                    <div className="input">
                        <img className="searchIcon" src={SearchIcon} alt="" />
                        <Input
                            className="inputBox"
                            onChange={e => {
                                setSearchTerm(e.target.value);
                                debaunceFilter({ search: e.target.value })
                            }}
                            placeholder="Nome"
                        />
                    </div>
                </div>
                <div className="list">
                    {entries.results?.filter(item => item?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map(element => (
                            <div className="entry" key={element.id}>
                                <button type="button" className="entryName" key={element.id} onClick={() => handlePick(element)}>
                                    <span className="entryTeam">{element.name}</span>
                                    <span className="entryInfo">Cidade</span>
                                </button>
                            </div>
                        ))}
                </div>
            </TeamSelectContainer>
        </>
    )
}
