import {ReactElement } from "react"
import { SignIn } from "pages/SignIn";

interface Props {
  children: ReactElement
}

export const PrivatePage = ({ children }: Props): ReactElement => {
  const isAuthenticated = localStorage.getItem("bearerToken")
  // Alterar styles para componente de Layout.
  if (!isAuthenticated) {
    return <SignIn/>
  }

  return children
}