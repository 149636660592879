import { IRoute } from "interfaces/IWaybill"
import { ContainerRoutes } from "./styles"

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  entries: IRoute[]
}

interface IArray {
  route: number|string,
  boxes: number
}

export function SelectContainerRoutes({ open, setOpen, entries }: IProps) {
  
  const emptyRoutes = entries.filter((data) => data.vehicle?.driver === null)
  const arr : IArray[] = []
  emptyRoutes.forEach((route) => {
    arr.push({"route": route.route, "boxes": route.boxesPlanned})
  })

  if (!open) return null

  return (
    <>
      <ContainerRoutes>
        <p className="title">Rotas sem atribuição</p>
        <div className="list">
          {arr.filter(element => element.route !== undefined).map((element: any) => (
            <div className="entry">
              <p className="entryName">{`${element.route || ""}`}</p>
              {element.boxes && (
                <p className="entryName">{`${element.boxes || 0} Caixas`} </p>
              )}
            </div>
          ))}
        </div>
      </ContainerRoutes>
    </>
  )
}