import FilterIcon from "assets/filterIcon.svg";
import { Button, ContainerButtonFilter, Image } from "./styles";

interface IProps {
  onClickFilter: () => void;
}

const ButtonFilter = (props: IProps) => {
  return (
    <ContainerButtonFilter onClick={props.onClickFilter}>
      <Image src={FilterIcon} />
      <Button className="buttonFilter" text="Filtrar" />
    </ContainerButtonFilter>
  );
};

export default ButtonFilter;
