import { IVehicle } from "interfaces/IVehicle"
import { useState } from "react"
import { RouteSelectContainer } from "./styles"
import { Input } from "components/Input"
import SearchIcon from 'assets/searchIcon.svg'
import CustomCheckBox from "components/checkbox"
import { toast } from "react-toastify"
import { baseURL } from "settings"
import { api } from "services/api"

interface ISupportDriverFormProps {
  data: IVehicle[],
  setPopupVisible: (x: boolean) => void
  params: {
    search: string;
    notPaginated: boolean;
    deliveryDate: string;
  }
  fetchData: () => Promise<void>
}

export const SupportDriversForm = ({ data, setPopupVisible, params, fetchData }: ISupportDriverFormProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDrivers, setSelectedDrivers] = useState<number[]>([])

  const handleCheckbox = async (element: number) => {
    if (selectedDrivers?.includes(element)) {
      setSelectedDrivers(state => ([...state?.filter((data: number) => data !== element)]));
    } else {
      setSelectedDrivers(state => ([...state, element]));
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (selectedDrivers.length === 0) {
      toast.warning("Selecione ao menos um motorista de suporte!")
      return
    }

    try {
      await api.post(`${baseURL}/v1/delivery/driver/support`, {
        vehiclesIds: selectedDrivers,
        deliveryDate: params.deliveryDate
      });
      toast.success("Motoristas de suporte adicionados com sucesso!")
      fetchData()
    } catch (err: any) {
      if (err.message.includes("Support driver already exists for vehicle")) {
         const vehicle = data.find(vehicle => vehicle.id === err.vehicle)
         return toast.warning(`Motorista ${vehicle?.driver?.name} já está cadastrado como suporte!`);
      }
      if (err.message.includes("Vehicle not found")) {
        const vehicle = data.find(vehicle => vehicle.id === err.vehicle)
        return toast.warning(`Motorista ${vehicle?.driver?.name} não foi encontrado`);
      }
      toast.error("Houve um erro ao adicionar os motoristas de suporte: " + err.message)
    } finally {
      setPopupVisible(false)
    }
  }

  return (
    <form id="popupForm" autoComplete="false" onSubmit={handleSave}>
      <RouteSelectContainer>
        <div className="header">
          <div className="input">
            <img className="searchIcon" src={SearchIcon} alt="" />
            <Input
              className="inputBox"
              onChange={handleSearch}
              placeholder="Motorista"
            />
          </div>
        </div>
        <div className="list">
          {data?.filter(item => item?.name?.toLowerCase().includes(searchTerm.toLowerCase()))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(element => (
              <div className="entry" key={element.id} onClick={() => handleCheckbox(element.id)}>
                <button type="button" className="entryName" key={element.id}>{`${element?.name}`}</button>
                <CustomCheckBox
                  checked={selectedDrivers?.includes(element.id)}
                  size="small"
                />
              </div>
            ))}
        </div>
      </RouteSelectContainer>
    </form>
  )
}
