import { Header } from 'components/Header';
import SidebarComponent from 'components/Sidebar';
import { Container, Line, MainContainer, SidebarContainer } from './styles';
import { ColumnProps, DataTable } from "components/Table";
import { baseURL, baseURLOrderApi } from "settings";
import { api } from "services/api";
import { useEffect, useState } from "react";
import { PaginationProps } from "interfaces/IPagination";
import { StatusTag } from "components/StatusBall";
import { useDebaunce } from "utils/debaunce";
import { Pagination } from "@mui/material";
import { PageContainer } from "components/PageContainer";
import { Toolbar } from "components/Toolbar";
import saveAs from "file-saver";
import { Popup } from "components/Popup";
import { DeliveryStatusForm } from "components/PopupForms/DeliveryStatusForm";
import { toast } from 'react-toastify';
import { dateToday } from 'utils/dateToday';
import { useMediaQuery } from 'react-responsive';
import { DeliveryStatusFilter } from 'components/DeliveryStatusFilters';
import { DeliveryTimeIcon } from 'components/DeliveryTimeIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { defaultTheme } from 'styles/theme/default';
import { IDelivery, IOrderItem, IOrderPayment } from 'interfaces/IDeliveryAttempt';
import { DeliveryStatusFormObs } from 'components/PopupForms/DeliveryStatusFormObs';

const columns: ColumnProps[] = [
    {
        name: "Nº pedido",
    },
    {
        name: "Motorista",
        align: "center",
    },
    {
        name: "Rota",
        align: "center"
    },
    {
        name: "Cliente",
        align: "center"
    },
    {
        name: "Data",
        align: "center"
    },
    {
        name: "Horário Previsto",
        align: "center"
    },
    {
        name: "Horário de Baixa",
        align: "center"
    },
    {
        name: "Obs"
    },
    {
        name: ""
    }
]

const smallColumns: ColumnProps[] = [
    {
        name: "ID",
    },
    {
        name: "Rota"
    },
    {
        name: "Cliente"
    },
    {
        name: "Prev.",
        align: "center"
    },
    {
        name: "horário",
        align: "center"
    },
    {
        name: "Entregue"
    }
]

interface IStatus {
    color: string,
    status: string
}

export function DeliveryStatus() {
    const smallScreen = useMediaQuery({ maxWidth: 820 })
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupObs, setPopupObs] = useState(false);
    const [orderData, setOrderData] = useState<IDelivery>({} as IDelivery)
    const [orderItems, setItems] = useState<IOrderItem[]>([] as IOrderItem[])
    const [orderPayment, setPayment] = useState<IOrderPayment>({} as IOrderPayment)
    const [statusData, setStatusData] = useState<IStatus>({} as IStatus)
    const [dataPopupObs, setDataPopupObs] = useState<IDelivery>({} as IDelivery)
    const [dataTable, setDataTable] = useState<PaginationProps<IDelivery>>({} as PaginationProps<IDelivery>)
    const [params, setParams] = useState({ page: 1, pageSize: 30, search: '', minDate: dateToday(), maxDate: dateToday() })
    const [paramsDate, setParamsDate] = useState({ startDate: dateToday(), endDate: dateToday() })
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);

    const debaunceFilter = useDebaunce({ fn: onChangeParams, delay: 500 })
    const debaunceFilterDate = useDebaunce({ fn: onChangeParamsDate, delay: 500 })

    const fetchData = () => {
        setLoading(true)
        api.get(`${baseURL}/v1/delivery/delivery-status`, { params })
            .then((response) => {
                setDataTable(response);
            }).finally(() => {
                setLoading(false)
            })
    };

    const fetchItems = (orderId: number | string) => {
        setLoading(true)
        const orderParams = {
            orderId: orderId
        }
        api.get(`${baseURLOrderApi}/order/`, {
            params: orderParams
        }).then(response => {
            joinData(response.results)
            let newPaymentData: IOrderPayment = response.results[0]
            setPayment(newPaymentData)
            setPopupVisible(true)
        }).catch(err => {
            toast.error("Houve um erro ao buscar os itens do pedido")
        }).finally(() => {
            setLoading(false)
        })
    }

    function onChangeParams(params: any) {
        setParams(state => ({ ...state, ...params }))
    }

    function onChangeParamsDate(params: any) {
        setParamsDate(state => ({ ...state, ...params }))
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        debaunceFilter({ page })
        debaunceFilterDate({ page })
    };

    const handleOpenStatus = (element: IDelivery) => {
        fetchItems(element.orderId)
    }

    const joinData = (orderList: any[]) => {
        let newList: IOrderItem[] = []
        orderList.forEach((order: any) => {
            order.item.forEach((item: any) => {
                newList.push(item)
            })
        })
        setItems(newList)
    }

    const isLate = (expectedTime: string, deliveryTime: string) => {
        expectedTime = expectedTime?.slice(0, 5).split(":").join()
        deliveryTime = deliveryTime?.split("T")[1].slice(0, 5).split(":").join()

        const state = expectedTime?.localeCompare(deliveryTime)

        return state === -1 ? 3 : 1
    }

    const downloadFile = async () => {
        try {
            setLoading(true)
            const response = await api.get(`${baseURL}/v1/delivery/logistic/download`, {
                params: paramsDate,
                responseType: 'arraybuffer'
            });
            const blob = new Blob([response], {
                type: 'application/ms-excel;charset=utf-8'
            });
            saveAs(blob, 'Planilha-Maracujá.xlsx');
            setLoading(false)
        } catch (error) {
            toast.error('Ocorreu um erro ao buscar os dados do Agile.');
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData()
    }, [params])

    return (

        <Container>
            <Popup
                popupTitle={"Status de Entrega"}
                open={popupVisible}
                isEditPopup={false}
                setOpen={setPopupVisible}
                hasStatus={true}
                statusData={statusData}
                content={<DeliveryStatusForm apiData={orderData} orderItems={orderItems} paymentData={orderPayment} />}
            />
            <Popup
                popupTitle={"Observações do motorista"}
                open={popupObs}
                isEditPopup={false}
                setOpen={setPopupObs}
                content={<DeliveryStatusFormObs apiData={orderData} />}
            />
            <Header />
            <div className="pageContent">
                <SidebarContainer>
                    <SidebarComponent />
                </SidebarContainer>

                <PageContainer>
                    <MainContainer>
                        <div className='infos'>
                            <h3>Status de entregas</h3>
                            <Toolbar
                                params={params}
                                paramsDate={paramsDate}
                                paramsDateState={setParamsDate}
                                paramState={setParams}
                                loadingState={loading}
                                placeholder="Número do pedido"
                                export={true}
                                onDownload={downloadFile}
                                page='deliveryStatus'
                            />
                        </div>
                        <DeliveryStatusFilter onChangeParams={onChangeParams} />
                            <DataTable
                                loading={loading}
                                columns={smallScreen ? smallColumns : columns}
                            >
                                {dataTable?.results?.filter(item => item?.orderId?.includes(searchTerm?.trim()))
                                    .map(element => (
                                        <Line className="bodyLine" key={element.id}
                                            onClick={() => {
                                                if (!smallScreen) return
                                                setOrderData(element as any)
                                                setStatusData(
                                                    {
                                                        status: element?.deliveryAttempt.length > 0 ? (element?.deliveryAttempt[0].successfulDelivery ? "Entregue" : "Falha") : "Em trânsito",
                                                        color: element?.deliveryAttempt.length > 0 ? (element?.deliveryAttempt[0].successfulDelivery ? "green" : "red") : "blue"
                                                    }
                                                )
                                                handleOpenStatus(element)
                                            }}>
                                            <td align="left" className='contentTable id'>
                                                {element?.deliveryAttempt.length > 0 ?
                                                    (element?.deliveryAttempt[0].successfulDelivery ? <StatusTag color="green" /> : <StatusTag color="red" />) :
                                                    <StatusTag color="blue" />}
                                                #{element?.orderId}
                                            </td>
                                            {!smallScreen &&
                                                <td align="left" className='contentTable driverName'>{element?.driver?.name || "Sem dados"}</td>
                                            }
                                            <td align="center" className='contentTable'>{element?.route}</td>
                                            <td align="center" className='contentTable clientName'>{element?.clientData?.clientFormalName}</td>
                                            <td align="center" className='contentTable date'>{element?.deliveryDate}</td>
                                            <td align="center" className='contentTable'>
                                                {element?.deliveryWindow?.initial?.slice(0, 5)} - {element?.deliveryWindow?.final?.slice(0, 5)}
                                            </td>
                                            <td align="center" className="contentTable deliveryTime">
                                                {!smallScreen && element?.deliveryAttempt.length > 0 &&
                                                    <DeliveryTimeIcon timing={
                                                        isLate(element?.deliveryWindow?.final, element.deliveryAttempt[0]?.attemptTime)
                                                    } />
                                                }
                                                {element?.deliveryAttempt[0]?.attemptTime.split("T")[1].slice(0, 5)}
                                            </td>
                                            {smallScreen === false &&
                                                <>
                                                    <td align="center" className="contentTable observation">
                                                        {element.deliveryAttempt[0]?.attemptTime &&
                                                            <ChatOutlinedIcon sx={{ fill: defaultTheme.colors['base-dark'] }}
                                                                onClick={() => {
                                                                    setOrderData(element)
                                                                    setDataPopupObs(element)
                                                                    setPopupObs(true)
                                                                }} />
                                                        }
                                                    </td>
                                                    <td align="right" className='contentTable edit'>
                                                        <MoreVertIcon sx={{ fill: "#555", cursor: "pointer" }} onClick={() => {
                                                            if (loading) return
                                                            setOrderData(element)
                                                            setStatusData(
                                                                {
                                                                    status: element?.deliveryAttempt.length > 0 ? (element?.deliveryAttempt[0].successfulDelivery ? "Entregue" : "Falha") : "Em trânsito",
                                                                    color: element?.deliveryAttempt.length > 0 ? (element?.deliveryAttempt[0].successfulDelivery ? "green" : "red") : "blue"
                                                                })
                                                            handleOpenStatus(element)
                                                        }} />
                                                    </td>
                                                </>
                                            }
                                        </Line>
                                    ))}
                            </DataTable>
                        <Pagination
                            shape="rounded"
                            count={dataTable?.totalPages}
                            page={params.page}
                            onChange={handlePageChange}
                            className='pagination'
                        />
                    </MainContainer>
                </PageContainer>
            </div>
        </Container>
    )
}
