import styled from 'styled-components'
import { defaultTheme } from 'styles/theme/default'

export const ConfirmContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 200;

    .backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .header {
        margin: 0;
    }

    .popup {
        position: fixed;
        width: fit-content;
        height: fit-content;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: #FFF;
        box-shadow: 0 0 300px rgba(0, 0, 0, 0.4);
        border-radius: 16px;
        padding: 24px;
        z-index: 220;
    }

    Button {
        width: 160px;
        height: 40px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: #FFF;

        justify-content: center;

        text-align: center;
    }

    .confirmText {
        text-align: center;
        margin-bottom: 23px;
        font-size: 1.2rem;
        font-family: 'Inter';
        line-height: 22px;
    }

    .confirmBtnContainer {
        display: flex;
        width: 100%;
        height: 40px;
        align-items: center;
        justify-content: center;
        justify-items: center;
        gap: 12px;

        .deleteBtn, .cancelBtn {
            margin: 0;
        }

        .deleteBtn {
          background-color: ${defaultTheme.colors.danger};
        }
    }

    @media screen and (max-width: 820px) {
        .confirmBtnContainer {
          flex-direction: column;
          gap: 12px;
          height: fit-content;
        }

        .popup{
          width: 300px;
          height: 200px;
        }

        .cancelBtn, .deleteBtn {
          height: 35px;
          width: 100%;
        }

        .header > h1 {
          font-size: 1.05rem;
        }
    }
`
