import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const RegionLineContainer = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    transition: all 100ms;
    outline-offset: -2px;


    .line {
        width: 25%;
        text-align: center;
    }

    &.active {
        font-weight: bold;
        outline: 2px solid ${defaultTheme.colors['base']};
    }
    
    &.inactive {
        outline: 0px solid white;
    }

`