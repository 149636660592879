import { IDelivery } from "interfaces/IDeliveryAttempt";
import { DeliveryHoverContainer } from "./styles";

interface IProps {
    data: IDelivery
}

export function DeliveryHover({data}: IProps) {
    const lastAttempt = data.deliveryAttempt[data.deliveryAttempt.length - 1]
    
    const getStatus = () => {
        const isDone = data.deliveryAttempt[data.deliveryAttempt.length-1]?.successfulDelivery
        return isDone !== undefined ? (isDone ? "Entregue" : "Falha") : "Em Trânsito"
    }

    return (
        <DeliveryHoverContainer>
            <div className="header">
                <span>{data.driver?.name} - Entrega {data.attendanceOrder}</span>
            </div>
            <div className="body">
                <div className="fields">
                    <span className="storeField">Restaurante</span>
                    <span className="idField">Nº do Pedido</span>
                    <span className="dateField">Data de Entrega</span>
                    <span className="timeField">Horário de Entrega</span>
                    <span className="statusField">Status</span>
                </div>
                <div className="values">
                    <span className="storeValue">{data.clientData?.clientFormalName}</span>
                    <span className="idValue">#{data.orderId}</span>
                    <span className="dateValue">{lastAttempt?.deliveryDate || "---"}</span>
                    <span className="timeValue">{lastAttempt?.attemptTime.split("T")[1].slice(0, 5) || "---"}</span>
                    <span className="statusValue">{getStatus()}</span>
                </div>

            </div>
        </DeliveryHoverContainer>
    )
}