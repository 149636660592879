import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`

  thead {
    position: sticky;
    top: 0;
    background: ${defaultTheme.colors['gray-300']};
    z-index: 25;
  }

  .MuiButtonBase-root {
    position: relative;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 16.5%;
    font-weight: 400;
    color: #6E8247;
  }

  .infos {
    margin-bottom: 28px;


    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .button {
    width: 134px;
    height: 40px;
    background: #89BD23;
    color: #FFF;
    padding-left: 44px;
  }

    /* &:not(:disabled):hover {
      background: ${({ theme }) => theme.colors["base-light"]};;
    } */

  .input, .buttonPlus {
    height: 100%;
    position: relative;
  }

  .boxAmount {
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  .boxInput {
    width: 52px;
    height: 30px;
    padding: 0;
    box-shadow: inset 0px 0px 1px 1px rgba(154, 154, 154, 0.15);
    border-radius: 8px;
    border: none;

    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    color: black;

    margin-right: 12px;
  }

  .iconBtns {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding-top: 8px;
  }

  .obsBtn .buttonImg {
    scale: 110%;
  }

  .commentText {
    max-width: 40rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .obsBtn.check .buttonImg {
    filter: invert(64%) sepia(79%) saturate(438%) hue-rotate(36deg) brightness(1.2) contrast(87%);
  }

  .boxInput.error {
    border: 1px solid ${defaultTheme.colors['danger']};
  }

  .boxInput.success {
    border: 1px solid ${defaultTheme.colors['success']};
  }

  .spacer {
    height: 96px;
  }

  @media screen and (max-width: 600px){

    .toolbar {
      input.toolbarInput {
        width: 75vw;
      }
    }

    .infos{
      margin-bottom: 20px;
      h3{
        font-size: 17px;
      }
    }

    .filterContainer {
      left: 32vw;
    }

  }
`

export const Line = styled.tr`
    width: 100%;
    height: 48px;

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }
`
