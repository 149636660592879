import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const Line = styled.tr`
    width: 100%;
    height: 48px;

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }
`;

export const DriverNameStyles = {
  color: defaultTheme.colors['gray-300'],
  fontSize: '17px',
  margin: '0 0 15px',
};
