import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`
  padding-bottom: 55px;

  .pagination{
    display: flex;
    justify-content: center;
  }

  .MuiPagination-ul {
    display: flex;
    margin-top: 43px;
    width: 400px;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
  }

  .MuiButtonBase-root {
    position: relative;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 16.5%;
    font-weight: 400;
    color: #6E8247;
  }

  .infos {
    margin-bottom: 28px;

    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .button {
    width: 134px;
    height: 40px;
    background: #89BD23;
    color: #FFF;
    padding-left: 44px;

  }

  .toolBar {
    margin-top: 25px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    .tabBtn {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 140%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border: none;

      transition: all 150ms;

      &.true {
          background: ${defaultTheme.colors['base-light']};
          border-radius: 10px 10px 0px 0px;
          border-bottom: 4px solid ${defaultTheme.colors['base']};
      }
    }
  }

  .searchIcon {
    position: absolute;
    left: 16px;
    top: 16px;
    bottom: 16px;
    z-index: 1;
  }

  .input, .buttonPlus {
    height: 100%;
    position: relative;
  }

  .headerTitle {
    padding-left: 0;
  }

  .priorityContainer {
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px red solid; */
  }

  @media screen and (max-width: 769px) {
    .infos {
    h3 {
      text-align: center;
      font-size: 17px;
    }
  }
}
`

export const Line = styled.tr`
  width: 100%;
  height: 48px;

  .contentTable {
    padding-left: 0;
  }

  .bodyLine:nth-child(2n) {
    background-color: #F5F5F5;
  }
`

interface IButtonStyleProps {
  isActive: boolean
}

export const TabButton = styled.button<IButtonStyleProps>`
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 140%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border: none;
      transition: all 150ms;

      background: ${({ isActive }) => isActive && defaultTheme.colors['base-light']};
      border-radius:  ${({ isActive }) => isActive && '10px 10px 0px 0px'};
      border-bottom: ${({ isActive }) => isActive && `4px solid ${defaultTheme.colors['base']}`};

      @media screen and (max-width: 769px) {
        font-size: 1rem;
        .infos{
         margin-bottom: 0;
        h3{
          text-align: center;
          font-size: 17px;
        }
      }
      }
    `
