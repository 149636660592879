import SearchIcon from 'assets/searchIcon.svg'
import { SelectContainer, SelectBack } from "./styles";
import { useDebaunce } from "utils/debaunce";
import { useEffect, useState } from "react";
import { baseURL } from "settings";
import { api } from "services/api";
import { PaginationProps } from 'interfaces/IPagination';
import { Input } from 'components/Input';
import { IDist } from 'interfaces/IDist';

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    handleSelect: (x: any) => void
}

export function ModelSelect({ open, setOpen, handleSelect }: IProps) {

    const handlePick = (element: any) => {
        handleSelect(element)
        setOpen(false)
    }

    if (!open) return null

    return (
        <>
            <SelectBack className="popupBack" onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }} />
            <SelectContainer>
                <div className="list">
                    <div className="entry">
                        <button type="button" className="entryName" onClick={() => handlePick("VAN")}>
                            VAN
                        </button>
                    </div>
                    <div className="entry">
                        <button type="button" className="entryName" onClick={() => handlePick("FIORINO")}>
                            FIORINO
                        </button>
                    </div>
                    <div className="entry">
                        <button type="button" className="entryName" onClick={() => handlePick("KOMBI")}>
                            KOMBI
                        </button>
                    </div>
                    <div className="entry">
                        <button type="button" className="entryName" onClick={() => handlePick("HR")}>
                            HR
                        </button>
                    </div>
                </div>
            </SelectContainer>

        </>
    )
}
