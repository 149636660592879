import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface IProps {
    type: "info" | "success" | "error"
}

export function InfoIcon({type}: IProps) {
    return (
        <div className="infoIconContainer">
            {type === "info" && <WarningRoundedIcon className={type}/>}
            {type === "success" && <CheckCircleRoundedIcon className={type}/>}
            {type === "error" && <ErrorRoundedIcon className={type}/>}
        </div>
    )
}