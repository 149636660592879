import { Box, Button, Checkbox, Modal } from "@mui/material";
import {
  ButtonOverBox,
  ButtonSubmit,
  ContainerInputSearch,
  ContainerListRoutes,
  ContainerRoutes,
  InputSearchRoute,
  Loading,
  Paper,
  Subtitle,
  Title,
} from "./styles";
import { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDebaunce } from "utils/debaunce";
import { routeService } from "services/routeService";
import { toast } from "react-toastify";

interface IProps { }

interface IRoute {
  route: number;
  checked: boolean;
  change: boolean;
  id: number;
}

const ButtonModalOverRoutesBoxes = (props: IProps) => {
  const [openModal, setOpenModal] = useState(false);
  const debaunceFilter = useDebaunce({ fn: onSearch, delay: 300 });
  const allRoutes = useRef<IRoute[]>([]);
  const [routes, setRoutes] = useState<IRoute[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRoutes = async () => {
    const deliveryDate = getDeliveryDateNow();
    try {
      setIsLoading(true);
      const { results } = await routeService.getAllWaybill({ deliveryDate, pageLimit: 1000 });
      const formattedRoutes: IRoute[] = results.map((route) => ({
        change: false,
        checked: route.canAboveLimitBoxes,
        route: route.route,
        id: route.id,
      }));

      allRoutes.current = formattedRoutes;
      setRoutes(formattedRoutes);
    } catch (err: any) {
      toast.error(err?.message || err?.msg || "Erro ao buscar rotas");
    } finally {
      setIsLoading(false);
    }
  };

  const onCheckRoute = (route: number) => {
    setRoutes((state) =>
      state.map((oldRoute) =>
        oldRoute.route === route
          ? { ...oldRoute, checked: !oldRoute.checked, change: true }
          : oldRoute
      )
    );
  };

  function onSearch(search: string) {
    setRoutes(
      allRoutes.current.filter((route) =>
        route.route.toString().includes(search)
      )
    );
  }

  const reset = () => {
    setRoutes([]);
    allRoutes.current = [];
  };

  const onCancel = () => {
    handleCloseModal();
  };

  const updateWaybill = async (route: IRoute) => {
    try {
      await routeService.updateWaybill(route.id, {
        can_above_limit_boxes: route.checked,
      });
    } catch (err: any) {
      toast.error(
        `Erro ao atualizar a rota ${route.route} | ${err?.message || err?.msg || "Erro ao atualizar rota"
        }`
      );
    }
  };

  const onSave = async () => {
    const routesToSave = routes.filter((route) => route.change);
    try {
      setIsLoading(true);
      await Promise.all(routesToSave.map((route) => updateWaybill(route)));
      toast.success("Rotas atualizadas com sucesso!");
      handleCloseModal();
    } catch (err: any) {
      toast.error(
        err?.message || err?.msg || "Erro ao atualizar rotas. Tente novamente"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    getRoutes();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    reset();
    setOpenModal(false);
  };

  const getDeliveryDateNow = () => {
    const dateNow = new Date();

    const hour = dateNow.getHours();

    if (hour > 12 || (hour === 12 && dateNow.getMinutes() > 0)) {
      dateNow.setDate(dateNow.getDate() + 1);
    }

    const year = dateNow.getFullYear();

    const month = (dateNow.getMonth() + 1).toString().padStart(2, "0");
    const day = dateNow.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <ButtonOverBox onClick={handleOpenModal}>Rotas estouradas</ButtonOverBox>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Paper>
          <Loading isLoading={isLoading} />
          <Title>Rotas que podem estourar</Title>
          <Subtitle>Marque as rotas que podem estourar</Subtitle>
          <ContainerRoutes>
            <ContainerInputSearch>
              <InputSearchRoute
                placeholder="Rota"
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
                InputLabelProps={{
                  shrink: false,
                }}
                onChange={(e) => debaunceFilter(e.target.value)}
              />
            </ContainerInputSearch>

            <ContainerListRoutes>
              {routes.map((route) => (
                <Button
                  key={route.route}
                  onClick={() => onCheckRoute(route.route)}
                >
                  <Checkbox
                    checked={route.checked}
                    sx={{
                      color: "#89BD23",
                      "&.Mui-checked": {
                        color: "#89BD23",
                      },
                    }}
                  />
                  {route.route}
                </Button>
              ))}
            </ContainerListRoutes>
          </ContainerRoutes>
          <Box
            style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
          >
            <ButtonSubmit isSave={false} onClick={onCancel}>
              Cancelar
            </ButtonSubmit>
            <ButtonSubmit isSave onClick={onSave}>
              Salvar
            </ButtonSubmit>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default ButtonModalOverRoutesBoxes;
