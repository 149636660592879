import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const CityLineContainer = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    background-color: ${defaultTheme.colors['white-100']};
    display: flex;
    align-items: center;

    .priorityInput {
        width: 67px;
        height: 38px;
        margin-left: 8px;
        border: 1px solid ${defaultTheme.colors['gray-100']};
        background-color: ${defaultTheme.colors['white-table']};
        font-size: 0.95rem;
        text-align: center;
        
        &.updated {
            color: ${defaultTheme.colors['transit']};
            font-weight: bolder;
        }

        ::placeholder {
            color: ${defaultTheme.colors['gray-100']}
        }

        :disabled {
            border: none;
            background-color: transparent;
        }
    }

    .small {
        width: 15%;
        text-align: center;
    }

    .medium {
        width: 30%;
    }

    .large {
        width: 35%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

`