import axios, { AxiosRequestConfig } from 'axios'
import { baseURL } from 'settings'

export class AgileProcessApi {

    public instance = axios.create({
        baseURL: baseURL
    })

    public get = async (
        url: string,
        config?: AxiosRequestConfig
    ): Promise<any> => {
        return this.instance
            .get(url, config)
            .then(x => x.data)
            .catch(err => {
                if (err?.message === 'Network Error') throw new Error('Network Error')
                if (axios.isAxiosError(err)) throw err.response?.data

                if (err?.messages?.message === "Token is invalid or expired") throw new Error('Unauthorized')

                throw err
            })
    }

    public put = async (
        url: string,
        params?: any,
        config?: AxiosRequestConfig
    ): Promise<any> => {
        return this.instance
            .put(url, params, config)
            .then(x => x.data)
            .catch(err => {
                if (err?.message === 'Network Error') throw new Error('Network Error')
                if (axios.isAxiosError(err)) throw err.response?.data
                if (err?.messages?.message === "Token is invalid or expired") throw new Error('Unauthorized')
                throw err
            })
    }

    public patch = async (
        url: string,
        params?: any,
        config?: AxiosRequestConfig
    ): Promise<any> => {
        return this.instance
            .patch(url, params, config)
            .then(x => x.data)
            .catch(err => {
                if (err?.message === 'Network Error') throw new Error('Network Error')
                if (axios.isAxiosError(err)) throw err.response?.data
                if (err?.messages?.message === "Token is invalid or expired") throw new Error('Unauthorized')
                throw err
            })
    }
}

export const agileProcessApi = new AgileProcessApi()