import { IProductConfig } from "interfaces/ISensitiveItems";
import { ProductSearchButtonContainer, ProductSearchEntryContainer } from "./styles";

interface IProps {
    data: IProductConfig,
    isSensitive: boolean,
    handleUpdate: (x: IProductConfig, y: boolean) => void
}

export function ProductSearchEntry({data, isSensitive, handleUpdate}: IProps) {

    const handleClick = () => {
        handleUpdate(data, !isSensitive)
    }

    return (
        <ProductSearchEntryContainer className="entry">
            <div className="left">
                <span className="sku">{data.sku}</span>
                -
                <span className="name">{data.name}</span>
            </div>
            <div className="right">
                <ProductSearchButtonContainer type="button" isSensitive={isSensitive} onClick={() => handleClick()}>
                    <span className="toggleText">{isSensitive ? "Remover" : "Adicionar"}</span>
                </ProductSearchButtonContainer>
            </div>
        </ProductSearchEntryContainer>
    )
}