import { IRoute } from "interfaces/IWaybill";
import { Container } from "./styles";
import { useMemo } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Props {
  dataDriver: IRoute[]
  setParamsTeam: any
  paramsTeam: any
}


export function PercentageRoutes({ dataDriver, setParamsTeam, paramsTeam }: Props) {

    const handleClick = (teamId: any) => {
      const newTeamId = paramsTeam.teamId === teamId ? "" : teamId;
      setParamsTeam({ teamId: newTeamId });
  };

  const items = useMemo(() => {
    const getDrivers = (id: number | undefined) => dataDriver.filter(({ driver }) => driver?.team?.id === id)
    const getDriversRoutes = (drivers: IRoute[]) => drivers.filter(({ route }) => !!route)
    return dataDriver.reduce((teamsAcc, { driver }) => {
      if (teamsAcc.some((team: any) => team.id === driver?.team?.id)) {
        return teamsAcc
      }
      const drivers = getDrivers(driver?.team?.id)
      const percentage = Math.floor(( getDriversRoutes(drivers).length / drivers.length ) * 100)
      return [ ...teamsAcc, { id: driver?.team?.id, name: driver?.team?.name, color: driver?.team?.colorTeam, percentage } ]
    }, [] as { id?: number, name?: string, color?: string, percentage?: number }[])
  }, [dataDriver])

  return (
    <Container>
       {items.map(item => (
        <div
          className={`content ${paramsTeam.teamId === item.id ? "selected" : ""}`}
          key={item.id}
          onClick={() => handleClick(item.id)}
        >
          <p className="title">{item.name || "Sem Time"}</p>
          <p className="percentage">{item.percentage}%
          {paramsTeam.teamId === item.id && <CheckCircleIcon className="icon" />}
          </p>
          <div className="line" style={{ background: item.color }} />
        </div>
      ))}
    </Container>
  )
}
