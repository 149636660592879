import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const StatusContainer = styled.div`
    font-family: ${defaultTheme.fonts['title']};
    font-size: 1rem;
    color: ${defaultTheme.colors['black']};
    display: flex;
    align-items: center;
    
    .statusBall {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 12px;
        background-color: ${defaultTheme.colors['gray-200']};
    }

    .statusBall.green {
        background-color: ${defaultTheme.colors['success']};
    }

    .statusBall.blue {
        background-color: ${defaultTheme.colors['transit']};
    }

    .statusBall.orange {
        background-color: ${defaultTheme.colors['alert']};
    }

    .statusBall.red {
        background-color: ${defaultTheme.colors['danger']};
    }
`