import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

interface IStyleProps {
    isSensitive: boolean
}

export const ProductEntryContainer = styled.div<IStyleProps>`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    background-color: ${defaultTheme.colors['white-100']};
    padding: 8px 24px;
    
    > .left {
        display: flex;
        width: 60%;
        gap: 12px;
        opacity: ${({ isSensitive }) => isSensitive ? 1 : 0.3};
        transition: opacity 200ms;

        .sku {
            width: 10%;
        }

        .name {
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            :hover {
                overflow: visible;
                z-index: 100;
            }
        }
    }

    > .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 40%;

        .observation {
            width: 100%;
            border: none;
            border-radius: 6px;
            background-color: ${defaultTheme.colors['white-box']};
        }
    
        .removeBtn {
            display: flex;
            width: 24px;
            height: 40px;
            align-items: center;
            border: none;
            background-color: transparent;
    
            :active {
                filter: brightness(0.7)
            }
            
            svg {
                width: 1.25rem;
                fill: ${({ isSensitive }) => isSensitive ? defaultTheme.colors['danger'] : defaultTheme.colors['base']}
            }
        }
    }

`