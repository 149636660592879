import { createGlobalStyle } from "styled-components";


export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html, body {
    width: 100%;
    height: 100%;
  }

  body.modal-open {
    overflow: hidden;
  }

  body, input, textarea, button {
    background: ${({ theme }) => theme.colors["white-300"]};
    font-family: ${({ theme }) => theme.fonts.regular};
    -webkit-font-smoothing: antialiased;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
