import styled from "styled-components";

export const FormContainer = styled.form`
   .boxInput {
    width: 52px;
    height: 30px;
    padding: 0;
    box-shadow: inset 0px 0px 1px 1px rgba(154, 154, 154, 0.15);
    border-radius: 8px;
    border: none;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    color: black;
  }
`
