import { baseURL } from 'settings';

import { api as apiService, ApiService } from './api'
import { ITeams } from 'interfaces/ITeams';
import { toast } from 'react-toastify';
import { driverVehicleService } from './driverVehicleService';
import { IDriver } from 'interfaces/IDriver';


class TeamService {
  constructor(private readonly api: ApiService, private readonly baseURL: string) { }

  public updateTeam = async (team: ITeams, removeLeader?: boolean): Promise<any> => {
    const params = {
      teamId: team.id,
      name: team.name,
      transshipPointId: team.transshipPoint?.id,
      leaderData: removeLeader ? "null" : {
        leaderId: team.leader?.leader?.id,
        cost: team.leader?.leader?.id ? (team.leader?.leader?.cost || 0) : undefined
      },
      colorTeam: team.colorTeam,
    }

    try {
      const response = await this.api.put(`${this.baseURL}/v1/delivery/team`, params)
      toast.success("Time atualizado com sucesso!")
      return response
    } catch (e) {
      toast.error("Houve um erro ao atualizar os times!")
    }
  }

  public registerTeam = async (team: ITeams): Promise<any> => {
    const params = {
      name: team.name,
      colorTeam: team.colorTeam,
      transshipPointId: team.transshipPoint?.id,
      leaderData: {
        leaderId: team.leader?.leader?.id,
        cost: team.leader?.leader?.id ? (team.leader?.leader?.cost || 0) : undefined
      },
    }

    const getParams = {
      name: team.name
    }

    try {
      await this.api.post(`${this.baseURL}/v1/delivery/team`, params)
      const getResponse = await this.api.get(`${this.baseURL}/v1/delivery/team`, { params: getParams })
      const newTeam = getResponse.results[0]
      for (const driver of team.members) {
        driver.teamId = newTeam.id
        driverVehicleService.updateDriver(driver)
      }
      toast.success("Time cadastrado com sucesso!")
      return getResponse
    } catch (e) {
      toast.error("Houve um erro ao cadastrar o time!")
    }
  }

  public deleteTeam = async (team: ITeams, notify?: boolean): Promise<any> => {
    const params = {
      id: team.id,
      teamId: team.id
    }

    try {
      let newTeam = team
      newTeam.members.forEach((driver: IDriver, index: number) => {
        driver.isLeader = false
        driver.teamId = "null"
        driverVehicleService.updateDriver(driver)
      })
      notify && toast.info("Aguarde...")
      await this.updateTeam(newTeam, true)
      const response = this.api.delete(`${this.baseURL}/v1/delivery/team`, { params })
      notify && toast.success("Time deletado com sucesso.")
      return response
    } catch (e) {
      toast.error("Não foi possível deletar o time")
    }
  }
}

export const teamService = new TeamService(apiService, baseURL)
