import { ClientScoreFormContainer } from "./styles";
import { IClientScore } from "interfaces/IClientScore";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@mui/icons-material";
import { useState } from "react";

interface IProps {
    data: IClientScore
    handleSubmit: (x: IClientScore, y: string) => void
}

export function ClientScoreForm({data, handleSubmit}: IProps) {
    const finalScore = data.gainedPoints - data.disputedPoints
    const [comment, setComment] = useState(data.comment)

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
        setComment(e.target.value)
    }

    return (
        <ClientScoreFormContainer id="popupForm" onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(data, comment)
        }}>
            <div className="col1">
                <div className="section">
                    <h2 className="sectionTitle">Informações</h2>
                    <div className="form">
                        <label htmlFor="clientName" className="clientNameLabel">Nome</label>
                        <input name="clientName" className="bigInput" value={data.clientName || "Sem Nome"} readOnly/>
                    </div>
                    <div className="form">
                        <label htmlFor="clientCode" className="clientCodeLabel">CPF/CNPJ</label>
                        <input name="clientCode" className="medInput" value="000.000.000-00" readOnly/>
                    </div>
                    <div className="form">
                        <label htmlFor="clientAddress" className="clientAddressLabel">Endereço</label>
                        <input name="clientAddress" className="bigInput" value="Sem Dados" readOnly/>
                    </div>
                    <div className="form">
                        <label htmlFor="clientRegion" className="clientRegionLabel">Região</label>
                        <input name="clientRegion" className="medInput" value="--" readOnly/>
                    </div>
                </div>
                <div className="section">
                    <h2 className="sectionTitle">Score</h2>
                    <div className="scoreContainer">
                        {finalScore > 0 ? <KeyboardArrowUpRounded className="scoreArrow"/> : <KeyboardArrowDownRounded className="scoreArrow"/>}
                        {Math.abs(finalScore) || "—"}
                    </div>
                </div>
            </div>
            <div className="col2">
                <div className="section">
                    <h2 className="sectionTitle">Contato</h2>
                    <div className="form">
                        <label htmlFor="clientEmail" className="clientEmailLabel">Email</label>
                        <input name="clientEmail" className="bigInput" value="Sem Dados" readOnly/>
                    </div>
                    <div className="form">
                        <label htmlFor="clientPhone" className="clientPhoneLabel">Tel/Cel</label>
                        <input name="clientPhone" className="bigInput" value="00 0 0000-0000" readOnly/>
                    </div>
                </div>
                <div className="section">
                    <h2 className="sectionTitle">Observação</h2>
                    <textarea name="clientComment" cols={30} rows={4}
                    className="clientComment" placeholder="Comentário"
                    defaultValue={data.comment} onBlur={handleBlur}/>
                </div>
            </div>
        </ClientScoreFormContainer>
    )
}