import { IRegion } from "interfaces/IRegion";
import { RegionLineContainer } from "./styles";
import { IDist } from "interfaces/IDist";

interface IProps {
    data: IRegion,
    className?: string
}

export function RegionLine ({data, className}: IProps){

    return (
        <RegionLineContainer className={`region ${className}`}>
            <span className="line id">{data.id}</span>
            <span className="line city">{data.name}</span>
            <span className="line uf">{data.name.slice(0, 3).trim()}</span>
            <span className="line depotId">-</span>
        </RegionLineContainer>
    )
}