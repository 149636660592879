import { TeamSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { PaginationProps } from 'interfaces/IPagination';
import { ITeams } from 'interfaces/ITeams';
import { api } from 'services/api';
import { baseURL } from 'settings';
import { dateToday } from 'utils/dateToday';

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParamsTeam: (x: any) => void
}

export function TeamSelectDownloadPDF({ open, setOpen, onChangeParamsTeam }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [params, setParams] = useState({page: 1, pageSize: 300, search: '', date: dateToday()})
  const [selectedTeams, setSelectedTeams] = useState<number[]>([])
  const [entries, setEntries] = useState<PaginationProps<ITeams>>({} as PaginationProps<ITeams>)

  const handleCheckbox = async (element: number) => {
    if (selectedTeams?.includes(element)) {
      setSelectedTeams(state => ( [ ...state?.filter((data: number) => data !== element) ] ));
    } else {
      setSelectedTeams(state => ( [...state, element] ));
    }
  }

  const fetchDataTeams = async () => {
    await api.get(`${baseURL}/v1/delivery/team`, {
      params
    })
      .then((response) => {
        setEntries(response);
      })
  };

  useEffect(() => {
    onChangeParamsTeam({ "teamId": selectedTeams.join(",") })
  }, [selectedTeams]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  useEffect(() => {
    fetchDataTeams()
  }, [params])

  if (!open) return null

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }} />
      <TeamSelectContainer>
        <div className="header">
          <span>Download PDF por time: </span>
        </div>
        <div className="list">
          {entries?.results?.filter(item => item?.id?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            .map(element => (
              <div className="entry" key={element.id} onClick={() => handleCheckbox(element?.id)}>
                <button type="button" className="entryName" key={element.id}>{element?.name}</button>
                <input
                  type="checkbox"
                  checked={selectedTeams?.includes(element.id)}
                />
              </div>
          ))}
        </div>
      </TeamSelectContainer>
    </>
  )
}
