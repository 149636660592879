import { IUser, INewUser } from 'interfaces/IUser'

import { api as apiService, ApiService } from './api'

class UserService {
  constructor(private readonly api: ApiService) {}

  public postUser = async (user: INewUser): Promise<IUser> => {
    return this.api.post('v1/delivery/user-manager', user)
  }
}

export const userService = new UserService(apiService)
