import { IDelivery } from "interfaces/IDeliveryAttempt";
import { DeliveryCardContainer } from "./styles";
import { DeliveryList } from "./DeliveryList";

interface IProps {
    data: IDelivery[]
}

export function DeliveryCard({data}: IProps) {
    return (
        <DeliveryCardContainer>
            <div className="teamColor" style={{ backgroundColor: data[0].driver?.team?.colorTeam}}/>
            <div className="driverDelivery">
                <div className="driverName">{data[0].driver?.name}</div>
                <div className="deliveryAmount">{data.length} Entregas</div>
            </div>
            <div className="routeData">{data[0].route}</div>
            <div className="vehicleModel">---</div>
            <div className="deliveryList">
                <DeliveryList orders={data}/>
            </div>
        </DeliveryCardContainer>
    )
}