import { SwitchContainer } from "./styles";
import { Link } from "react-router-dom";

interface IProps {
    page: "driver" | "team"
}

export function DriverPageSwitch({page}: IProps) {
    return (
        <SwitchContainer>
            <div className={`buttonContainer driverBtn ${page === "driver" ? "selected" : ""}`}>
            <Link to='/drivers'>Motoristas</Link>
            </div>
            <div className={`buttonContainer teamBtn ${page === "team" ? "selected" : ""}`}>
            <Link to='/teams'>Times</Link>
            </div>
        </SwitchContainer>
    )
}