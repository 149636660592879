import styled from 'styled-components'
import { defaultTheme } from 'styles/theme/default'

export const SelectContainer = styled.div`
    position: absolute;
    top: 20%;
    right: 0;
    padding: 10px 0;
    width: 130px;
    height: 180px;
    z-index: 200;
    
    cursor: default;
    
    /* Cinza/Branco-100 */
    background: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));

    .list {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;

        overflow-y: auto;
    }

    .entry {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 40px;
        padding: 12px 25px;

        :hover {
            background-color: ${defaultTheme.colors['base-light']};
        }
    }

    .entryName {
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-align: left;
        background: none;
        border: none;
        width: 100%;
        color: black;
    }

    @media screen and (max-width:769px){
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 10%;
        margin: auto;
        .header {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

`

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
`