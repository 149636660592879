import { Input } from "components/Input";
import { useFormik } from "formik";
import { ITeams } from "interfaces/ITeams";
import { useEffect, useState } from "react";
import * as yup from "yup"

import { DropdownButton } from "components/DropdownButton";
import { IDriver } from "interfaces/IDriver";
import { toast } from "react-toastify";
import { DriverList } from "../DriverList";
import { defaultTheme } from "styles/theme/default";
import { DistSelect } from "components/PopupForms/DriverForm/DistSelect";
import { KeyboardArrowDown } from "@mui/icons-material";
import { ColorSelect } from "../ColorSelect";

interface IProps {
    handleSave: (data: ITeams, isNew: boolean) => void
}

export function RegisterTeam({ handleSave }: IProps) {
    const [team, setTeam] = useState<ITeams>({} as ITeams)
    const [select, setSelect] = useState(false)
    const [members, setMembers] = useState<IDriver[]>([] as IDriver[])
    const [driverAmount, setAmount] = useState(0)
    const [color, setColor] = useState('#eee')
    const [colorSelector, setColorSelector] = useState(false)

    const nameRegexp = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi
    const schema = yup.object().shape({
        name: yup.string().matches(nameRegexp, "Digite um nome válido!").required("Digite um nome")
    })

    const { values, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: ""
        },
        onSubmit: async () => {
            if (members.length < 2) {
                toast.error("Não é possível ter um time com menos de dois membros!")
                return
            }
            const leaderDriver = team.members.filter((driver) => driver.isLeader === true)
            const leaders = leaderDriver.length
            // Check if there is more than leader, or no leader at all
            if (leaders > 1) {
                toast.error("Não é possível ter um time com mais de um líder!")
                return
            } else if (leaders < 1) {
                toast.error("Não é possível ter um time sem líderes!")
                return
            }
            let newTeam = team
            newTeam.name = values.name
            newTeam.colorTeam = color
            newTeam.leader = {
                leader: {
                    id: leaderDriver[0].id || newTeam.leader.leader.id,
                    name: leaderDriver[0].name
                }
            }

            toast.info("Aguarde...")


            handleSave(newTeam, true)
        },
        validationSchema: schema,
        validateOnChange: true
    })

    const handleSelectDriver = (element: IDriver, state: boolean, leader?: boolean) => {
        leader ? element.isLeader = true : element.isLeader = false

        const index = members.findIndex(member => member.id === element.id)
        let updatedMembers : IDriver[] = [...members]
        if (index !== -1) {
            // The element already exists in the members array, so update it.
            if (state) {
                updatedMembers[index] = element
                setMembers(updatedMembers)

                if (leader) {
                    setFieldValue('name', element.name)
                }
            } else {
                setMembers(members.filter(a => a.id !== element.id))
            }
        } else {
            // The element doesn't exist in the members array, so add it.
            setMembers([...members, element])
        }

        setTeam({...team, members: updatedMembers})
    }

    useEffect(() => {
        setAmount(members.length)
    }, [members])

    return (
        <form id="popupForm" className="teamForm" onSubmit={handleSubmit}>
            <div className="col1">
                <div className="section horizontal">
                <div className="form vertical changeColor">
                        <label htmlFor="color" className="formName">Cor</label>
                        <div className="buttonContainer" style={{ display: "flex", cursor: "pointer" }} onClick={() => setColorSelector(true)}>
                            <button name="color" type="button" className="changeColorBtn" style={{ backgroundColor: color }} />
                            <KeyboardArrowDown style={{ pointerEvents: "none" }} />
                        </div>
                        <ColorSelect handleSelect={setColor} open={colorSelector} setOpen={setColorSelector} />
                    </div>
                    <div className="form vertical">
                        <label htmlFor="name" className="formName">Nome do Time</label>
                        <Input name="name" className="teamInput" onChange={handleChange} onBlur={handleBlur} error={errors.name} value={values.name} readOnly/>
                    </div>
                    <div className="form vertical">
                        <label htmlFor="route" className="formName">Região</label>
                        <DropdownButton
                            className="large"
                            text={"Selecione uma região"}
                            bgColor={defaultTheme.colors['base-light']}
                            select={select}
                            setSelect={setSelect}
                            content={<DistSelect open={select} setOpen={setSelect} handleSelect={() => {}}/>} />
                    </div>
                </div>
                <div className="section">
                    <div className="form full">
                        <label className="formName">Motoristas</label>
                        <span className="drivers">{`${driverAmount} motoristas`}</span>
                    </div>
                    <div className="form">
                        <DriverList data={team} handleSelect={handleSelectDriver} className="driverList" />
                    </div>
                </div>
            </div>
        </form>
    )
}
