import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const SidebarWrapper = styled.div`
    justify-content: center;
    margin-top: 32px;
    height: 100%;
    background-color: #FFF;
    position: fixed;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 35;

    :hover {
        .line {
            width: 160px;
        }
    }
    
    .sidebarContainer {
        border: none;
    }
    
    .btnBackground {
        display: flex;
        width: 100%;
        height: 10vh;
        justify-content: center;
        align-items: flex-end;
        background-color: #FFF;
    }

    .btn {
        width: 100px;
        height: 24px;
        border: 1px solid #6E8247;
        border-radius: 43px;
        background: transparent;
        transition: all 100ms;

        :hover {
            background-color: ${defaultTheme.colors['base']};
            border: 1px solid transparent;
            color: ${defaultTheme.colors['white-100']};
        }
    }

    .line {
        width: 40px;
        border: 1px solid #C4C4C4;
        margin: 24px auto;
        transition: width 350ms;
    }
    
    .menu {
        height: 100%;
        padding-top: 50px;
        background-color: #FFF;
    }

    .menuItem .menuText {
        color: ${defaultTheme.colors['gray-300']};
    }

    .menuItem a, .menuItemActive, .menuIcon, .menuText {
        transition: all 125ms;
    }

    .menuItem a:hover, .menuItemActive {
        background-color: ${defaultTheme.colors['base-light']};
        padding-bottom: 4px;

        .menuText {
            color: ${defaultTheme.colors['base-dark']};
        }

        .menuIcon {
            fill: ${defaultTheme.colors['base-dark']};
            filter: brightness(0) saturate(100%) invert(45%) sepia(57%) saturate(315%) hue-rotate(39deg) brightness(93%) contrast(87%);
        }
    }

    @media screen and (max-height:1024px){
        .menu {
            overflow: hidden;
            padding-top: 35px;
        }

        .menuItem {
            padding: 0;

            .menuText {
                font-size: 0.9rem;
            }

            a {
                height: 36px;
            }
        }

        .menuItemActive {
            padding-bottom: 0;
        }

        .btnBackground {
            align-items: center;
        }

        .btn {
            padding: 0;
            height: 1.75rem;
        }
    }
`