import styled from "styled-components";

export const Container = styled.div`
  max-width: 277px;
  min-width: 257px;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  user-select: none;
  
  cursor: default;
  
  background: #FFFFFF;
  border-radius: 24px;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.21);

  .title {
    color: #757575;
    margin-top: 5px;
    margin-bottom: 13px;
  }

  .countRoutes {
    color: #353535;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
  }

  .danger {
    color: #EB001B;
  }

  .success {
    color: #4ECC71;
  }

  .open {
    display: block;
  }

  .okIcon {
    position: absolute;
    margin-right: 192px;
    width: 18px;
  }

  .arrowDown {
    transform: rotate(180deg);
  }
`