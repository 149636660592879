import styled from "styled-components";

export const Line = styled.tr`
    width: 100%;
    height: 48px;

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }


`
