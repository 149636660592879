import LogoMaracuja from 'assets/logo.svg'
import { LogoWrapper } from './styles'

export function Logo() {
    return (
        <LogoWrapper className='logoWrapper'>
            <img className='logoImg' src={LogoMaracuja} alt="" />
            <span className='logoName'>Maracujá</span>
        </LogoWrapper>
    )
}
