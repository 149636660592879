import { ISensitiveItem } from "interfaces/ISensitiveItems"
import { ItemContainer } from "./styles"
import { useState } from "react"

interface IProps {
    item: ISensitiveItem,
    handleUpdate: (x: ISensitiveItem, y: boolean) => void
}

export function SensitiveItemEntry ({item, handleUpdate}: IProps) {
    const [value, setValue] = useState(item.brokenItems)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const parsedValue = parseInt(e.target.value)
        if (parsedValue > item.brokenItems) setValue(item.brokenItems)
        setValue(parsedValue)
    }

    const handleBlur = () => {
        const newItem: ISensitiveItem = {...item, brokenItems:value}
        handleUpdate(newItem, newItem.brokenItems === item.brokenItems)
    }

    return (
        <ItemContainer hasUpdated={value !== item.brokenItems}>
            <span className="name large">{item.name}</span>
            <span className="quant small">{item.quantity}</span>
            <div className="broken small">
                <input type="number" min={0} max={item.quantity} className="brokenInput" defaultValue={item.brokenItems} onBlur={handleBlur} onChange={handleChange} />
            </div>
        </ItemContainer>
    )
}