import styled from "styled-components";

export const ContainerRoutes = styled.div`
  position: absolute;
  width: 271px;
  height: 273px;
  bottom: 110%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.21);

  cursor: default;
  
  background: #FFFFFF;
  border-radius: 8px;

  .title {
    margin-top: 16px;
    margin-left: 20px;
    color: #757575;
  }

  .list {
    width: 100%;
    height: 90%;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
  }

  .entry {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 40px;
    padding: 12px 25px;

  }

  .entryName {
    font-size: 0.9rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    text-align: left;
    background: none;
    border: none;
    width: 100%;
    color: black;
  }
`

export const SelectBack = styled.div`
  cursor: default;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
`