import ReceiptIcon from '@mui/icons-material/Receipt';
import { EditButton } from "components/EditButton";
import { useEffect, useState } from "react";
import { TeamSelect } from "components/TeamSelect"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { toast } from "react-toastify";
import { IVehicle } from "interfaces/IVehicle";
import { driverVehicleService } from "services/driverVehicleService";
import { ITeams } from "interfaces/ITeams";
import { defaultTheme } from "styles/theme/default";

interface IProps {
    data: IVehicle,
    handleEdit: () => void,
    routeError?: boolean,
}

export function DriverCard({ data, handleEdit, routeError }: IProps) {
    const [newData, setData] = useState<IVehicle>(data)
    const [select, setSelect] = useState(false)
    const [team, setTeam] = useState<ITeams>(data.driver?.team ?? {} as ITeams)

    const getContrast = (hexcolor: string) => {

        // If a leading # is provided, remove it
        if (hexcolor.slice(0, 1) === '#') {
            hexcolor = hexcolor.slice(1);
        }
    
        // If a three-character hexcode, make six-character
        if (hexcolor.length === 3) {
            hexcolor = hexcolor.split('').map(function (hex) {
                return hex + hex;
            }).join('');
        }
    
        // Convert to RGB value
        var r = parseInt(hexcolor.substr(0,2),16);
        var g = parseInt(hexcolor.substr(2,2),16);
        var b = parseInt(hexcolor.substr(4,2),16);
    
        // Get YIQ ratio
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        // Check contrast
        return (yiq >= 128) ? 'black' : 'white';
    
    };

    const handleSelect = async (newTeam: ITeams) => {
        if (!data.driver) {
            toast.error("Aloque um motorista ao veículo")
            setSelect(false)
            return
        }
        try {
            const teamId = newTeam.id
            let isLeader = false

            if (newTeam.id === data?.driver?.team?.id) {
                isLeader = true
            }

            const updatedDriver = { ...data, driver: { ...data.driver, teamId, isLeader: isLeader } };
            await driverVehicleService.updateDriver(updatedDriver.driver);
            setTeam(newTeam)
            setSelect(false);
        } catch (error) {
            toast.error("Ocorreu um erro ao atualizar os times");
            throw error
        }
    };

    useEffect(() => {
        setData(data)
        // setTeam(data?.driver?.team ?? )
    }, [])

    return (
        <>
            <div className="mainInfo">
                <p className="name">{data?.driver?.name || data?.name}</p>
            </div>
            <div className="sideMenu">
                <div className="infoTag">
                    {data.driver && (data.driver?.id === team?.leader?.leader?.id) &&
                        <div className="leaderTag">
                            <StarRoundedIcon sx={{ fill: "#DBA120" }} />
                            <span className="infoText">Líder</span>
                        </div>
                    }
                    {routeError &&
                        <div className="errorTag">
                            <ErrorRoundedIcon sx={{ fill: "#EB001B" }} />
                            <span className="infoText">Trocar rota</span>
                        </div>
                    }
                </div>
                <button className="teamDropdown" disabled={!data.driver}
                    style={{ backgroundColor: data.driver ? (team?.colorTeam || defaultTheme.colors['base-light']) : defaultTheme.colors['gray-100'] }}
                    onClick={() => setSelect(true)}>
                    <span className={`teamName ${team.colorTeam && getContrast(team?.colorTeam || "#fff")}`}>{data.driver ? (team?.name && `Time ${team?.name}` || 'S/ Time') : ""}</span>
                    {select ? <KeyboardArrowUpIcon className={`arrow ${getContrast(team?.colorTeam || "#fff")}`} /> :
                    <KeyboardArrowDownIcon className={`arrow ${team.colorTeam && getContrast(team?.colorTeam || "#fff")}`} />}
                    {select && <TeamSelect open={select} setOpen={setSelect} handleSelect={handleSelect} />}
                </button>
                <button className="receiptBtn" onClick={() => toast.warn("Função ainda em desenvolvimento.")}>
                    <ReceiptIcon sx={{ fill: "#757575" }} />
                </button>
                <EditButton onClick={handleEdit} />
            </div>
        </>
    );
}