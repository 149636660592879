import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

interface IStyleProps {
    hasUpdated: boolean
}

export const ItemContainer = styled.div<IStyleProps>`
    display: flex;
    width: 100%;
    height: 40px;

    align-items: center;
    justify-content: space-between;

    .large {
        width: 40%;
    }

    .small {
        width: 30%;
        text-align: center;
    }

    .brokenInput {
        width: 50px;
        height: 40px;
        border-radius: 8px;
        border: 0;
        background-color: ${defaultTheme.colors['base-input']};
        padding: 0;
        text-align: center;
        font-size: 0.85rem;
        font-weight: ${({ hasUpdated }) => hasUpdated ? 'bold' : 'regular'};
        color: ${({ hasUpdated }) => hasUpdated ? defaultTheme.colors['transit'] : 'initial'};
    }
`