import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const CardContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    width: 100%;

    &.driverCard {
        min-height: 64px;
        max-height: 64px;
    }

    &.teamCard {
        min-height: 64px;
        max-height: 94px;

    }

    &.table {
        border-radius: 0;
        height: 35px;
        box-shadow: none;
        font-size: 0.9rem;

        .mainCard {
            height: 100%;
            padding: 0 41px;
        }

        .cardStrip {
            border-radius: 0;
        }

        .tableInput {
            border-radius: 0;
            height: 32px;
        }

        .tableSized {
            height: 40px;
        }

        :nth-child(2n) {
            background-color: ${defaultTheme.colors['white-table']};
        }
    }

    .cardStrip {
        width: 8px;
        border-radius: 8px 0 0 8px;
    }

    .mainCard {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        padding: 18px 41px 18px 24px;
    }

    .mainInfo {
        display: flex;
        flex-direction: column;
        gap: 9px;
        width: 80%;
    }

    .route {
        font-family: 'Inter', sans-serif;
        color: ${defaultTheme.colors['gray-300']};
    }

    .sideMenu {
        position: absolute;
        display: flex;
        align-items: center;
        gap: 32px;
        right: 32px;
    }

    .arrow {
        fill: ${defaultTheme.colors['gray-300']};
    }

    .infoTag {
        width: 120px;
    }

    .leaderTag, .errorTag {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .teamName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .teamName.white {
        color: white;
    }

    .infoText {
        user-select: none;
    }

    .driverAmount {
        font-family: 'Inter', sans-serif;
        color: ${defaultTheme.colors['gray-300']};
        font-size: 1rem;
    }

    .teamDropdown {
        position: relative;
        width: 130px;
        height: 28px;
        /* background: #FFEEDA; */
        border-radius: 30px;
        border: none;
        padding: 6px 12px;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        display: flex;
        align-items: center;
        justify-content: space-between;

        :disabled {
            color: #FFF;
            cursor: not-allowed;
        }
    }

    .arrow.white {
        fill: white;
    }

    .receiptBtn {
        border: none;
    }


    @media screen and (max-width:769px){
        .mainCard{
          padding: 18px 12px 18px 12px;
        }
        p{
          font-size: 0.9rem;
        }
        .sideMenu{
          gap: 4px;
        }
        .infoTag {
            width: fit-content;
        }

        .infoText {
            display: none;
        }
    }


`
