import styled from 'styled-components'
import { defaultTheme } from 'styles/theme/default'

export const RouteSelectContainer = styled.div`
    position: absolute;
    top: 20%;
    left: 103%;
    width: 285px;
    height: 570px;
    z-index: 200;
    
    cursor: default;
    
    /* Cinza/Branco-100 */
    background: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));
    
    .header {
        padding: 16px;
        width: 100%;
    }

    .input {
        position: relative;
        height: 40px;
        width: 100%;
    }

    .inputBox {
        width: 100%;
        border-radius: 37px;
        height: 40px;
    }
    
    .input input{
        padding-left: 15%;
        color: black;
        border: none;
        font-size: 0.9rem;
        background-color: ${defaultTheme.colors['base-input']};
        ::placeholder {
            color: ${defaultTheme.colors['gray-200']};
        }
    }

    .input img {
        position: absolute;
        z-index: 1;
        left: 12px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        cursor: default;
        pointer-events: none;
    }

    .list {
        width: 100%;
        height: 85%;

        display: flex;
        flex-direction: column;

        overflow-y: auto;
    }

    .entry {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        height: 40px;
        padding: 12px 25px;

        :hover {
            background-color: ${defaultTheme.colors['base-light']};
        }
    }

    .entryName {
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-align: left;
        background: none;
        border: none;
        width: 100%;
        color: black;
    }

    @media screen and (max-width:769px){
        left: 0;
        top: 15vw;
        .header {
            margin-bottom: 0;
            margin-top: 0;
        }
    
        ::before {
            visibility: hidden;
        }
    }

`

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
`