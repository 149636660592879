import { RegionSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { IPunctuality } from "interfaces/IPunctuality";

interface IProps {
  open: boolean,
  setOpen: (x: boolean) => void,
  onChangeParams: (x: any) => void,
  entries: IPunctuality[]
}

export function RegionSelect({ open, setOpen, onChangeParams, entries }: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRegion, setSelectedRegion] = useState<string[]>([]) 

  const handleCheckbox = async (element: string) => {
    if (selectedRegion?.includes(element)) {
      setSelectedRegion(state => ( [ ...state?.filter((data: string) => data !== element) ] ));
    } else {
      setSelectedRegion(state => ( [...state, element] ));
    }
  }

  useEffect(() => {    
    onChangeParams({ "region": selectedRegion.join(",") })
  }, [selectedRegion]);
  
  if (!open) return null

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }} />
      <RegionSelectContainer>
        <div className="list">
          {entries?.filter(item => item?.onTime.toString().toLowerCase().includes(searchTerm.toLowerCase()))
            .filter((item, index, self) => index === self.findIndex((t) => t?.region === item?.region))
            .map(element => (
              element?.region !== null &&
              <div className="entry" key={element?.region} onClick={() => handleCheckbox(element?.region)}>
                <button type="button" className="entryName" key={element?.region}>{`${element?.region}`}</button>
                <input
                  type="checkbox"
                  checked={selectedRegion?.includes(element?.region)}
                />
              </div>
            ))}
        </div>
      </RegionSelectContainer>
    </>
  )
}
