import styled from "styled-components";

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
`

export const CostFilterContainer = styled.div`
    position: relative;
    left: 110%;
    width: 282px;
    z-index: 200;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px;

    .header {
        margin-top: 5px;
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    button {
        background: transparent;
        margin-top: 10px;
        border-radius: 12px;
        font-size: 14px;
        width: 100%;
        border: none;
        color: #6E8247;
    }

    @media screen and (max-width: 769px) {
        left: -7%;
        top: 30%;
    }
`