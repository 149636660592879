import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";
import { ButtonProps } from "./index";

export const ButtonContainer = styled.button<ButtonProps>`
  padding: 0 2.8rem;
  color: ${props => props.variant === 'primary' ? '#FFFFFF' : '#6E8247'};
  background: ${defaultTheme.colors['base']};
  border-radius: 12px;
  transition: 0.4s;
  font-size: 16px;
  width: 372px;
  border: none;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .borderButton {
    background-color: #FFF;
  }

  .sheetText{
    width: 100px;
  }
`;
