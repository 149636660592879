import { ReactNode } from "react";
import { Content, Root } from "./styles";

type Props = {
  children: ReactNode
}

export function PageContainer({ children }: Props) {
  return (
    <Root>
      <Content>{children}</Content>
    </Root>
  )
}