import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

interface IStyleProps {
    isCollapsed: boolean
}

export const OrderContainer = styled.div<IStyleProps>`
    position: relative;
    lefT: 0;
    right: 0;
    width: 100%;
    height: ${({ isCollapsed }) => isCollapsed ? '64px' : 'fit-content'};
    background-color: ${defaultTheme.colors['white-100']};
    box-shadow: 0px 0px 8px 0px #0000001F;
    border-radius: 16px;
    outline: 2px solid transparent;
    transition: outline 100ms;
    overflow: hidden;

    cursor: ${({ isCollapsed }) => isCollapsed && 'pointer'};

    :hover {
        ${({ isCollapsed }) => isCollapsed && {
            outline: `2px solid ${defaultTheme.colors['base']}`
        }}
    }

    .header {
        font-family: Inter;
        font-size: 1rem;
        font-weight: bold;
        color: ${defaultTheme.colors['gray-300']};
        display: flex;
        padding: 24px;
        gap: 0;

        .large {
            width: 40%;   
        }

        .small {
            width: 30%;
            text-align: center
        }

    }

    .body {
        font-size: 0.9rem;
        font-weight: 400;
        padding: ${({ isCollapsed} ) => isCollapsed ? '0px' : '24px'};
        display: flex;
        flex-direction: column;
        gap: 12px;

        hr {
            display: block;
            height: 1px;
            border: 0;
            border-top: 1px solid ${defaultTheme.colors['gray-100']};
        }
    }

`