import styled from 'styled-components'
import { defaultTheme } from 'styles/theme/default'

export const FormContainer = styled.form`
    width: 450px;
    max-height: 400px;
    min-height: 100px;
    z-index: 200;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    cursor: default;

    .recordInfo {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px 0;
    }

    .listData {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        height: 8vh;
        border-radius: 8px;
    }

    .dataCardContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px 20px;
    }

    .positiveBoxesReturned {
        color: ${defaultTheme.colors['base']};
    }

    .negativeBoxesReturned {
        color: ${defaultTheme.colors['danger']};
    }

    @media screen and (max-width:769px){
        top: 110%;
        left: 50%;
        width: 100%;

        .dataCardContainer {
          padding: 10px 0;
        }

        .input input {
          padding-left: 16%;
        }

        .list {

        }

        .entry {
          margin-left: 0;
        }

        .header {
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 0;
        }

        ::before {
            visibility: hidden;
        }
    }
`

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
`

