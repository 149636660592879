import styled from "styled-components";

export const OrderListContainer = styled.div`
    margin-top: 12px;
    max-height: 70vh;
    padding: 8px;
    overflow-y: auto;
    border-radius: 16px;

    .list {
        width: 100%;
        min-height: 68.25vh;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    @media screen and (max-width:769px){
        max-height: 100%;
    }
`