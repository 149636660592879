import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`

  thead {
    position: sticky;
    top: 0;
    background: ${defaultTheme.colors['gray-300']};
    z-index: 25;
  }

  .MuiButtonBase-root {
    position: relative;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 16.5%;
    font-weight: 400;
  }

  .infos {
    margin-bottom: 28px;

    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .button {
    width: 134px;
    height: 40px;
    background: #89BD23;
    color: #FFF;
    padding-left: 44px;
  }

  .spacer {
    height: 96px;
  }

  @media screen and (max-width: 600px){

    .toolbar {
      input.toolbarInput {
        width: 75vw;
      }
    }

    button {
      width: auto;
    }

    .infos{
      margin-bottom: 20px;
      h3{
        font-size: 17px;
      }
    }

    .filterContainer {
      left: 32vw;
    }

    th:first-of-type {
      padding: 6px 0 !important;
    }

  }
`
