import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";


export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: ${defaultTheme.colors['base-light']};

  img {
    width: 49.2%;
    height: 100%;
  }

  @media screen and (max-width:769px){
    .illust {
      position: absolute;
      left: 0;
      right: 0;
      top: -20vh;
      margin: auto;
      width: 80%;
    }
  }
`

export const FormContainer = styled.div`
  width: 50.8%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${defaultTheme.colors['white-100']};

  .logoWrapper {
    margin-top: 47px;
  }
  .logoName {
    line-height: 130%;
  }

  .line {
    width: 100%;
    border: 1px solid #C4C4C4;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  
  @media screen and (max-width:769px){
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60vh;
    border-radius: 48px 48px 0px 0px;

    .logoWrapper {
      position: absolute;
      width: fit-content;
      top: -40vh;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }

    .logoName {
      margin-top: 0;
    }
  }
`

export const InputContainer = styled.div`
  width: 372px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  Button {
    height: 48px;
    background-color: ${defaultTheme.colors['base']};
  }

  Button:disabled {
    cursor: wait;
  }

  .line {
    width: 364px;
    border: 1px solid #C4C4C4;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  a {
    font-size: 16px;
    line-height: 140%;
    color: #6E8247;

  }

  .passReset {
    width: fit-content;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .signupBtn {
    margin: 0;
  }

  .borderButton {
    background: #fff;
    border: 1.5px solid #6E8247;
  }

  .formWrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .errorMsg {
    font-size: 0.8rem;
    color: ${defaultTheme.colors['danger']};
  }

  .formWrapper .error {
    border: 1px solid ${defaultTheme.colors['danger']};
  }

  span {
    font-size: 16px;
    color: #757575;
  }

  h2 {
    margin-bottom: 24px;
    color: #353535;
    font-weight: 500;
    line-height: 150%;
    font-size: 36px;
  }
`