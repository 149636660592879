import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";


export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: ${defaultTheme.colors['base-light']};

  img {
    width: 49.2%;
    height: 100%;
  }

  .registerForm {
    width: 300px;
  }

  .closeBtn {
    margin-top: 28px;
    width: 100%;
  }

  .infoForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .infoTitle {
    text-align: center;
  }

  @media screen and (max-width:768px) {
    .illust {
      display: none;
    }
  }
`

export const FormContainer = styled.div`
  width: 50.8%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${defaultTheme.colors['white-100']};
  overflow-y: scroll;

  .logoWrapper {
    margin-top: 47px;
  }

  h3 {
    margin-top: 47px;
    color: #89BD23;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
  }

  @media screen and (max-width:769px){
    overflow-y: auto;
    width: 100%;
    padding-bottom:100px;
  }
`

export const InputContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  Button {
    min-height: 48px;
  }

  Button:disabled {
    background-color: ${defaultTheme.colors['gray-200']};
  }

  .line {
    width: 364px;
    border: 1px solid #C4C4C4;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .backLink {
    display: flex;
    align-items: center;
  }

  .backLink img {
    margin-right: 8px;
  }

  a {
    font-size: 1.2rem;
    line-height: 140%;
    color: #6E8247;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .goBack {
    width: 16px;
  }

  .borderButton {
    background: #89BD23;
    color: #FFF;
    margin-top: 24px;
  }

  .success {
    color: ${defaultTheme.colors['base-dark']};
  }

  .errorMsg {
    font-size: 0.8rem;
    color: ${defaultTheme.colors['danger']};
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
  }

  label {
    font-size: 16px;
    border: none;
    color: #757575;
    margin-bottom: 8px;
    margin-top: 13px;
  }

  h2 {
    margin-bottom: 22px;
    color: #353535;
    font-weight: 500;
    line-height: 150%;
    font-size: 1.5rem;
  }

  .formWrapper .error {
    border: 1px solid ${defaultTheme.colors['danger']};
  }

  .adminCheck {
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
  }

  .line {
    margin: 20px auto;
  }

`
