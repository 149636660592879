import * as yup from "yup"

const phoneRegExp = /^\(\d{2}\)\s?\d?\s?\d{4}-\d{4}$/
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const regexp = /^[a-zA-Z]+$/
const nameRegexp = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi
const vehicleNameRegexp = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff0-9\s]*)$/gi
const plateRegexp = /^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/
const cpfRegExp = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

// SIGN UP SCHEMA
export const signupSchema = yup.object().shape({
    firstName: yup.string().matches(nameRegexp, "Digite um nome válido").required("Digite um nome"),
    lastName: yup.string().matches(nameRegexp, "Digite um sobrenome válido").required("Digite um sobrenome"),
    email: yup.string().email("Digite um email válido").required("Digite um email"),
    password: yup.string().matches(/^[^\s]+$/, "Espaços não são permitidos").required("Digite uma senha"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], "As senhas devem ser iguais").required("")
})

// GENERAL SCHEMAS
// SCHEMAS FOR POPUP FORMS
export const apiSchema = yup.object().shape({
    // Client Info
    clientName: yup.string().required("Digite um nome"),
    phone: yup.string().matches(phoneRegExp, "Digite um número de telefone válido"),
    // Order Info
    store: yup.string().default(""),
    address: yup.string().required("Digite um endereço"),
    region: yup.string().required("Digite uma região"),
    route: yup.number().required("Digite uma rota"),
    updated: yup.date().required("Digite uma data válida"),
    scheduled: yup.date().required("Digite uma data de agendamento"),
})

export const driverVehicleSchema = yup.object().shape({
    name: yup.string().matches(nameRegexp, "Use apenas letras").required("Digite um nome"),
    agileCode: yup.string().required("Digite o código único do agile"),
    phone: yup.string().matches(phoneRegExp, "Digite um número de telefone válido"),
    capacity: yup.number().min(1, "Capacidade deve ser maior que 0").required("Digite a capacidade do veículo"),
    licensePlate: yup.string().matches(plateRegexp, "Digite uma placa válida"),
})

export const registerSchema = yup.object().shape({
    // Driver Form
    driverName: yup.string().matches(nameRegexp, "Use apenas letras").required("Digite um nome"),
    phone: yup.string().matches(phoneRegExp, "Digite um número de telefone válido"),
    agileCode: yup.string().matches(cpfRegExp, "Digite um CPF válido").required("Digite o CPF do motorista"),
    agilePass: yup.string().matches(/^[^\s]+$/, "Espaços não são permitidos").required("Digite a senha do Agile"),
    agileConfirm: yup.string().oneOf([yup.ref('agilePass'), null], "As senhas devem ser iguais").required("Confirme a Senha"),
    
    // Vehicle Form
    licensePlate: yup.string().matches(plateRegexp, "Digite uma placa válida").required("Digite uma placa"),
    vehicleName: yup.string().matches(vehicleNameRegexp, "Digite um nome válido").required("Digite um nome"),
    capacity: yup.number().min(1, "Capacidade deve ser maior que 0").required("Digite a capacidade do veículo"),
    color: yup.string().matches(nameRegexp, "Use apenas letras"),
})

export const driverSchema = yup.object().shape({
    name: yup.string().matches(nameRegexp, "Use apenas letras").required("Digite um nome"),
    agileCode: yup.string().matches(cpfRegExp, "Digite um CPF válido").required("Digite o CPF do motorista"),
    agilePass: yup.string().matches(/^[^\s]+$/, "Espaços não são permitidos").required("Digite a senha do Agile"),
    agileConfirm: yup.string().oneOf([yup.ref('agilePass'), null], "As senhas devem ser iguais").required("Confirme a Senha"),
    phone: yup.string().matches(phoneRegExp, "Digite um número de telefone válido"),
})

export const vehicleSchema = yup.object().shape({
    name: yup.string().required("Digite um nome"),
    agileUniqueCode: yup.string().matches(cpfRegExp, "Digite um CPF válido").required("Digite o código único do veículo"),
    licensePlate: yup.string().matches(plateRegexp, "Digite uma placa válida").required("Digite a placa do veículo"),
    distCenter: yup.string().required("Digite um Centro de Distribuição"),
    capacity: yup.number().min(1, "Capacidade deve ser maior que 0").required("Digite a capacidade do veículo"),
    model: yup.string().required("Digite o modelo do veículo"),
    manufacturer: yup.string(),
    color: yup.string()
})