import { Button } from "components/Button";
import { IoMdClose } from "react-icons/io";
import { ConfirmPopup } from "components/ConfirmPopup";
import { StatusTag } from "components/StatusBall";
import { useState } from "react";
import { PopupContainer } from "./styles";

interface PopupProps {
  open: boolean,
  setOpen: (e: boolean) => void,
  content: JSX.Element,
  popupTitle?: string,
  isEditPopup?: boolean,
  canDelete?: boolean,
  disableSave?: boolean
  disableDelete?: boolean,
  onDelete?: (x?: any) => void,
  deleteText?: string,
  deleteToggle?: boolean,
  confirmDelete?: boolean,
  isObservation?: boolean,
  hasStatus?: boolean,
  noHeader?: boolean,
  statusData?: {
    color: string,
    status: string
  },
  titleStyles?: {
    align?: 'center | left | right',
    pl?: string,
    pr?: string
  },
  keepOnBlur?: boolean,
  expand?: boolean,
  aside?: boolean,
  isTablePopup?: boolean
}

export function Popup(
  { open, setOpen, content, popupTitle, isEditPopup, canDelete, onDelete,
    isObservation, hasStatus, noHeader, statusData, keepOnBlur, deleteText,
    deleteToggle, confirmDelete, disableDelete, disableSave, expand, aside, isTablePopup,
    titleStyles}: PopupProps) {
  const [confirmVisible, setConfirmVisible] = useState(false);

  const togglePopup = () => {
    setOpen(!open)
  }

  if (!open) return <></>

  return (
    <PopupContainer titleStyles={titleStyles} aside={aside} onClick={(e) => { !keepOnBlur && togglePopup() }}>
      <div className={`popup ${isObservation ? "obsPopup" : ""} ${expand && "expand"}`} onClick={(e) => e.stopPropagation()}>
        <ConfirmPopup states={[confirmVisible, setConfirmVisible]} handleDelete={onDelete} />
        <div className="closeButton" onClick={togglePopup}>
          <IoMdClose className="cancelBtn" />
        </div>
        {!noHeader && (
          <div className="header">
            <h1 className="popupTitle">{popupTitle}</h1>
            {hasStatus && <StatusTag status={statusData?.status} color={statusData?.color} />}
          </div>
        )}
        <div className={`popupContent ${isTablePopup ? 'tablePopup' : ''}`}>
          {isTablePopup ? (
            <div className="tablePopup">
              {content || (
                <div className="scrollableTable">
                  {content}
                </div>
              )}
            </div>
          ) : (
            content
          )}
        </div>
        {isEditPopup && (
          <div className="btnContainer">
            <div className="deleteBtnContainer">
              {canDelete && (
                <Button
                  className={`${deleteToggle ? "saveBtn" : "deleteBtn"}`}
                  text={deleteText || "Deletar"}
                  onClick={() => {
                    confirmDelete ? setConfirmVisible(true) : onDelete && onDelete()
                  }}
                  disabled={disableDelete}
                />
              )}
            </div>
            <div className="cancelSaveBtnContainer">
              <Button className="cancelBtn" text="Cancelar" onClick={togglePopup} />
              <Button className="saveBtn" text="Salvar" type="submit" form="popupForm" disabled={disableSave} />
            </div>
          </div>
        )}
      </div>
    </PopupContainer>
  )
}
