import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { IDelivery, IOrderItem, IOrderPayment } from "interfaces/IDeliveryAttempt";
import { ItemTableContainer } from "./styles";
import { mask } from "remask";

interface IProps {
    apiData: IDelivery
    orderItems: IOrderItem[],
    paymentData: IOrderPayment   
}

export function DeliveryStatusForm({ apiData, orderItems, paymentData }: IProps) {
    const { created, deliveryDate, addressData, clientData, boxes, driver } = apiData
    const address = addressData ? `${addressData.street} ${addressData.number}, ${addressData.city} - ${addressData.state}` : "Sem Endereço"

    const phonePatterns = ['(99) 9999-9999', '(99) 9 9999-9999']
    const cpfPatterns = ['999.999.999-9', '999.999.999-99']

    return (
        <div className="tablePopup">
            <div className="col1">
                <div className="section">
                    <h2 className="sectionTitle">Informações do Pedido</h2>
                    <Table size="small">
                        <TableBody>
                            <TableRow className="tableRows">
                                <TableCell>Realizado em</TableCell>
                                <TableCell>{created.split("T")[0] || "Sem Data"}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Agendado para</TableCell>
                                <TableCell>{deliveryDate || "Sem Data"}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Endereço</TableCell>
                                <TableCell>
                                    {address || "S/ Endereço"}
                                </TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Status do pagamento</TableCell>
                                <TableCell>{paymentData?.status_history?.at(-1)?.status || "Sem Dados"}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Forma de pagamento</TableCell>
                                <TableCell>{paymentData?.payment?.at(-1)?.method_payment_name || "Sem Dados"}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Itens</TableCell>
                                <TableCell>{boxes || '---'} caixa(s)</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Motorista</TableCell>
                                <TableCell>{driver?.name || "S/ Nome"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <div className="section">
                    <h2 className="sectionTitle">Informações do Cliente</h2>
                    <Table size="small">
                        <TableBody>
                            <TableRow className="tableRows">
                                <TableCell>Cliente</TableCell>
                                <TableCell>{paymentData?.client?.responsible_name || "Sem Nome"}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Razão Social</TableCell>
                                <TableCell>{clientData?.clientFormalName || "Sem Razão Social"}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Nome do Endereço</TableCell>
                                <TableCell>{clientData?.clientFormalName || "Sem Nome"}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>CPF / CNPJ</TableCell>
                                <TableCell>{paymentData?.client && mask(paymentData?.client?.cpf_cnpj.toString(), cpfPatterns)}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Email</TableCell>
                                <TableCell>{paymentData?.client?.email}</TableCell>
                            </TableRow>
                            <TableRow className="tableRows">
                                <TableCell>Tel / Cel</TableCell>
                                <TableCell>{paymentData?.client && mask(paymentData?.client?.phone_number_1, phonePatterns)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className="col2">
                <div className="section" style={{ height: "100%" }}>
                    <h2 className="sectionTitle">Carrinho</h2>
                    <ItemTableContainer>
                        <div className="itemTableHead">
                            <span className="productHeader">Produto</span>
                            <span className="quantHeader">Qntd</span>
                            <span className="priceHeader">Valor</span>
                        </div>
                        <div className="itemTable">
                            <div className="itemTableBody">
                                {orderItems.map(item => (
                                    <>
                                        <div className="itemRow">
                                            <span className="itemName">{item.description}</span>
                                            <span className="itemQuant">{item.quantity}</span>
                                            <span className="itemPrice">{item && "R$" + ((item.unit_price * item.quantity).toFixed(2) || " ---")}</span>
                                        </div>
                                        <div className="horLine"/>
                                    </>
                                ))}
                            </div>
                            <div className="itemTableFooter">
                                <div className="subtotal">
                                <span>Subtotal:</span>
                                <span className="priceValue">R${paymentData?.sub_total_value || " ---"}</span>
                                </div>
                                <div className="coupon">
                                <span>Cupom:</span>
                                <span className="priceValue">R${paymentData?.total_discount_value || " ---"}</span>
                                </div>
                                <div className="delivery">
                                <span>Entrega:</span>
                                <span className="priceValue">R${paymentData?.delivery_value || " ---"}</span>
                                </div>
                                <div className="total">
                                <span>Total:</span>
                                <span className="priceValue">R${paymentData?.total_value || " ---"}</span>
                                </div>
                            </div>
                        </div>
                    </ItemTableContainer>
                </div>
            </div>
        </div>
    )
}