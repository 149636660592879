import { useEffect, useState } from "react";
import { AddPopupContainer, Back } from "components/Priority/styles";
import { Depot, priorityService } from "services/priorityService";
import { toast } from "react-toastify";

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    fetchData: () => void
}

export function AddDepotPopup({ open, setOpen, fetchData }: IProps) {
    const [depotName, setDepotName] = useState("")
    const [depotId, setDepotId] = useState("")
    const [abbr, setAbbr] = useState("")

    const handleSave = () => {
        const element: Depot = {
            externalId: depotId,
            name: depotName,
            initials: abbr
        }
        priorityService.saveDepot(element)
            .then((e) => {
                toast.success("CD registrado com sucesso")
                setOpen(false)
                fetchData()
            })
            .catch(e => {
                toast.error("Não foi possível registrar o CD")
            })
    }

    if (!open) return null

    return (
        <>
            <Back onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }} />
            <AddPopupContainer>
                <input type="text" className="text" placeholder="Nome do CD" onChange={(e) => setDepotName(e.target.value)} />
                <input type="text" className="text" placeholder="ID Externo" onChange={(e) => setDepotId(e.target.value)} />
                <input type="text" className="text small" placeholder="Sigla"
                onChange={(e) => {
                    e.target.value = e.target.value.toLocaleUpperCase()
                    setAbbr(e.target.value.toLocaleUpperCase())
                }} />
                <button className="saveBtn" onClick={handleSave} disabled={!depotId || !depotName || !abbr }>Salvar</button>
            </AddPopupContainer>

        </>
    )
}