import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const PriorityContainer = styled.div`
    width: fit-content;
    height: 100%;
    background-color: ${defaultTheme.colors['white-100']};
    border-radius: 16px;
    box-shadow: 0px 0px 8px 0px #0000001F;
    display: flex;
    flex-direction: column;
    font-family: "Roboto";
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0em;

    &.compact {
        width: 570px;
    }

    &.wide {
        width: 770px;
    }

    .header {
        position: relative;
        padding: 16px;
        height: 72px;
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        gap: 12px;
        align-items: center;
        font-family: 'Inter', sans-serif;

        .left, .right {
            display: flex;
            width: 100%;
            align-items: center;
            gap: 8px;
        }

        .right {
            justify-content: flex-end;
        }

        .input {
            width: 90%;
            height: fit-content;
        }
        .inputBox {
            width: 100%;
        }

        .addBtn {
            border: none;
            background-color: ${defaultTheme.colors['base']};
            color: white;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            font-size: large;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .editBtns {
            display: flex;
            width: 232px;
            height: 40px;
            gap: 12px;
            justify-content: flex-end;
            font-weight: 500;
            font-size: 1rem;
            
            button {
                width: 110px;
                border-radius: 12px;
                user-select: none;
            }
            
            .editBtn {
                border: none;
                background-color: ${defaultTheme.colors['base']};
                color: white;

                transition: all 200ms;

                :disabled {
                    background-color: ${defaultTheme.colors['gray-100']};
                    pointer-events: none;
                }
            }

            .cancelBtn {
                border: 1px solid black;
                color: black;
            }
        }
    }

    .main {
        .columns {
            user-select: none;
            display: flex;
            justify-content: flex-start;
            width: 100%;
            color: ${defaultTheme.colors['gray-300']};
            margin-bottom: 16px;
            padding-left: 40px;

            .small {
                width: 17%;
                text-align: center;
            }

            .medium {
                width: 20%;
            }

            .large {
                width: 37%;
            }

            > .line {
                width: 25%;
                text-align: center;
            }
        }
        .list {
            border-radius: 0 0 16px 16px;
            overflow: hidden;
            .priorityGroup {
                position: relative;
                width: 100%;
                display: flex;
                
                .priorityLetter {
                    width: 40px;
                    min-height: 40px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.25rem;
                    color: ${defaultTheme.colors['base-dark']};
                    background-color: ${defaultTheme.colors['base-light']};
                    user-select: none;
                }

                .entries {
                    position: relative;
                    right: 0;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    transition: all 100ms;
                    outline: 0px solid white;
                    outline-offset: -2px;

                    &.over {
                        outline: 2px solid ${defaultTheme.colors['base']};
                        z-index: 100;

                        span {
                            pointer-events: none;
                        }
                    }
                }
            }

            .priorityGroup:nth-of-type(2n){
                filter: brightness(0.97) saturate(1.3);
            }
        }
    }
`

export const Back = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 25;
`

export const AddPopupContainer = styled.div`
    position: absolute;
    min-width: 510px;
    width: fit-content;
    height: 64px;
    top: 100%;
    left: 0vw;
    right: 0vw;
    margin: auto;
    padding: 12px 16px;
    background-color: ${defaultTheme.colors['white-100']};
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
    border-radius: 8px;

    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 100;

    .text {
        width: 175px;
        padding: 12px;
        border: none;
        background-color: ${defaultTheme.colors['white-100']};
        border: 1px solid ${defaultTheme.colors['gray-300']};
        border-radius: 4px;

        &.small {
            width: 85px;
        }
    }
    
    select {
        border-radius: 4px;
        height: 100%;
    }

    .state {
        width: 80px;
    }

    .depot {
        width: 120px;
    }

    .saveBtn {
        width: 70px;
        border-radius: 4px;
        margin-left: 8px;
        height: 80%;
        border: none;
        background-color: ${defaultTheme.colors['base']};
        color: ${defaultTheme.colors['white-100']};

        :disabled {
            background-color: ${defaultTheme.colors['gray-100']};
            cursor: default;
        }
    }
`