import styled from "styled-components";

export const ColorSelectContainer = styled.div`
    position: absolute;
    top: 110%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
    width: 180px;
    height: fit-content;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);

    border-radius: 12px;

    z-index: 999;

    .colorBtn {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: none;
    }
`