import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const CardContainer = styled.div`
    .cardItem {
        position: relative;
        margin-right: 24px;
        width: 236px;
        height: 120px;
        padding: 24px;
        background-color: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
        border-radius: 16px;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        font-weight: 500;
        cursor: default;
    }

    .cardItem b {
        font-size: 1.15rem;
        width: 100%;
    }

    .cardItem p {
        color: ${defaultTheme.colors['gray-300']};
    }

    .cardItem a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`
