import { IBoxCount, IRouteTag } from "interfaces/IBoxes";
import { Divider, OrderContainer } from "./styles";
import { useState } from "react";

interface IProps {
    data: IRouteTag,
    boxData: IBoxCount,
    handleSave: (x: IBoxCount, y: boolean) => void,
    isCount: boolean,
}

export function OrderEntry ({data, handleSave, isCount, boxData}: IProps) {
    const [changed, setChanged] = useState(false)
    const [boxAmount, setBoxAmount] = useState(
        isCount ? boxData?.boxesSent - (boxData?.boxesConfirmed ?? boxData?.boxesSent)
                : boxData?.boxesConfirmed - (boxData?.boxesReturned ?? boxData?.boxesConfirmed)
    )

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numValue = parseInt(e.target.value)
        setChanged(numValue !== boxAmount )
    }
    
    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (!e.target.value?.trim()){
            e.target.value = "0"
        }
        const numValue = parseInt(e.target.value)
        const result = (isCount ? boxData?.boxesSent : boxData?.boxesConfirmed) - numValue
        setChanged(numValue !== boxAmount)
        const newElement: IBoxCount = {
            ...boxData,
            boxesConfirmed: isCount ? result : boxData?.boxesConfirmed,
            boxesReturned: isCount ? boxData?.boxesReturned : result,
            boxesMissing: numValue
        }
        handleSave(newElement, numValue === boxAmount)
    }

    return (
        <>
            <OrderContainer>
                <span className="orderId">{data.orderId}</span>
                <span className="clientName">{data.clientData?.clientFormalName || "Sem Nome"}</span>
                
                <div className="boxCount">
                    {boxData?.boxesSent ?
                    <>
                        <input type="number" max={isCount ? boxData?.boxesSent : (boxData?.boxesConfirmed)}
                        className={`boxInput ${changed && "changed"}`} defaultValue={boxAmount || 0}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        de {isCount ? boxData?.boxesSent : boxData?.boxesConfirmed ?? boxData?.boxesSent}
                    </> :
                    <span>Sem dados</span>
                    }
                </div>
            </OrderContainer>
            <Divider/>
        </>
    )
}