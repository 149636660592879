import { IBoxes } from "interfaces/IBoxes"
import { Line } from "../styles"
import { balanceColor } from "./styles"
import { dateFormat } from "utils/dateFormat"

interface IProps {
  element: IBoxes,
}

export const FormTableLine = ({ element }: IProps) => {

  return (
    <Line className="bodyLine">
      <td align="center">
        <span className="date">{dateFormat(element.deliveryDate)}</span>
      </td>
      <td align="center">
        <span className="routes">{element.route}</span>
      </td>
      <td align="center">
        <span className="balance" style={balanceColor(element.boxesSent, element.boxesReturned)}>
          {element.boxesReturned - element.boxesSent < 0 ? '-' : ''}
          {Math.abs(element.boxesSent - element.boxesReturned)}
        </span>
      </td>
    </Line>
  )
}
