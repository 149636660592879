import { useState, useEffect, useRef } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IBoxReturnHistory } from 'interfaces/IBoxes';
import { ptBR as dateFnsLocale } from 'date-fns/locale';
import { ptBR as muiLocale } from '@mui/x-date-pickers/locales';
import { defaultTheme } from 'styles/theme/default';

interface ICustomDatePicker {
  updateData: (updatedElement: IBoxReturnHistory) => void;
  element: IBoxReturnHistory;
}

const CustomDatePicker = ({ updateData, element }: ICustomDatePicker) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSelectedDate(new Date(element.returnedAt));
  }, [element.returnedAt]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node) &&
        popperRef.current &&
        !popperRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      const localDate = new Date(date);
      localDate.setHours(0, 0, 0, 0);
      updateData({ ...element, returnedAt: localDate.toISOString() });
    } else {
      updateData({ ...element, returnedAt: '' });
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateFnsLocale}
      localeText={muiLocale.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <div ref={datePickerRef}>
        <DatePicker
          label="Data de Retorno"
          value={selectedDate}
          onChange={handleDateChange}
          format="dd/MM/yyyy"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slotProps={{
            textField: {
              sx: {
                maxWidth: '170px',
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: defaultTheme.colors['base']
                  }
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: defaultTheme.colors['base']
                },
                '@media (max-width: 600px)': {
                  width: '100%',
                  maxWidth: '120px',
                  '& .MuiOutlinedInput-input': {
                    padding: '8px 12px'
                  },
                  '& .MuiInputAdornment-root': {
                    display: 'none'
                  }
                }
              }
            },
            popper: {
              sx: {
                '& .MuiPickersDay-root.Mui-selected': {
                  backgroundColor: defaultTheme.colors['base'] + '!important',
                  color: 'white !important'
                },
                '& .MuiPickersDay-root:hover': {
                  backgroundColor: defaultTheme.colors['base'],
                  color: 'white !important'
                }
              },
              ref: popperRef
            },
            desktopPaper: {
              sx: {
                '& .MuiPickersYear-yearButton.Mui-selected': {
                  backgroundColor: defaultTheme.colors['base'] + '!important',
                  color: 'white !important'
                },
                '& .MuiPickersYear-yearButton:hover': {
                  backgroundColor: defaultTheme.colors['base'],
                  color: 'white !important'
                }
              }
            },
            mobilePaper: {
              sx: {
                '& .MuiPickersDay-root.Mui-selected': {
                  backgroundColor: defaultTheme.colors['base'] + '!important',
                  color: 'white !important'
                },
                '& .MuiPickersDay-root:hover': {
                  backgroundColor: defaultTheme.colors['base'],
                  color: 'white !important'
                },
                '& .MuiDialogActions-root .MuiButton-root': {
                  color: defaultTheme.colors['base'],
                  '&.MuiButton-contained': {
                    backgroundColor: defaultTheme.colors['base'],
                    '&:hover': {
                      backgroundColor: defaultTheme.colors['base'] + 'CC'
                    }
                  },
                  '&.MuiButton-outlined': {
                    borderColor: defaultTheme.colors['base'],
                    color: defaultTheme.colors['base']
                  }
                },
                '& .MuiPickersYear-yearButton.Mui-selected': {
                  backgroundColor: defaultTheme.colors['base'] + '!important',
                  color: 'white !important'
                },
                '& .MuiPickersYear-yearButton:hover': {
                  backgroundColor: defaultTheme.colors['base'],
                  color: 'white !important'
                }
              }
            }
          }}
        />
      </div>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
