import styled, { keyframes } from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

const hoverAnimation = keyframes`
  0% {
    color: ${defaultTheme.colors["gray-200"]};
  }
  100% {
    color: ${defaultTheme.colors["danger"]};
  }
`;


export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`
  .pagination{
    display: flex;
    justify-content: center;
  }

  .MuiPagination-ul {
    display: flex;
    margin-top: 43px;
    margin-bottom: 50px;
    width: 400px;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
  }

  .MuiButtonBase-root {
    position: relative;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 16.5%;
    font-weight: 400;
    color: #6E8247;
  }


  .infos {
    margin-bottom: 28px;

    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .toolBar {
    margin-top: 25px;
    display: flex;
    align-items: center;
    margin-right: 664px;
  }

  .searchIcon {
    position: absolute;
    left: 16px;
    top: 16px;
    bottom: 16px;
    z-index: 1;
  }

  .cardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 40px;
  }

  .mainInfo {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    flex-direction: row;
  }

  .plusIcon {
    position: absolute;
    left: 16px;
    top: 12px;
    z-index: 1;
  }

  .planilhaIcon {
    position: absolute;
    margin-top: 10px;
    margin-left: 25px;
    z-index: 1;
  }

  .buttonPlanilhaContainer {
    display: flex;
  }

  .buttonPlanilha {
    width: 170px;
    height: 40px;
    background: transparent;
    color: #89BD23;
    border: 1px solid #89BD23;
    margin-left: 12px;
  }

  .input, .buttonPlus, .buttonPlanilhaContainer {
    height: 100%;
    position: relative;
  }

  .routeCardHeader {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: flex-start;

    padding-left: 3%;

    color: ${defaultTheme.colors['gray-300']};

    .driverName {
      width: 34%;
    }

    .route {
      width: 22%;
    }

    .capacity {
      width: 14%;
    }

    .routesDone {
      width: 20%;
    }
  }

  .dataCardContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 1000px;
  }

  .deleteIcon {
    cursor: pointer;
    color: ${defaultTheme.colors["gray-200"]};
    transition: color 0.5s ease-in-out;

    &:hover {
      animation: ${hoverAnimation} 0.3s forwards;
    }
  }

  @media screen and (max-width:769px){
    .infos{
      margin-bottom: 0;
      h3{
        font-size: 17px;
      }
    }

    .toolBar .input {
      width: 100vw;
    }

    .mainInfo {
      display: flex;
      gap: 8px;
      justify-content: flex-start;
      flex-direction: column;
    }

    .cardContainer {
      margin-top: 10px;
    }

    .routeCardHeader {
      .driverName {
        width: 33%;
      }
      .route {
        width: 24%;
      }
      .capacity {
        width: 20%;
      }
    }
  }

`

export const Line = styled.tr`
    width: 100%;
    height: 48px;

    .contentTable {
      padding-left: 3%;
    }

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }
`
