import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

interface IStyleProps {
   hasBorderRadius: boolean
   isPopup?: boolean;
   noHeader?: boolean;
   actions?: boolean;
}

export const TableContainer = styled.div`
    @media screen and (max-width: 769px){
      width: 100%;
      overflow-y: auto;
      overflow-x: auto;
    }
`

export const Table = styled.table <IStyleProps>`
    overflow-x: scroll;
    width: 100%;
    table-layout: relative;
    border-collapse: collapse;
    min-height: 100px;

    th:first-of-type {
        ${props => props.hasBorderRadius && {"border-top-left-radius" : "18px"}};
        padding: 10px 30px;
    }
    th:last-of-type {
        ${props => props.hasBorderRadius && {"border-top-right-radius" : "18px"}};
    }


    .scaleOnHover {
        transition: all 200ms;

        :hover {
            font-size: 1.1rem;
            color: ${defaultTheme.colors['base']};
        }
    }

    .arrowIcon {
      fill: transparent;
      font-size: 18px;
      transition: fill 0.3s;
      cursor: pointer;
    }

    .arrowIcon:hover {
      fill: ${defaultTheme.colors['gray-300']};
    }

    .headerTable {
        position: relative;
        cursor: default;
        user-select: none;
        background: ${props => props.noHeader ? 'none' : '#757575'};
        color: ${props => props.noHeader ? defaultTheme.colors['base-label']: '#fff'};
        height: ${props => props.noHeader ? '52px' : '48px'};
    }

    ${props => props.actions && `
    .columnAttributes {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    th:first-child .columnAttributes {
      justify-content: initial;
    }
    `}

    .headerTable th {
        font-weight: ${props => props.noHeader && 200};
    }

    .headerTitle {
        padding-left: 2%;
        padding-right: 2%;
    }

    .loadingBar {
        position: absolute;
        width: 100%;
    }

    .bodyTable {
        background-color: ${defaultTheme.colors['white-100']};
    }

    .bodyTable .bodyLine:nth-child(2n){
        background-color: ${defaultTheme.colors['white-table']};
    }

    .bodyTable .bodyLine:hover{
        background-color: #ddd;
    }

    @media screen and (max-width: 769px){
        .contentName {
            font-size: 0.9rem;
        }

        th:first-of-type {
          border-top-left-radius : 0px;
          padding: 12px 30px;
        }
        th:last-of-type {
          border-top-right-radius : 0px;
        }

        .headerTable {
            height: auto;
        }
    }
`

export const PopupButton = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  font-family: ${defaultTheme.fonts.regular};
  font-size: 15px;
  border: none;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${defaultTheme.colors['base']};
    color: ${defaultTheme.colors['white-100']};
  }

  .arrowIcon{
    font-size: 18px;
  }
`;
