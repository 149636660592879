import styled from "styled-components"

export const Root = styled.div`
  padding-top: 32px;
  padding-left: 80px;

  @media screen and (max-width: 769px){
    padding-left: 0;
  }
`

export const Content = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 64px auto;
  padding: 0 2%;

  @media screen and (max-width: 769px){
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 64px;
  }
`
