import SearchIcon from "assets/searchIcon.svg";
import { Input } from "components/Input";
import { ContainerInputFilter, Image } from "./styles";

interface IProps {
  onChange: (value: string) => void;
  placeholder: string;
}

const InputFilter = (props: IProps) => {
  return (
    <ContainerInputFilter>
      <Image src={SearchIcon} />
      <Input
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        placeholder={props.placeholder}
      />
    </ContainerInputFilter>
  );
};

export default InputFilter;
