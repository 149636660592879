import SidebarComponent from 'components/Sidebar';
import { Header } from 'components/Header';
import { Container, MainContainer, SidebarContainer } from './styles';
import { ColumnProps, DataTable } from "components/Table";
import { useEffect, useState } from "react";
import { PageContainer } from 'components/PageContainer'
import { Toolbar } from "components/Toolbar";
import { FormTableLine } from './FormTable';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { routeService } from 'services/routeService';
import CustomCheckBox from 'components/checkbox';
import { ExportRoutesResponse } from 'interfaces/IWaybill';
import { IOrderRoute } from 'interfaces/IWaybill';
import { chunkArray } from 'utils/chunkArray';
import { verifyDate } from 'utils/verifyDate';
import { abacateService } from 'services/abacateApi';

export function ExportRoutes() {
  const smallScreen = useMediaQuery({ maxWidth: 820 })
  const [dataPagination, setDataPagination] = useState<any>();
  const [dataOrderRoute, setDataOrderRoute] = useState<IOrderRoute>();
  const [loading, setLoading] = useState(true)
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [responses, setResponses] = useState<ExportRoutesResponse | null>(null);

  const handleToggleSelected = (routeId: number, selectAll: boolean) => {
    let updatedSelectedRows: number[] = [];
    if (selectAll) {
      updatedSelectedRows = dataPagination.map((route: any) => route.routeId);
    }
    else if (selectAll === false && routeId === 0) {
      updatedSelectedRows = [];
    }
    else {
      updatedSelectedRows = selectedRows.includes(routeId)
        ? selectedRows.filter(id => id !== routeId)
        : [...selectedRows, routeId];
    }
    setSelectedRows(updatedSelectedRows);
  };

  const handleToggleAllSelected = () => {
    setIsCheckedAll(!isCheckedAll);
    handleToggleSelected(0, !isCheckedAll);
  };

  const fetchExportRoutes = async () => {
    try {
      setLoading(true)
      const response = await routeService.getAgileRoutes()
      if (response.length === 0) {
        toast.warning("Não foram encontradas rotas para este dia.");
        return
      }
      setDataPagination(response)
    } catch (error) {
      toast.error("Erro ao buscar rotas no Agile. " + error)
    } finally {
      setLoading(false)
    }
  }

  const fetchOrderRoute = async () => {
    try {
      const params = { scheduled_date: verifyDate() }
      const response = await abacateService.getOrderRoute(params)
      setDataOrderRoute(response)
    } catch {
      toast.error("Erro ao buscar rotas no Abacate.")
    }
  }

  const handleExportRoutes = async () => {
    try {
      toast.info("Aguarde, isso pode levar algum tempo...")
      setResponses(null)
      setWaitingResponse(true)
      const chunks = chunkArray(selectedRows, 5)
      for (const chunk of chunks) {
        const response = await routeService.exportRoutes(chunk)
        setResponses(prevState => ({ ...prevState, ...response.responses }))
      }
    } catch (error) {
      toast.error("Houve um erro ao exportar as rotas. Por favor, tente novamente mais tarde.")
    } finally {
      toast.success("Envio de rotas finalizado. Verifique o resultado.");
      setWaitingResponse(false);
      setSelectedRows([]);
      setIsCheckedAll(false);
    }
  }

  const defaultColumns: ColumnProps[] = [
    {
      name: <CustomCheckBox handleToggle={handleToggleAllSelected} align='center' checked={isCheckedAll === true} />,
      align: "center",
      minWidth: "80px"
    },
    {
      name: "Motorista",
      align: "left",
    },
    {
      name: "Rota",
      align: "center",
    },
    {
      name: "Centro de Distribuição",
      align: "center",
    },
    {
      name: "Data de Atendimento",
      align: "center",
    },
    {
      name: "Pedidos Enviados",
      minWidth: "120px",
      align: "center"
    },
    {
      name: "",
      minWidth: "40px",
      align: "center"
    }
  ]

  const smallScreenColumns: ColumnProps[] = [
    {
      name: <CustomCheckBox handleToggle={handleToggleAllSelected} align='center' checked={isCheckedAll === true} />,
      align: "center",
      minWidth: "80px"
    },
    {
      name: "Motorista",
      align: "left",
    },
    {
      name: "Rota",
      align: "center",
    },
    {
      name: "CD",
      align: "center",
    },
    {
      name: "Data",
      align: "center",
    },
    {
      name: "Pedidos",
      minWidth: "120px",
      align: "center"
    },
    {
      name: "",
      minWidth: "40px",
      align: "center"
    }
  ]

  let columns = smallScreen ? smallScreenColumns : defaultColumns


  useEffect(() => {
    fetchExportRoutes()
    fetchOrderRoute()
  }, []);

  return (
    <Container>
      <div className="pageContent">
        <Header />
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Exportação de Rotas</h3>
              <Toolbar
                loadingState={loading}
                placeholder="Motorista, veículo ou rota"
                hideFilter
                singleButton={true}
                extraButtons={[
                  {
                    type: "filled",
                    text: `Exportar Rotas ${selectedRows.length > 0 ? `(${selectedRows.length})` : ""}`,
                    onClick: () => handleExportRoutes(),
                    disableState: selectedRows.length === 0 || waitingResponse,
                    customWidth: "160px",
                    customMargin: "0 24px 0 0"
                  }
                ]}
              />
            </div>
            <DataTable loading={loading} columns={columns}>
              {dataPagination?.sort((a: any, b: any) => a.route_id - b.route_id)
                .map((element: any) => {
                  return (
                    <FormTableLine
                      key={element.route_id}
                      element={element}
                      handleToggleSelected={handleToggleSelected}
                      selectedRows={selectedRows}
                      waitingResponse={waitingResponse}
                      responses={responses}
                      dataOrderRoute={dataOrderRoute}
                    />
                  );
                })}
            </DataTable>
            <div className="spacer" />
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  );
};
