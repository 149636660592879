import { Box, styled } from "@mui/material";

export const Image = styled("img")({
  position: "absolute",
  left: "16px",
  top: "16px",
  bottom: "16px",
  zIndex: 1,
  pointerEvents: "none",
});

export const ContainerInputFilter = styled(Box)({
  height: "100%",
  position: "relative",
});
