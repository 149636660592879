import {
  Button,
  styled,
  Paper as PaperMui,
  Typography,
  Box,
  TextField,
  LinearProgress,
} from "@mui/material";
import { defaultTheme } from "styles/theme/default";

export const ButtonOverBox = styled(Button)`
  width: 180px !important;
  height: 40px !important;
  border: 1px solid ${defaultTheme.colors["base"]};
  border-radius: 12px;
  color: ${defaultTheme.colors["base"]} !important;
  font-weight: 500 !important;
  text-transform: none;

  @media screen and (max-width: 769px) {
    height: 35px !important;
    width: 140px !important;
  }
`;

export const Paper = styled(PaperMui)({
  maxWidth: "600px",
  height: "600px",
  width: "100%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "12px",
  padding: "2rem",
  margin: "0 1rem",
});

export const Title = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: "500",
  marginBottom: "1rem",
});

export const Subtitle = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: "400",
  color: defaultTheme.colors["gray-300"],
  marginBottom: "1rem",
});

export const ContainerInputSearch = styled(Box)({
  padding: "1rem",
});

export const InputSearchRoute = styled(TextField)({
  width: "100%",
  backgroundColor: defaultTheme.colors["white-box"],
  borderRadius: "12px",
  height: "50px",
  "& *::-webkit-input-placeholder": {
    color: defaultTheme.colors["black"],
  },
  "& *": {
    border: "none !important",
  },
  "& svg": {
    color: defaultTheme.colors["black"],
    marginRight: "8px",
  },
});

export const ContainerRoutes = styled(Box)({
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "12px",
});

export const ContainerListRoutes = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "300px",
  overflowY: "scroll",
  alignItems: "flex-start",
  marginBottom: "1rem",
  "& > .MuiButtonBase-root": {
    width: "100%",
    justifyContent: "flex-start",
    color: defaultTheme.colors["black"],
    "&:not(:last-child)": {
      borderBottom: `1px solid ${defaultTheme.colors["white-box"]}`,
    },

    "&.Mui-checked": {
      color: `${defaultTheme.colors["white-100"]} !important`,
    },
  },
});

export const ButtonSubmit = styled(Button)(
  ({ isSave }: { isSave: boolean }) => ({
    width: "100px !important",
    height: "40px !important",
    border: `1px solid ${defaultTheme.colors["base"]}`,
    borderRadius: "12px",
    color: isSave
      ? defaultTheme.colors["white-100"]
      : `${defaultTheme.colors["base"]} !important`,
    fontWeight: "500 !important",
    background: isSave
      ? defaultTheme.colors["base"]
      : defaultTheme.colors["white-100"],
    textTransform: "none",
  })
);

export const Loading = styled(LinearProgress)(
  ({ isLoading }: { isLoading: boolean }) => ({
    backgroundColor: defaultTheme.colors["base-light"],
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "12px",
    borderRadius: "12px 12px 0 0",
    opacity: isLoading ? 1 : 0,
    "& .MuiLinearProgress-bar": {
      backgroundColor: defaultTheme.colors["base"],
    },
  })
);
