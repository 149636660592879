import styled from "styled-components";

export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`
  height: 100%;

  .pagination{
    display: flex;
    justify-content: center;
  }

  .MuiPagination-ul {
    display: flex;
    margin-top: 43px;
    margin-bottom: 50px;
    width: 400px;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
  }

  .MuiButtonBase-root {
    position: relative;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 16.5%;
    font-weight: 400;
  }

  .spacer {
    margin-top: 40px;
  }

  .infos {
    margin-bottom: -15px;

    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .toolBar {
    margin-top: 25px;
    display: flex;
    align-items: center;
    margin-right: 664px;
  }

  .checkbox{
    margin: 30px 30px;
  }

  .dataCardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 140px;
    gap: 8px;
    margin-top: 12px;

    &.tableCard {
      gap: 0;
    }

    .progressBar {
      position: absolute;
    }
    }

    .routeStats {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 2%;
      padding-right: 2%;
      padding-left: 7.5%;
      height: 70px;
      display: flex;
      /* justify-content: flex-end; */
      z-index: 25;

      .leftCol {
        min-width: 50vw;
        height: 100%;
      }

      .rightCol {
        display: flex;
        min-width: 41vw;
        justify-content: flex-end;
        gap: 12px;
      }

      button.expandStatsBtn {
        border: 0;
        background: #FFFFFF;
        border-radius: 24px;
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.21);
      }
  }

  @media screen and (max-width:769px){
      .checkbox{
        margin: 30px 0;
      }
      .toolBar .input {
        width: 100vw;
      }
      .infos{
        margin-bottom: 0;
        h3{
          font-size: 17px;
        }
      }
  }

`

export const Line = styled.tr`
    width: 100%;
    height: 48px;

    .contentTable {
      padding-left: 3%;
    }

    .bodyLine:nth-child(2n) {
        background-color: #F5F5F5;
    }
`
