import { api as apiService, ApiService } from "./api";
import { baseURLAbacateApi } from "settings";

class AbacateService {
  constructor(
    private readonly api: ApiService,
    private readonly baseURLAbacateApi: string
  ) {}

  public getOrderRoute = async (params: {scheduled_date: string}) => {
    return await this.api.get(`${this.baseURLAbacateApi}/api/abacate/v1/logistic/order_route/`, { params });
  };

}

export const abacateService = new AbacateService(apiService, baseURLAbacateApi);
