import styled from "styled-components";

export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`
  padding-bottom: 55px;

  .buttonPlanilhaContainer {
    display: flex;
  }

  .buttonPlanilha {
    display: flex;
    position: relative;
    width: 190px;
    height: 40px;
    background: transparent;
    color: #89BD23;
    border: 1px solid #89BD23;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    gap: 12px;
  }

  .buttonPlanilhaContainer {
    height: 100%;
    position: relative;
  }

  .planilhaIcon {
    z-index: 1;
  }

  .planilhaText {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .pagination{
    display: flex;
    justify-content: center;
  }

  .MuiPagination-ul {
    display: flex;
    margin-top: 43px;
    width: 400px;
    justify-content: center;
    border-radius: 63px;
  }

  .MuiButtonBase-root {
    position: relative;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 16.5%;
    font-weight: 400;
    color: #6E8247;
  }

  .infos {
    margin-bottom: 28px;

    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .button {
    width: 134px;
    height: 40px;
    background: #89BD23;
    color: #FFF;
    padding-left: 44px;

  }

  .buttonFilter {
    width: 134px;
    height: 40px;
    background: transparent;
    color: #89BD23;

    /* &:not(:disabled):hover {
      background: ${({ theme }) => theme.colors["base-light"]};;
    } */
  }

  .filterIcon, .plusIcon {
    cursor: pointer;
  }

  .toolBar {
    margin-top: 25px;
    display: flex;
    align-items: center;
    margin-right: 664px;
  }

  .searchIcon {
    position: absolute;
    left: 16px;
    top: 16px;
    bottom: 16px;
    z-index: 1;
  }

  .plusIcon {
    position: absolute;
    left: 16px;
    top: 12px;
    z-index: 1;
  }

  .filterIcon {
    position: absolute;
    top: 10px;
    left: 12px;
    z-index: 1;
  }

  .buttonFilterContainer {
    height: 32px;
    position: relative;
    width: 84px;
    margin: 0 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 26px;

    :hover {
      background-color: #F0F0F0;
    }
  }

  .input, .buttonPlus {
    height: 100%;
    position: relative;
  }

  .headerTitle {
    padding-left: 0;
  }

  .infos{
    h3{
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 769px) {
    .infos{
    h3{
      font-size: 17px;
    }
  }
}
`

export const Line = styled.tr`
  width: 100%;
  height: 48px;

  .contentTable {
    padding-left: 0;
  }

  .bodyLine:nth-child(2n) {
    background-color: #F5F5F5;
  }
`
