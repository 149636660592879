import { TeamSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { PaginationProps } from 'interfaces/IPagination';
import { ITeams } from 'interfaces/ITeams';

interface IProps {
    open: boolean,
    setOpen: (x: boolean) => void,
    onChangeParams: (x: any) => void,
    entries: PaginationProps<ITeams>
}

export function TeamSelect({ open, setOpen, onChangeParams, entries }: IProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTeams, setSelectedTeams] = useState<number[]>([]) 

    const handleCheckbox = async (element: number) => {
        if (selectedTeams?.includes(element)) {
            setSelectedTeams(state => ( [ ...state?.filter((data: number) => data !== element) ] ));
        } else {
            setSelectedTeams(state => ( [...state, element] ));
        }
    }

    useEffect(() => {    
        onChangeParams({ "teamId": selectedTeams.join(",") })
    }, [selectedTeams]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
    };

    if (!open) return null

    return (
        <>
            <SelectBack className="popupBack" onClick={(e) => {
                e.stopPropagation()
                setOpen(false)
            }} />
            <TeamSelectContainer>
                <div className="list">
                    {entries?.results?.filter(item => item?.id?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
                        .map(element => (
                            <div className="entry" key={element.id} onClick={() => handleCheckbox(element?.id)}>
                                <button type="button" className="entryName" key={element.id}>{`${element?.name}`}</button>
                                <input
                                    type="checkbox"
                                    checked={selectedTeams?.includes(element.id)}
                                />
                            </div>
                        ))}
                </div>
            </TeamSelectContainer>
        </>
    )
}
